import React, { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { getCostVolumeRatioUnit } from '../ChallengeFilter/ChallengeFilterAlertService'
import {
	convertPriceToVolume,
	getPartStatusAndIcon
} from '../PartsListView/PartsListViewService'
import FlexBox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { manufacturingMethodStrings } from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ManufacturingMethod } from 'Services/models/IConfiguration'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { getString } from 'Services/Strings/StringService'

interface Props {
	costSavingAmount: number
	costSavingPercentage: number
	suggestionType: string
	suggestion: Record<string, any>
}

const PartCardFooter: FC<Props> = ({
	costSavingAmount,
	costSavingPercentage,
	suggestionType,
	suggestion
}) => {
	const part = suggestion?.part || suggestion.cluster
	let {
		iconName,
		resultLabel,
		isPartUpdating,
		isPartUnprintable,
		isPartNotCostEffective
	} = getPartStatusAndIcon(part.status, part.result, false)
	const review3dInProjectAnalysis = Feature.isFeatureOn(
		FeatureComponentId.REVIEW_3D_IN_PROJECT_ANALYSIS
	)

	const isNotCostSaving = useMemo(() => {
		return (
			costSavingAmount <= 0 &&
			!isPartNotCostEffective &&
			!isPartUpdating &&
			!isPartUnprintable &&
			!!suggestion?.resultType
		)
	}, [
		costSavingAmount,
		isPartNotCostEffective,
		isPartUpdating,
		isPartUnprintable,
		suggestion?.resultType
	])

	let resultLabelText =
		costSavingAmount > 0 ? resultLabel : getString('NO_OPPORTUNITIES')

	if (isNotCostSaving) {
		resultLabelText = getString('NO_COST_SAVING')
		iconName = CADAnalysisResult.notCostEffective
	}

	const manufacturingMethodString: ImanufacturingTypes =
		suggestion.mainManufactureMethod === ManufacturingMethod.threeD
			? ImanufacturingTypes.AM
			: suggestion.mainManufactureMethod

	const isChallenge =
		suggestion.resultType === ConfigurationResultTypes.Challenges ||
		suggestion.part?.actualResult > 0

	const disableMethod = isChallenge || !suggestion.resultType

	const footerInfo = `$${costSavingAmount} / ${costSavingPercentage}%`

	const showResultLabel =
		(!costSavingAmount && !costSavingPercentage && !suggestionType) ||
		isNotCostSaving

	if (isChallenge) {
		return (
			<FlexBox flexDirection="column" width="100%">
				<FlexBox>
					{getString('CURRENT_COST')}:&nbsp;
					<div
						className="suggestion-card-footer full"
						title={part.actualResult}
					>
						${part.actualResult?.toFixed(2)}
					</div>
				</FlexBox>
			</FlexBox>
		)
	}

	if (showResultLabel) {
		return (
			<FlexBox alignItems="center" justifyContent="flex-start">
				{iconName && isNotCostSaving && (
					<IconFactory
						iconName={iconName}
						className="project-results--label--title--icon grid-icon"
					/>
				)}
				<div
					title={
						isPartUpdating || (!review3dInProjectAnalysis && !isPartUnprintable)
							? resultLabelText
							: null
					}
					data-qa="data-qa-part-row-printability-status"
				>
					{resultLabelText}
				</div>
			</FlexBox>
		)
	}

	return (
		<FlexBox flexDirection="column" width="100%">
			<FlexBox>
				{getString('SOLUTION_BENEFIT_COST_SAVING_NAME')}:&nbsp;
				<div className="suggestion-card-footer full" title={footerInfo}>
					{footerInfo}
				</div>
			</FlexBox>
			{!disableMethod && (
				<FlexBox>
					{getString('MATERIAL_COST_TECHNOLOGY')}:&nbsp;
					<div>
						{getString(manufacturingMethodStrings[manufacturingMethodString])}
					</div>
				</FlexBox>
			)}
		</FlexBox>
	)
}

export default memo(PartCardFooter)
