import React, { Component } from 'react'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import { bindActionCreators } from 'redux'

import cx from 'classnames'
import { isEmpty } from 'lodash'

import * as FilterPartsAction from '../../../Components/FilterPartsGrid/FilterPartsAction'
import * as StarIconActions from '../../../Components/StarIcon/StarIconActions'
import * as ProjectAnalysisActions from './ProjectAnalysisActions'
import { ProjectStatus } from '../../../../Services/models/IProject'
import { NAV_TITLE_PROJECT_ANALYSIS } from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { gerUrlParams } from '../../../../Services/Utils/urlTools'
import { getTheme } from '../../../../themes/getTheme'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import ProjectExtraFeaturesSection from '../../../Components/ProjectExtraFeaturesSection'
import {
	onTourStop,
	onUpdateTourSteps,
	updateTourAction
} from '../../../Components/TakeATour/TakeATourActions'
import HeaderCard from '../../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import WeightReductionProgress from '../../../Components/WeightReductionProgress'
import Loader from '../../../Loader/Loader'
import SolutionFea from '../../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionFea'
import { METADATA } from '../../NewUploadProject/constants'
import { clearUploadMessages } from '../../NewUploadProject/UploadProjectActions'
import { setupClustersOverview } from '../../PrintableClusters/PrintableClustersOverviewActions'
import { resetProjectInformation } from '../../ProjectPage/ProjectPageAction'
import {
	projectPageScrollParams,
	ProjectUrlParams
} from '../../ProjectPage/ProjectPageConstants'
import ProjectFail from '../../ProjectPending/ProjectFail'
import ProjectPending from '../../ProjectPending/ProjectPending'
import {
	confirmConsolidationAnalysis,
	sendMultiplePartsIntoOneRequest
} from '../MultiplePartsIntoOneWidgits/RequestMultiplePartsIntoOneCardActions'
import QuickCADProjectResults from '../QuickCADProjectResults'
import ProjectMultipleParts from './ProjectMultipleParts/ProjectMultipleParts'
import { styles } from './ProjectMultipleParts/style'
import FailedPartsWarning from './ProjectsResult/FailedPartsWarning'
import ProjectsResult from './ProjectsResult/index'
import ProjectAdminWarning from './ProjectsResult/ProjectAdminWarning'
import ProjectAlerts from './ProjectsResult/ProjectAlerts'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import './ProjectAnalysis.scss'

const { cardBoxColor } = getTheme()

class ProjectAnalysis extends Component {
	componentDidMount() {
		const {
			project,
			match: {
				params: { projectId, feaId }
			},
			setupProjectAnalysis,
			pageName,
			tourPages,
			pagesVisited,
			flatRoutes,
			onUpdateTourSteps,
			resetWeightReductionProgressTourStep,
			filterPartsDefaultSelection,
			printersFullData,
			selectedFilterValues
		} = this.props
		onUpdateTourSteps(tourPages, pageName, true)
		resetWeightReductionProgressTourStep()
		const { scrollToBottom } = gerUrlParams([ProjectUrlParams.scrollToBottom])

		if ((!project || project.id !== projectId) && flatRoutes?.length) {
			setupProjectAnalysis(
				projectId,
				selectedFilterValues || filterPartsDefaultSelection,
				pageName,
				tourPages,
				pagesVisited,
				feaId,
				printersFullData
			)

			scrollToBottom === 'true' &&
				scroller.scrollTo('scrollBottom', projectPageScrollParams)
		}
	}

	componentWillUnmount = () => {
		this.props.onTourStop()
	}

	componentDidUpdate(prevProps) {
		const {
			match: {
				params: { projectId }
			},
			setupProjectAnalysis,
			doRefresh,
			pageName,
			pagesVisited,
			tourPages,
			filterPartsDefaultSelection,
			printersFullData,
			project,
			selectedFilterValues
		} = this.props
		const {
			match: {
				params: { projectId: prevProjectId }
			}
		} = prevProps
		if (
			(project && project.id !== projectId) ||
			projectId !== prevProjectId ||
			doRefresh
		) {
			setupProjectAnalysis(
				projectId,
				selectedFilterValues || filterPartsDefaultSelection,
				pageName,
				tourPages,
				pagesVisited,
				null,
				printersFullData
			)
		}
	}

	renderExtraFeaturesSection(is2dProject, allPartsInapplicable) {
		const {
			showMultiplePartsIntoOneResults,
			project,
			showMultiplePartsIntoOneRequest,
			clusterRequested,
			clusterRequestText,
			projectClusterStatus,
			weightReductionRequested,
			weightReductionRequestText,
			weightReductionItemsWithPictures,
			projectWeightReductionThreshold,
			projectWeightReductionMinimumThicknessThreshold,
			onWeightReductionThresholdChanged,
			onWeightReductionMinimumThicknessThresholdChanged,
			sendWeightReductionRequest,
			weightReductionLoading,
			projectWeightReductionStatus,
			onUpdateWeightReductionButtonClick,
			updateWeightReductionButtonLoading,
			isSinglePartProject,
			reducePartWeightClick,
			materialCountInfo,
			confirmConsolidationAnalysis,
			sendMultiplePartsIntoOneRequest,
			setupClustersOverview,
			clusterItemsWithPictures,
			onlyPart
		} = this.props

		const isMetadata =
			!!project?.metadataParts || onlyPart?.formatType === METADATA

		if (is2dProject || allPartsInapplicable) {
			return <></>
		}

		return (
			<div style={styles.combineCard}>
				<ProjectExtraFeaturesSection
					disabled={isMetadata}
					setupClustersOverview={setupClustersOverview}
					clusterItemsWithPictures={clusterItemsWithPictures}
					sendMultiplePartsIntoOneRequest={sendMultiplePartsIntoOneRequest}
					confirmConsolidationAnalysis={confirmConsolidationAnalysis}
					materialCountInfo={materialCountInfo}
					reducePartWeightClick={reducePartWeightClick}
					isSinglePartProject={isSinglePartProject}
					showMultiplePartsIntoOneRequest={showMultiplePartsIntoOneRequest}
					project={project}
					projectWeightReductionStatus={projectWeightReductionStatus}
					clusterRequested={clusterRequested}
					clusterRequestText={clusterRequestText}
					projectClusterStatus={projectClusterStatus}
					showMultiplePartsIntoOneResults={showMultiplePartsIntoOneResults}
					weightReductionRequested={weightReductionRequested}
					weightReductionRequestText={weightReductionRequestText}
					weightReductionItemsWithPictures={weightReductionItemsWithPictures}
					projectWeightReductionThreshold={projectWeightReductionThreshold}
					projectWeightReductionMinimumThicknessThreshold={
						projectWeightReductionMinimumThicknessThreshold
					}
					onWeightReductionThresholdChanged={onWeightReductionThresholdChanged}
					onWeightReductionMinimumThicknessThresholdChanged={
						onWeightReductionMinimumThicknessThresholdChanged
					}
					weightReductionLoading={weightReductionLoading}
					sendWeightReductionRequest={() =>
						sendWeightReductionRequest(
							project?.id,
							projectWeightReductionThreshold,
							projectWeightReductionMinimumThicknessThreshold
						)
					}
					onUpdateWeightReductionButtonClick={() =>
						onUpdateWeightReductionButtonClick(
							project?.id,
							projectWeightReductionThreshold
						)
					}
					updateWeightReductionButtonLoading={
						updateWeightReductionButtonLoading
					}
				/>
			</div>
		)
	}

	render() {
		const {
			project,
			reducePartWeightClick,
			reducePartWeightCloseClick,
			showWeightReduceProgressPopup,
			WeightReductionMaterialsData,
			printersFullData,
			reduceWeightPartIdClick,
			showFeaAnalysisProjectAlert,
			solutionFeaStrengthTitle,
			solutionFeaStrength,
			solutionFeaSDStrength,
			solutionFeaUserInputTitle,
			solutionFeaSliderMarks,
			solutionFeaSliderMaxValue,
			solutionFeaSliderStartValue,
			solutionFeaSliderValue,
			solutionFeaSliderIsRange,
			solutionFeaSliderUnits,
			solutionFeaSliderMinValue,
			solutionFeaResult,
			solutionFeaAlertLoading,
			feaAnalysisResultsId,
			solutionFea,
			onProjectFeaAlertCancel,
			onProjectFeaAlertConfirm,
			reduceWeightPartSelectedIsStl,
			reduceWeightPartSelectedHasBrepData,
			is2dProject,
			isMetaDataProject,
			allPartsInapplicable,
			onlyPart,
			isSinglePartProject,
			amountOfLockedParts,
			showStandardCostFinishIcon,
			showExportButton,
			onlyPartResultTitle,
			partsProperties,
			selectedFilterPart,
			showingSimpleAlertText,
			showingSimpleAlertTitle,
			removeAlert,
			showRemovePartAlert,
			onRemovePartCancel,
			onRemovePartConfirm,
			requestedRemovePartName,
			loadingRemovePart,
			requestedRemovePartId,
			showStandardCostAlert,
			onStandardCostClick,
			partsStandardCosts,
			onPartsStandardCostUpdated,
			projectId,
			updateStandardCostLoading,
			disableStandardCostSubmit,
			onPartsStandardConfirmed,
			showStandardCostError,
			showStandardCostNumberError,
			showNameDuplicationError,
			userHasNoPermissions,
			partsStandardCostsBeforeChanges,
			parts,
			initialSetup,
			allPartsFailed,
			showFailedPartsWarning,
			removeFailWarningFromProject
		} = this.props

		const quickCADUploadFeatureIsOn = Feature.isFeatureOn(
			FeatureComponentId.QUICK_CAD_UPLOAD
		)
		const singlePartProjectViewIsOn = Feature.isFeatureOn(
			FeatureComponentId.SINGLE_PART_PROJECT_VIEW
		)

		if (
			userHasNoPermissions ||
			(project?.status === ProjectStatus.published && allPartsFailed)
		) {
			return (
				<ProjectFail
					project={project}
					projectFail={true}
					userHasNoPermissions={userHasNoPermissions}
					quickProjectConnectionError={false}
				/>
			)
		}

		if (!isEmpty(project) && project?.status !== ProjectStatus.published) {
			return <ProjectPending project={project} />
		}

		if (quickCADUploadFeatureIsOn) {
			return <QuickCADProjectResults isBundle={false} />
		}

		const emptyParts =
			!parts.length && isEmpty(onlyPart) && !amountOfLockedParts

		return (
			<NavBarAndMaterial
				title={NAV_TITLE_PROJECT_ANALYSIS}
				className="project-analysis-block"
			>
				{initialSetup && emptyParts ? (
					<Loader
						wrapperClassName="project-analysis-block__loader"
						load={true}
						fullPage={true}
					/>
				) : (
					<></>
				)}
				<WeightReductionProgress
					show={showWeightReduceProgressPopup}
					onConfirm={reducePartWeightClick}
					onCancel={reducePartWeightCloseClick}
					WeightReductionMaterialsData={WeightReductionMaterialsData}
					partId={reduceWeightPartIdClick}
					radioButtonSelected={'1'}
					printersFullData={printersFullData}
					isSTLFile={reduceWeightPartSelectedIsStl}
					hasBrepData={reduceWeightPartSelectedHasBrepData}
					projectId={
						project && typeof project === 'object' ? project.id : project
					}
				/>
				<SolutionFea
					show={showFeaAnalysisProjectAlert}
					onCancel={() => onProjectFeaAlertCancel(solutionFea?.id)}
					onConfirm={userSelectedInputs =>
						onProjectFeaAlertConfirm(
							solutionFea.id,
							userSelectedInputs,
							feaAnalysisResultsId,
							solutionFea.configuration,
							solutionFea.part.id
						)
					}
					strengthTitle={solutionFeaStrengthTitle}
					strength={solutionFeaStrength}
					SDStrength={solutionFeaSDStrength}
					userInputTitle={solutionFeaUserInputTitle}
					sliderMarks={solutionFeaSliderMarks}
					sliderMaxValue={solutionFeaSliderMaxValue}
					sliderStartPointValue={solutionFeaSliderStartValue}
					solutionFeaSliderValue={solutionFeaSliderValue}
					solutionFeaSliderIsRange={solutionFeaSliderIsRange}
					solutionFeaSliderUnits={solutionFeaSliderUnits}
					solutionFeaSliderMinValue={solutionFeaSliderMinValue}
					feaResult={solutionFeaResult}
					solutionFeaAlertLoading={solutionFeaAlertLoading}
				/>
				<br />
				<ProjectAdminWarning project={project} />
				<FailedPartsWarning
					show={showFailedPartsWarning}
					onRemoveWarningClick={() => removeFailWarningFromProject(projectId)}
				/>
				<div style={styles.gridSuper}>
					<ProjectsResult
						project={project}
						isSinglePartProject={
							isSinglePartProject && singlePartProjectViewIsOn
						}
						is2dProject={is2dProject}
						isMetaDataProject={isMetaDataProject}
						onlyPart={onlyPart}
						amountOfLockedParts={amountOfLockedParts}
						showStandardCostFinishIcon={showStandardCostFinishIcon}
						showExportButton={showExportButton}
						onlyPartResultTitle={onlyPartResultTitle}
						selectedFilterPart={selectedFilterPart}
					/>
					{this.renderExtraFeaturesSection(
						is2dProject,
						allPartsInapplicable,
						emptyParts
					)}
					{(!singlePartProjectViewIsOn || !isSinglePartProject) && (
						<div className="general-part-statistics__block">
							<HeaderCard
								contentRootClassName={cx('general-part-statistics', {
									'all-inapplicable': allPartsInapplicable
								})}
								id={`general-parts-statistics`}
								cardTitle={getString('PARTS')}
								headerColor={cardBoxColor || 'green'}
								iconColor="red"
								content={<ProjectMultipleParts />}
							/>
						</div>
					)}
				</div>
				<br />
				<ProjectAlerts
					showingSimpleAlertText={showingSimpleAlertText}
					showingSimpleAlertTitle={showingSimpleAlertTitle}
					removeAlert={removeAlert}
					showRemovePartAlert={showRemovePartAlert}
					onRemovePartCancel={onRemovePartCancel}
					onRemovePartConfirm={onRemovePartConfirm}
					requestedRemovePartName={requestedRemovePartName}
					loadingRemovePart={loadingRemovePart}
					requestedRemovePartId={requestedRemovePartId}
					onlyPart={onlyPart}
					showStandardCostAlert={showStandardCostAlert}
					onStandardCostClick={onStandardCostClick}
					partsStandardCosts={partsStandardCosts}
					onPartsStandardCostUpdated={onPartsStandardCostUpdated}
					projectId={projectId}
					updateStandardCostLoading={updateStandardCostLoading}
					disableStandardCostSubmit={disableStandardCostSubmit}
					onPartsStandardConfirmed={onPartsStandardConfirmed}
					showStandardCostError={showStandardCostError}
					showStandardCostNumberError={showStandardCostNumberError}
					showNameDuplicationError={showNameDuplicationError}
					partsStandardCostsBeforeChanges={partsStandardCostsBeforeChanges}
					isBundle={false}
				/>
				<div id="scrollBottom" />
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	ProjectAnalysisReducer,
	user,
	userHome: { flatRoutes },
	PrintableClustersOverviewReducer: {
		materialCountInfo,
		clusterItemsWithPictures
	}
}) => {
	const {
		userDetails: { admin, email, pagesVisited: pagesVisitedFromUserDetails },
		pagesVisited,
		pages,
		filterPartsDefaultSelection,
		printersFullData,
		roles,
		contactUsEmail
	} = user

	return {
		...ProjectAnalysisReducer,
		isAdmin: admin,
		email,
		pagesVisited,
		pagesVisitedFromUserDetails,
		tourPages: pages,
		flatRoutes,
		filterPartsDefaultSelection,
		printersFullData,
		materialCountInfo,
		roles,
		contactUsEmail,
		user,
		clusterItemsWithPictures
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			...FilterPartsAction,
			...ProjectAnalysisActions,
			...StarIconActions,
			updateTourAction,
			onUpdateTourSteps,
			onTourStop,
			clearUploadMessages,
			sendMultiplePartsIntoOneRequest,
			confirmConsolidationAnalysis,
			setupClustersOverview,
			resetProjectInformation
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAnalysis)
