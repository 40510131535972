import { AxiosPromise } from 'axios'

import { Part } from '../models/IPart'
import { IStandardCostData } from '../models/IStandardCostData'
import { WeightReductionGen2Type } from '../models/IWeightReductionGen2'
import { del, get, post, put } from '../Network'

export const removePart = (PartId: number, projectId: string) => {
	return del(`/parts/${PartId}?projectId=${projectId}`)
}

export const removeProject = (id: string) => {
	return del(`/projects/${id}`)
}

export const updateGrabCad = (
	id: number,
	toggleGrabCad: boolean,
	configurationId?: number
) => {
	return put(`/grabCadAnalysis/${id}`, {
		toggleGrabCad: !toggleGrabCad,
		configurationId: configurationId || null
	})
}

export const cancelProject = async (id: string) => {
	return del(`/upload/projects/${id}`)
}

export const weightReductionRequest = (
	projectId: string,
	projectWeightReductionThreshold: number,
	projectWeightReductionMinimumThicknessThreshold: number
) => {
	const params = {
		projectWeightReductionThreshold,
		projectWeightReductionMinimumThicknessThreshold
	}
	return post(`/weightReduction/${projectId}`, params)
}

export const removeProjectFailWarning = (projectId: string) => {
	return put(`/projects/failWarning/${projectId}`)
}

export const getWeightReductionRequest = (projectId: string) => {
	return get(`/weightReduction/${projectId}`)
}

export const getMoreWeightReductionRequest = (
	projectId: string,
	currentPartsCount: number
) => {
	return get(
		`/weightReduction/${projectId}?currentPartsCount=${currentPartsCount}`
	)
}

export const updateWeightReduction = (
	projectId: string,
	projectWeightReductionThreshold: number
) => {
	const params = {
		projectWeightReductionThreshold
	}
	return put(`/weightReduction/${projectId}`, params)
}

export const updateStandardCosts = (
	standardCostData: IStandardCostData,
	projectId: string
): AxiosPromise<{ parts: Part[] }> | undefined => {
	const params = {
		standardCostData: JSON.stringify(standardCostData)
	}
	return put(`/standardCost/${projectId}`, params)
}

export const weightReductionGen2Request = (
	configurationId: number,
	weightReductionType: WeightReductionGen2Type
) => {
	const params = {
		configurationId,
		weightReductionType
	}
	return post('/weightReductionGen2', params)
}
