import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isEmpty } from 'lodash'

import { useChallengesRows } from './ChallengeTabService'
import DataTable from 'Scenes/Components/DataTable'
import {
	getComplexityScore,
	getCostVolumeRatioUnit
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ChallengeFilter/ChallengeFilterAlertService'
import {
	convertMMtoCM,
	convertPriceToVolume
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/PartsListView/PartsListViewService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

interface IChallengeProps {
	part: Part
	configuration: IConfiguration
}

const ChallengeTab: FC<IChallengeProps> = ({ part, configuration }) => {
	const user = useSelector((state: any) => state.user)
	const unit = getCostVolumeRatioUnit(user.userUnitSystem)

	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)

	const partVolumeUnit = useMemo(
		() =>
			convertPriceToVolume(
				configuration?.costResults?.actualResult / part?.volume || 0,
				customizeUnitSystem,
				user.userUnitSystem
			),
		[
			configuration?.costResults?.actualResult,
			part?.volume,
			customizeUnitSystem,
			user.userUnitSystem
		]
	)

	const partVolumeAverage = useMemo(
		() =>
			convertPriceToVolume(
				part?.averageCosts?.averageActualResultToVolume || 0,
				customizeUnitSystem,
				user.userUnitSystem
			),
		[
			part?.averageCosts?.averageActualResultToVolume,
			customizeUnitSystem,
			user.userUnitSystem
		]
	)

	const partCostVolumeCube = useMemo(
		() =>
			user.userUnitSystem === UnitSystem.metric
				? convertMMtoCM(partVolumeUnit)
				: partVolumeUnit,
		[partVolumeUnit, user.userUnitSystem]
	)

	const partCostVolumeAverage = useMemo(
		() =>
			user.userUnitSystem === UnitSystem.metric
				? convertMMtoCM(partVolumeAverage)
				: partVolumeAverage,
		[partVolumeAverage, user.userUnitSystem]
	)

	const complexityScore = useMemo(
		() =>
			getComplexityScore(
				part?.partComplexityScore,
				user.defaultComplexityScore
			),
		[part?.partComplexityScore, user.defaultComplexityScore]
	)

	const challengeRows = useChallengesRows(
		part,
		configuration,
		unit,
		complexityScore,
		partCostVolumeCube,
		partCostVolumeAverage
	)

	return (
		<div>
			<DataTable
				tableClassName="solution-tab--material-table solution-tab--material-two-column solution-tab--new-table challenges"
				tableHead={[[], []]}
				tableDataRows={challengeRows}
				showBreakLines={true}
				showBreakLineAtEndOfTable
			/>
			{isEmpty(part.challenges) && (
				<div className="solution-tab--no-challenges">
					{getString('NO_CHALLENGES_FOUND')}
				</div>
			)}
		</div>
	)
}

export default ChallengeTab
