import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { isEmpty } from 'lodash'

import FailedPartsWarning from '../../ProjectAnalysis/ProjectSuggestionPage/ProjectsSuggestionResult/FailedPartsWarning'
import QuickCADProjectResults from '../../ProjectAnalysis/QuickCADProjectResults'
import ProjectBundleMultipleParts from './ProjectBundleMultipleParts'
import { clearRoutingPoller } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import {
	onPartsStandardConfirmed,
	onPartsStandardCostUpdated,
	onRemovePartCancel,
	onRemovePartConfirm,
	onStandardCostClick,
	removeAlert,
	removeFailWarningFromProject
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionActions'
import ProjectAlerts from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectsSuggestionResult/ProjectAlerts'
import ProjectResultDistributionChart from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionAnalysisChart/ProjectResultDistributionChart'
import ProjectPdfModal from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/ProjectExportPDF/ProjectPdfModal'
import { styles } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/style'
import ProjectSuggestionTabTitle from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionTabTitle'
import { getProjectsBundle } from 'Scenes/Home/ProjectBundle/ProjectSuggestionBundle/ProjectBundleActions'
import {
	projectPageScrollParams,
	ProjectUrlParams
} from 'Scenes/Home/ProjectPage/ProjectPageConstants'
import ProjectFail from 'Scenes/Home/ProjectPending/ProjectFail'
import ProjectPending from 'Scenes/Home/ProjectPending/ProjectPending'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectStatus } from 'Services/models/IProject'
import { PROJECT_ANALYSIS_SUB_TITLE, RESULTS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { gerUrlParams } from 'Services/Utils/urlTools'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

const NavBarAndMaterialTSX: any = NavBarAndMaterial
const ProjectResultDistributionChartTSX: any = ProjectResultDistributionChart

const ProjectSuggestionBundle: FC = () => {
	const [showPDFModal, setShowPDFModal] = useState(false)

	const { projectId } = useParams() as { projectId: string }
	const dispatch = useDispatch()
	const { scrollToBottom } = gerUrlParams([ProjectUrlParams.scrollToBottom])

	const {
		projects,
		project,
		numberOfPrintableParts,
		numberOfAllBenefits,
		benefitsState,
		numberOfPrintablePart,
		bundleId,
		showStandardCostAlert,
		partsStandardCosts,
		updateStandardCostLoading,
		disableStandardCostSubmit,
		showStandardCostError,
		showStandardCostNumberError,
		showNameDuplicationError,
		showingSimpleAlertText,
		showingSimpleAlertTitle,
		showRemovePartAlert,
		requestedRemovePartName,
		loadingRemovePart,
		onlyPart,
		requestedRemovePartId,
		doRefreshConfigurations,
		userHasNoPermissions,
		partsStandardCostsBeforeChanges,
		totalPartsCount,
		parts,
		initialSetup,
		allPartsFailed,
		showFailedPartsWarning,
		selectedTab,
		selectedFilterValues,
		searchPhrase,
		leadingConfigurationData,
		paginationData,
		partsToPrintLoading,
		partsToPrintSummary,
		partsToPrint,
		opportunitiesData,
		resultType,
		sortingCriteria,
		sortBy
	} = useSelector(
		(state: RootStateOrAny) => state.ProjectBundleSuggestionReducer
	)

	const quickCADUploadFeatureIsOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	useEffect(() => {
		dispatch(clearRoutingPoller())

		scrollToBottom === 'true' &&
			scroller.scrollTo('scrollBottom', projectPageScrollParams)
	}, [])

	useEffect(() => {
		dispatch(getProjectsBundle(projectId))
	}, [projectId])

	useEffect(() => {
		if (doRefreshConfigurations) {
			dispatch(getProjectsBundle(projectId))
		}
	}, [doRefreshConfigurations])

	const projectAssembly = useMemo(
		() => projects.filter((project: any) => !!project.isAssembly),
		[projects]
	)

	if (
		userHasNoPermissions ||
		(project?.status === ProjectStatus.published && allPartsFailed)
	) {
		return (
			<ProjectFail
				project={project}
				projectFail={true}
				userHasNoPermissions={userHasNoPermissions}
				quickProjectConnectionError={false}
			/>
		)
	}

	if (!isEmpty(project) && project?.status !== ProjectStatus.published) {
		return (
			<ProjectPending
				project={project}
				projectBundleId={bundleId}
				projects={projects}
			/>
		)
	}

	if (quickCADUploadFeatureIsOn) {
		return <QuickCADProjectResults isBundle />
	}

	const emptyParts = !parts.length && isEmpty(onlyPart)

	return (
		<NavBarAndMaterialTSX title={getString('NAV_TITLE_BUNDLE_PROJECTS')}>
			{initialSetup && emptyParts ? (
				<Loader
					wrapperClassName="project-analysis-block__loader"
					load={true}
					fullPage={true}
				/>
			) : (
				<></>
			)}
			<FailedPartsWarning
				show={showFailedPartsWarning}
				onRemoveWarningClick={() =>
					dispatch(removeFailWarningFromProject(bundleId))
				}
			/>
			<div className="project-bundle">
				<Flexbox
					flexDirection="row"
					style={{ margin: '0 15px', width: 'calc(100% - 30px)' }}
				>
					<HeaderCard
						id={`general-part-bundle-statistics`}
						cardTitle={RESULTS}
						headerColor={cardBoxColor || 'green'}
						cardSubtitle={PROJECT_ANALYSIS_SUB_TITLE}
						iconColor="red"
						content={
							<ProjectResultDistributionChartTSX
								style={styles.assemblyProjectResultChart}
								contentStyle={styles.chartWhenAssembly}
								isAssembly={!!projectAssembly?.length}
								projectsAssembly={projectAssembly}
								image={''}
								name={''}
								numberOfPrintablePart={numberOfPrintablePart}
								printabilityData={opportunitiesData}
								totalPartsCount={totalPartsCount}
								benefitsState={benefitsState}
								numberOfAllBenefits={numberOfAllBenefits}
								project={project}
								assemblyImage={styles.assemblyImage}
								is2dProject={false}
								isMetaDataProject={false}
								allPartsInapplicable={false}
								numberOfPrintableParts={numberOfPrintableParts}
							/>
						}
					/>
				</Flexbox>
				<div className="general-part-statistics__block">
					<ProjectSuggestionTabTitle active={selectedTab} />
					<HeaderCard
						contentRootClassName="general-part-statistics"
						id={`general-parts-bundle-statistics`}
						cardTitle={getString('PARTS')}
						headerColor={cardBoxColor || 'green'}
						iconColor="red"
						content={
							<ProjectBundleMultipleParts
								showPDFModal={showPDFModal}
								setShowPDFModal={setShowPDFModal}
							/>
						}
					/>
				</div>
			</div>
			<ProjectAlerts
				showingSimpleAlertText={showingSimpleAlertText}
				showingSimpleAlertTitle={showingSimpleAlertTitle}
				removeAlert={() => dispatch(removeAlert())}
				showRemovePartAlert={showRemovePartAlert}
				onRemovePartCancel={() => dispatch(onRemovePartCancel())}
				onRemovePartConfirm={(
					partId: number,
					externalId: string,
					isBundle: boolean
				) => dispatch(onRemovePartConfirm(partId, externalId, isBundle))}
				requestedRemovePartName={requestedRemovePartName}
				loadingRemovePart={loadingRemovePart}
				requestedRemovePartId={requestedRemovePartId}
				onlyPart={onlyPart}
				showStandardCostAlert={showStandardCostAlert}
				onStandardCostClick={() => dispatch(onStandardCostClick())}
				partsStandardCosts={partsStandardCosts}
				onPartsStandardCostUpdated={(data: any) =>
					dispatch(onPartsStandardCostUpdated(data))
				}
				projectId={projectId}
				updateStandardCostLoading={updateStandardCostLoading}
				disableStandardCostSubmit={disableStandardCostSubmit}
				onPartsStandardConfirmed={(data: any) =>
					dispatch(
						onPartsStandardConfirmed(
							data,
							bundleId,
							partsStandardCostsBeforeChanges
						)
					)
				}
				showStandardCostError={showStandardCostError}
				showStandardCostNumberError={showStandardCostNumberError}
				showNameDuplicationError={showNameDuplicationError}
				isBundle
			/>
			{showPDFModal && (
				<ProjectPdfModal
					paginationData={paginationData}
					sortBy={sortBy}
					sortingCriteria={sortingCriteria}
					selectedTab={selectedTab}
					resultType={resultType}
					showModal={showPDFModal}
					closeModal={() => {
						setShowPDFModal(false)
					}}
					projectId={projectId}
					selectedFilterValues={selectedFilterValues}
					searchPhrase={searchPhrase}
					projectName={project.name}
					opportunitiesData={opportunitiesData}
					partsToPrintLoading={partsToPrintLoading}
					totalPartsCount={totalPartsCount}
					partsToPrintSummary={partsToPrintSummary}
					partsToPrint={partsToPrint}
					leadingConfigurationData={leadingConfigurationData}
					shouldRequestAllParts={paginationData.totalPagesCount > 1}
					isBundle
				/>
			)}
			<div id="scrollBottom" />
		</NavBarAndMaterialTSX>
	)
}

export default memo(ProjectSuggestionBundle)
