import React, { memo, useEffect, useMemo, useState } from 'react'

import {
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	RadioGroup,
	Select,
	TextField
} from '@material-ui/core'
import { capitalize } from 'lodash'

import {
	convertMMtoCM,
	convertPriceToVolume
} from '../PartsListView/PartsListViewService'
import { ClientChallengesType } from '../ProjectAnalysisSuggestionService'
import {
	complexityScoreOptions,
	getRadioButtonInputText,
	getRadioButtonUnit,
	minCountInAssembly,
	SelectedType,
	SelectedTypeEnum,
	tightToleranceOptions
} from './ChallengeFilterAlertService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorRadio from 'Scenes/Components/CastorRadio'
import FlexBox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

const TextFieldTsx: any = TextField

interface ChallengeFilterAlertProps {
	value: string
	type: SelectedType
	challengeType: ClientChallengesType
	onSubmit: (
		value: string | number,
		selectedType: SelectedType,
		challengeType: ClientChallengesType
	) => void
	onCancel: () => void
	show: boolean
	userUnitSystem: UnitSystem
	challengesFilterLoading: boolean
	customizeUnitSystem?: boolean
}

const ChallengeFilterAlert: React.FC<ChallengeFilterAlertProps> = ({
	value,
	type,
	challengeType,
	onSubmit,
	show,
	onCancel,
	challengesFilterLoading,
	userUnitSystem,
	customizeUnitSystem
}) => {
	const [error, setError] = useState('')
	const [selectedType, setSelectedType] = useState<SelectedType>(type)
	const [inputValue, setInputValue] = useState<string | number>('')
	const [selectedValue, setSelectedValue] = useState<string>(value)
	const selectValueText = getRadioButtonInputText(challengeType, userUnitSystem)
	const unit = getRadioButtonUnit(challengeType, userUnitSystem)

	useEffect(() => {
		let updatedValue = convertDataIfNeeded(value, selectedType)
		setInputValue(updatedValue)
	}, [])

	const convertDataIfNeeded = (
		value: string | number,
		selectedValueType: SelectedType
	) => {
		let updatedValue = value

		if (
			challengeType === ClientChallengesType.hightCostToVolume &&
			selectedValueType === SelectedTypeEnum.input
		) {
			updatedValue = convertPriceToVolume(
				+value,
				customizeUnitSystem,
				userUnitSystem
			)
			updatedValue =
				userUnitSystem === UnitSystem.metric
					? convertMMtoCM(updatedValue)
					: updatedValue
		}

		return updatedValue
	}

	const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedType(e.target.value as SelectedType)

		if (type !== e.target.value) {
			setInputValue('')
		} else {
			const updatedValue = convertDataIfNeeded(value, e.target.value)
			setInputValue(updatedValue)
		}
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}

	const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedValue(e.target.value as string)
	}

	const handleSubmit = () => {
		const finalValue =
			selectedType === SelectedTypeEnum.select ? selectedValue : inputValue
		onSubmit(finalValue, selectedType, challengeType)
	}
	const disabledAlert = useMemo(() => {
		if (
			challengeType === ClientChallengesType.countInAssembly &&
			selectedType === SelectedTypeEnum.input &&
			+inputValue < minCountInAssembly
		) {
			setError(getString('NUMBER_VALIDATION_FROM').format(minCountInAssembly))
			return true
		}

		if (error) {
			setError('')
		}

		if (selectedType === SelectedTypeEnum.select) {
			return false
		}

		return (
			inputValue === '' &&
			(selectedType === SelectedTypeEnum.input ||
				selectedType === SelectedTypeEnum.percentage)
		)
	}, [selectedType, inputValue, challengeType, error])

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	const renderContent = () => {
		if (selectedType === SelectedTypeEnum.select) {
			const options =
				challengeType === ClientChallengesType.tightTolerance
					? tightToleranceOptions
					: complexityScoreOptions

			return (
				<FormControl fullWidth>
					<InputLabel id="select-label">
						{getString('CHALLENGES_ALERT_SELECT')}
					</InputLabel>
					<Select
						labelId="select-label"
						value={selectedValue}
						onChange={handleSelectChange}
					>
						{options.map(option => (
							<MenuItem key={option.value} value={option.value}>
								{capitalize(option.label)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		}

		return (
			<div className="challenge-filter">
				<RadioGroup
					color="primary"
					className="challenge-filter--radio-group"
					onChange={handleTypeChange}
					value={selectedType}
				>
					<FormControlLabel
						className="challenge-radio"
						value={SelectedTypeEnum.input}
						control={<CastorRadio color="primary" />}
						label={selectValueText}
					/>
					<FormControlLabel
						className="challenge-radio"
						value={SelectedTypeEnum.percentage}
						control={<CastorRadio color="primary" />}
						label={getString('CHALLENGES_ALERT_ADD_PERCENTAGE')}
					/>
				</RadioGroup>
				<FlexBox className="challenge-filter--input" alignItems="center">
					<TextFieldTsx
						error={!!error}
						helperText={error}
						type="number"
						value={inputValue}
						onKeyDown={handleKeyDown}
						onChange={handleInputChange}
					/>
					<span>
						{selectedType === SelectedTypeEnum.percentage ? '%' : unit}
					</span>
				</FlexBox>
			</div>
		)
	}

	return (
		<CastorAlert
			show={show}
			headerTitle={getString('CHALLENGES_ALERT_TITLE')}
			onConfirm={handleSubmit}
			onCancel={onCancel}
			showCancel={true}
			confirmOptionalText={getString('SUBMIT')}
			disabled={disabledAlert}
			loading={challengesFilterLoading}
		>
			{renderContent()}
		</CastorAlert>
	)
}

export default memo(ChallengeFilterAlert)
