import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import TransparentButton from '../TransparentButton'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'

interface IProps {
	isOnPrem: boolean
	showImage: boolean
	isMaterialsNames: boolean
	isGenerativeFileOption: boolean
	isUserMaterialsNames: boolean
}

const BomTemplate: FC<IProps> = ({
	isOnPrem,
	showImage,
	isMaterialsNames = false,
	isGenerativeFileOption = false,
	isUserMaterialsNames = false
}) => {
	const {
		defaultBomFilePath,
		defaultMaterialsNameBom,
		defaultUserMaterialsNameBom,
		defaultGenerativeFilePath
	} = useSelector((state: RootStateOrAny) => state.user)
	let link = isOnPrem
		? `${process.env.PUBLIC_URL}/materials/CASTOR_BOM_Template_v7.xlsx`
		: defaultBomFilePath

	if (isMaterialsNames) {
		link = isOnPrem
			? `${process.env.PUBLIC_URL}/materials/CASTOR_BOM_MATERIALS_Template.xlsx`
			: defaultMaterialsNameBom
	}
	if (isUserMaterialsNames) {
		link = isOnPrem
			? `${process.env.PUBLIC_URL}/materials/CASTOR_BOM_USER_MATERIALS_Template.xlsx`
			: defaultUserMaterialsNameBom
	}

	if (isGenerativeFileOption) {
		link = isOnPrem
			? `${process.env.PUBLIC_URL}/materials/GD_data_template_file_V1.0.xlsx`
			: defaultGenerativeFilePath
	}

	return (
		<TransparentButton
			isAnchor={true}
			href={link}
			data-qa="data-qa-download-bom-file"
			download
		>
			{showImage ? <DownloadBom /> : <></>}
			<span>
				{isGenerativeFileOption
					? getString('DOWNLOAD_GENERATIVE_DESIGN_TEMPLATE')
					: getString('DOWNLOAD_BOM_TEMPLATE')}
			</span>
		</TransparentButton>
	)
}

export default WithFeatureToggleHOC(
	memo(BomTemplate),
	FeatureComponentId.BOM_TEMPLATE
)
