import React, { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { Tab, Tabs } from '@material-ui/core'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import { ProjectStatus } from '../../../../Services/models/IProject'
import { NAV_TITLE_PROJECT_ANALYSIS } from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { gerUrlParams } from '../../../../Services/Utils/urlTools'
import { getTheme } from '../../../../themes/getTheme'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import {
	onTourStop,
	onUpdateTourSteps
} from '../../../Components/TakeATour/TakeATourActions'
import HeaderCard from '../../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import Loader from '../../../Loader/Loader'
import SolutionFea from '../../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionFea'
import {
	projectPageScrollParams,
	ProjectUrlParams
} from '../../ProjectPage/ProjectPageConstants'
import ProjectFail from '../../ProjectPending/ProjectFail'
import ProjectPending from '../../ProjectPending/ProjectPending'
import QuickCADProjectResults from '../QuickCADProjectResults'
import {
	onPartsStandardCostUpdated,
	onProjectFeaAlertCancel,
	onProjectFeaAlertConfirm,
	onRemovePartCancel,
	onRemovePartConfirm,
	onStandardCostClick,
	removeAlert,
	removeFailWarningFromProject,
	resetWeightReductionProgressTourStep,
	setupProjectAnalysis
} from './ProjectAnalysisSuggestionActions'
import FailedPartsWarning from './ProjectsSuggestionResult/FailedPartsWarning'
import ProjectsResult from './ProjectsSuggestionResult/index'
import ProjectAdminWarning from './ProjectsSuggestionResult/ProjectAdminWarning'
import ProjectAlerts from './ProjectsSuggestionResult/ProjectAlerts'
import ProjectPdfModal from './ProjectSuggestionMultipleParts/ProjectExportPDF/ProjectPdfModal'
import ProjectMultipleParts from './ProjectSuggestionMultipleParts/ProjectMultipleParts'
import { styles } from './ProjectSuggestionMultipleParts/style'
import ProjectSuggestionTabTitle from './ProjectSuggestionTabTitle'
import { clearRoutingPoller, onPartsStandardConfirmed } from 'global actions'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import './ProjectAnalysisSuggestion.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

const { cardBoxColor } = getTheme()

const ProjectSuggestionC: FC<any> = () => {
	const [showPDFModal, setShowPDFModal] = useState(false)

	const dispatch = useDispatch()
	const {
		pagesVisited,
		pages: tourPages,
		filterPartsDefaultSelection,
		printersFullData
	} = useSelector((state: RootStateOrAny) => state.user)

	const { flatRoutes } = useSelector((state: RootStateOrAny) => state.userHome)
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)

	const {
		project,
		pageName,
		selectedFilterValues,
		showFeaAnalysisProjectAlert,
		solutionFeaStrengthTitle,
		solutionFeaStrength,
		solutionFeaSDStrength,
		solutionFeaUserInputTitle,
		solutionFeaSliderMarks,
		solutionFeaSliderMaxValue,
		solutionFeaSliderStartValue,
		solutionFeaSliderValue,
		solutionFeaSliderIsRange,
		solutionFeaSliderUnits,
		solutionFeaSliderMinValue,
		solutionFeaResult,
		solutionFeaAlertLoading,
		feaAnalysisResultsId,
		solutionFea,
		is2dProject,
		isMetaDataProject,
		allPartsInapplicable,
		onlyPart,
		isSinglePartProject,
		amountOfLockedParts,
		showStandardCostFinishIcon,
		showExportButton,
		onlyPartResultTitle,
		selectedFilterPart,
		showingSimpleAlertText,
		showingSimpleAlertTitle,
		showRemovePartAlert,
		requestedRemovePartName,
		loadingRemovePart,
		requestedRemovePartId,
		showStandardCostAlert,
		partsStandardCosts,
		projectId: projectPropsId,
		updateStandardCostLoading,
		disableStandardCostSubmit,
		showStandardCostError,
		showStandardCostNumberError,
		showNameDuplicationError,
		userHasNoPermissions,
		partsStandardCostsBeforeChanges,
		parts,
		initialSetup,
		allPartsFailed,
		showFailedPartsWarning,
		doRefresh,
		selectedTab,
		searchPhrase,
		totalPartsCount,
		leadingConfigurationData,
		paginationData,
		opportunitiesData,
		partsToPrintLoading,
		partsToPrintSummary,
		partsToPrint,
		resultType,
		sortingCriteria,
		sortBy
	} = useSelector((state: RootStateOrAny) => state.ProjectSuggestionReducer)

	const { params } = useRouteMatch() as { params: Record<string, string> }
	const { projectId, feaId } = params

	useEffect(() => {
		dispatch(onUpdateTourSteps(tourPages, pageName, true))
		dispatch(resetWeightReductionProgressTourStep())
		dispatch(clearRoutingPoller())
		const { scrollToBottom } = gerUrlParams([ProjectUrlParams.scrollToBottom])

		if ((!project || project?.id !== projectId) && flatRoutes?.length) {
			dispatch(
				setupProjectAnalysis(
					projectId,
					selectedFilterValues || filterPartsDefaultSelection,
					pageName,
					tourPages,
					pagesVisited,
					feaId,
					printersFullData,
					isBundle
				)
			)

			scrollToBottom === 'true' &&
				scroller.scrollTo('scrollBottom', projectPageScrollParams)
		}

		return () => {
			dispatch(onTourStop())
		}
	}, [])

	useEffect(() => {
		if (
			(project && project?.id !== projectId) ||
			projectId !== project?.id ||
			doRefresh
		) {
			dispatch(
				setupProjectAnalysis(
					projectId,
					selectedFilterValues || filterPartsDefaultSelection,
					pageName,
					tourPages,
					pagesVisited,
					null,
					printersFullData,
					isBundle
				)
			)
		}
	}, [projectId, project])

	const quickCADUploadFeatureIsOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)
	const singlePartProjectViewIsOn = Feature.isFeatureOn(
		FeatureComponentId.SINGLE_PART_PROJECT_VIEW
	)

	if (
		userHasNoPermissions ||
		(project?.status === ProjectStatus.published && allPartsFailed)
	) {
		return (
			<ProjectFail
				project={project}
				projectFail={true}
				userHasNoPermissions={userHasNoPermissions}
				quickProjectConnectionError={false}
			/>
		)
	}

	if (!isEmpty(project) && project?.status !== ProjectStatus.published) {
		return <ProjectPending project={project} />
	}

	if (quickCADUploadFeatureIsOn) {
		return <QuickCADProjectResults isBundle={false} />
	}

	const emptyParts = !parts.length && isEmpty(onlyPart) && !amountOfLockedParts

	return (
		<NavBarAndMaterialTSX
			title={NAV_TITLE_PROJECT_ANALYSIS}
			className="project-analysis-block"
		>
			{initialSetup && emptyParts ? (
				<Loader
					wrapperClassName="project-analysis-block__loader"
					load={true}
					fullPage={true}
				/>
			) : (
				<></>
			)}
			<SolutionFea
				show={showFeaAnalysisProjectAlert}
				onCancel={() => dispatch(onProjectFeaAlertCancel(solutionFea?.id))}
				onConfirm={userSelectedInputs =>
					dispatch(
						onProjectFeaAlertConfirm(
							solutionFea.id,
							userSelectedInputs,
							feaAnalysisResultsId,
							solutionFea.configuration,
							solutionFea.part.id
						)
					)
				}
				strengthTitle={solutionFeaStrengthTitle}
				strength={solutionFeaStrength}
				SDStrength={solutionFeaSDStrength}
				userInputTitle={solutionFeaUserInputTitle}
				sliderMarks={solutionFeaSliderMarks}
				sliderMaxValue={solutionFeaSliderMaxValue}
				sliderStartPointValue={solutionFeaSliderStartValue}
				solutionFeaSliderValue={solutionFeaSliderValue}
				solutionFeaSliderIsRange={solutionFeaSliderIsRange}
				solutionFeaSliderUnits={solutionFeaSliderUnits}
				solutionFeaSliderMinValue={solutionFeaSliderMinValue}
				feaResult={solutionFeaResult}
				solutionFeaAlertLoading={solutionFeaAlertLoading}
			/>
			<br />
			<ProjectAdminWarning project={project} />
			<FailedPartsWarning
				show={showFailedPartsWarning}
				onRemoveWarningClick={() =>
					dispatch(removeFailWarningFromProject(projectId))
				}
			/>
			<div style={styles.gridSuper as any}>
				<ProjectsResult
					project={project}
					isSinglePartProject={isSinglePartProject && singlePartProjectViewIsOn}
					is2dProject={is2dProject}
					isMetaDataProject={isMetaDataProject}
					onlyPart={onlyPart}
					amountOfLockedParts={amountOfLockedParts}
					showStandardCostFinishIcon={showStandardCostFinishIcon}
					showExportButton={showExportButton}
					onlyPartResultTitle={onlyPartResultTitle}
					selectedFilterPart={selectedFilterPart}
				/>
				{(!singlePartProjectViewIsOn || !isSinglePartProject) && (
					<div className="general-part-statistics__block">
						<ProjectSuggestionTabTitle active={selectedTab} />
						<HeaderCard
							contentRootClassName={cx('general-part-statistics', {
								'all-inapplicable': allPartsInapplicable
							})}
							id={`general-parts-statistics-suggestion`}
							headerColor={cardBoxColor || 'green'}
							iconColor="red"
							content={
								<ProjectMultipleParts
									showPDFModal={showPDFModal}
									setShowPDFModal={setShowPDFModal}
								/>
							}
						/>
					</div>
				)}
			</div>
			<br />
			<ProjectAlerts
				showingSimpleAlertText={showingSimpleAlertText}
				showingSimpleAlertTitle={showingSimpleAlertTitle}
				removeAlert={() => dispatch(removeAlert())}
				showRemovePartAlert={showRemovePartAlert}
				onRemovePartCancel={() => dispatch(onRemovePartCancel())}
				onRemovePartConfirm={(
					partId: number,
					externalId: string,
					isBundle: boolean
				) => dispatch(onRemovePartConfirm(partId, externalId, isBundle))}
				requestedRemovePartName={requestedRemovePartName}
				loadingRemovePart={loadingRemovePart}
				requestedRemovePartId={requestedRemovePartId}
				onlyPart={onlyPart}
				showStandardCostAlert={showStandardCostAlert}
				onStandardCostClick={() => dispatch(onStandardCostClick())}
				partsStandardCosts={partsStandardCosts}
				onPartsStandardCostUpdated={(data: any) =>
					dispatch(onPartsStandardCostUpdated(data))
				}
				projectId={projectPropsId}
				updateStandardCostLoading={updateStandardCostLoading}
				disableStandardCostSubmit={disableStandardCostSubmit}
				onPartsStandardConfirmed={(
					data: any,
					projectId: string,
					prevPartsStandardCosts: any
				) =>
					dispatch(
						onPartsStandardConfirmed(data, projectId, prevPartsStandardCosts)
					)
				}
				showStandardCostError={showStandardCostError}
				showStandardCostNumberError={showStandardCostNumberError}
				showNameDuplicationError={showNameDuplicationError}
				partsStandardCostsBeforeChanges={partsStandardCostsBeforeChanges}
				isBundle={false}
			/>
			{showPDFModal && (
				<ProjectPdfModal
					paginationData={paginationData}
					sortBy={sortBy}
					sortingCriteria={sortingCriteria}
					selectedTab={selectedTab}
					resultType={resultType}
					showModal={showPDFModal}
					closeModal={() => {
						setShowPDFModal(false)
					}}
					isBundle={false}
					projectId={projectId}
					selectedFilterValues={selectedFilterValues}
					searchPhrase={searchPhrase}
					projectName={project.name}
					opportunitiesData={opportunitiesData}
					partsToPrintLoading={partsToPrintLoading}
					totalPartsCount={totalPartsCount}
					partsToPrintSummary={partsToPrintSummary}
					partsToPrint={partsToPrint}
					leadingConfigurationData={leadingConfigurationData}
					shouldRequestAllParts={paginationData.totalPagesCount > 1}
				/>
			)}
			<div id="scrollBottom" />
		</NavBarAndMaterialTSX>
	)
}

export default memo(ProjectSuggestionC)
