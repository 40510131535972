import { PART, PARTS } from 'Services/Strings'

export const noOpportunitiesColor = '#D9D9D9'
export const amPCColor = '#7653ED'
export const amWRColor = '#20C0E6'
export const castingColor = '#75DDDD'
export const latheColor = '#75B065'
export const latheSplitColor = '#75C265'
export const amBestMatchColor = '#78DD65'

export const opportunitiesResults = {
	amNoDesignChanges: 'amNoDesignChanges',
	amPC: 'amPC',
	amWR: 'amWR',
	casting: 'casting',
	noOpportunities: 'noOpportunities',
	lathe: 'lathe',
	latheSplit: 'latheSplit'
}

export const opportunitiesResultsColors = {
	amNoDesignChanges: amBestMatchColor,
	amPC: amPCColor,
	amWR: amWRColor,
	casting: castingColor,
	lathe: latheColor,
	latheSplit: latheSplitColor,
	noOpportunities: noOpportunitiesColor
}

export const initChartData = () => {
	return [
		{
			type: opportunitiesResults.amNoDesignChanges,
			count: 0,
			percent: 0,
			name: 'amNoDesignChanges',
			color: amBestMatchColor
		},
		{
			type: opportunitiesResults.amPC,
			count: 0,
			percent: 0,
			name: 'amPC',
			color: amPCColor
		},
		{
			type: opportunitiesResults.amWR,
			count: 0,
			percent: 0,
			name: 'amWR',
			color: amWRColor
		},
		{
			type: opportunitiesResults.casting,
			count: 0,
			percent: 0,
			name: 'casting',
			color: castingColor
		},
		{
			type: opportunitiesResults.lathe,
			count: 0,
			percent: 0,
			name: 'lathe',
			color: latheColor
		},
		{
			type: opportunitiesResults.latheSplit,
			count: 0,
			percent: 0,
			name: 'latheSplit',
			color: latheSplitColor
		},
		{
			type: opportunitiesResults.noOpportunities,
			count: 0,
			percent: 0,
			name: 'noOpportunities',
			color: noOpportunitiesColor
		}
	]
}

export const initCostChartData = () => {
	return [
		{
			type: opportunitiesResults.amNoDesignChanges,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'amNoDesignChanges',
			color: amBestMatchColor
		},
		{
			type: opportunitiesResults.amPC,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'amPC',
			color: amPCColor
		},
		{
			type: opportunitiesResults.amWR,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'amWR',
			color: amWRColor
		},
		{
			type: opportunitiesResults.casting,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'casting',
			color: castingColor
		},
		{
			type: opportunitiesResults.lathe,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'lathe',
			color: latheColor
		},
		{
			type: opportunitiesResults.latheSplit,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'latheSplit',
			color: latheSplitColor
		},
		{
			type: opportunitiesResults.noOpportunities,
			count: 0,
			percent: 0,
			dollar: 0,
			name: 'noOpportunities',
			color: noOpportunitiesColor
		}
	]
}

export const countToPieChartLabel = (percent: number, isCostChart: boolean) =>
	percent <= 0.01 ? ' ' : `${percent}`

export const countToPieChartLongLabel = (
	count: number,
	percent: number,
	name: string,
	isCostChart: boolean,
	opportunitiesData?: any
) =>
	count === 0
		? ' '
		: isCostChart
		? `${opportunitiesData[name]} ${(count > 1
				? PARTS
				: PART
		  ).toLowerCase()}\n(${percent}%)`
		: `${count} ${(count > 1 ? PARTS : PART).toLowerCase()}\n(${percent}%)`

export const calculatePercent = (count: number, total: number) =>
	((100 * count) / total).toFixed(1)

export const calculateRestPercent = (
	counts: number[],
	total: number,
	isCostChart: boolean
) => {
	let othersPercent = 0
	counts.forEach(
		count =>
			(othersPercent += isCostChart
				? count
				: parseFloat(((100 * count) / total).toFixed(1)))
	)
	const restPercentage = (100 - othersPercent).toFixed(1)

	return +restPercentage < 0 ? 0 : restPercentage
}

export const setChartDataCounter = (chartData: any, type: string) => {
	const dataIndex = chartData.findIndex((data: any) => data.type === type)
	if (dataIndex !== -1) {
		chartData[dataIndex].count = chartData[dataIndex].count + 1
	}
}

export const setChartDataCount = (
	chartData: any,
	type: string,
	count: number
) => {
	const dataIndex = chartData.findIndex((data: any) => data.type === type)
	if (dataIndex !== -1) {
		chartData[dataIndex].count = count
		chartData[dataIndex].dollar = count
	}
}
