import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import { isNumber } from 'lodash'

import {
	getComplexityScore,
	getCostVolumeRatioUnit,
	tightToleranceOptions
} from '../ChallengeFilter/ChallengeFilterAlertService'
import PartCardActionIcons from '../PartsThumbnailView/PartCardActionIcons'
import {
	onPartClick,
	onRemovePartClick,
	onRemovePartConfirm
} from '../ProjectAnalysisSuggestionActions'
import {
	renderConsolidationDeletionAlert,
	stopEvent
} from '../ProjectAnalysisSuggestionPartHelpers'
import { TabsItem } from '../ProjectAnalysisSuggestionService'
import PartAdditionalData from './PartAdditionalData'
import {
	convertMMtoCM,
	convertPriceToVolume,
	getPartStatusAndIcon
} from './PartsListViewService'
import { onPartPropertiesClick } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { opportunitiesNames } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisService'
import {
	InapplicableStatus,
	manufacturingMethodStrings
} from 'Services/Constants'
import {
	CLUSTER_ANALYSIS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { setStringItemToLocalStorage } from 'Services/LocalStorageService'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { ManufacturingMethod } from 'Services/models/IConfiguration'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { FormatType } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem, VolumeUnit } from 'Services/UnitsConversionService'

type IProps = {
	suggestion: Record<any, any>
	leadingConfigurationData: IBestMatchData[]
	isBundle: boolean
	toPrint?: boolean
	isQuickCADUpload?: boolean
	isGenerativeDesignOn?: boolean
	idx?: number
}

const PartRow: FC<IProps> = ({
	suggestion,
	isBundle,
	toPrint = false,
	isQuickCADUpload,
	isGenerativeDesignOn,
	idx
}) => {
	const { paginationData: { page = 0 } = {}, selectedTab } = useSelector(
		(state: RootStateOrAny) =>
			isBundle
				? state.ProjectBundleSuggestionReducer
				: state.ProjectSuggestionReducer
	)

	const isChallenges = selectedTab === TabsItem.challenges

	const [showAlert, setShowAlert] = useState(false)
	const dispatch = useDispatch()
	const user = useSelector((state: RootStateOrAny) => state.user)
	const history = useHistory()

	const {
		name,
		part,
		cluster,
		costSavingAmount,
		costSavingPercentage,
		material,
		resultType,
		partReductionsCount = 0,
		mainManufactureMethod
	} = suggestion

	let configurationName =
		getString(
			opportunitiesNames[resultType as keyof typeof opportunitiesNames]
		) || name
	const rowPart = part || cluster
	const partName = rowPart?.partNumber
	const partVolume = rowPart?.volume
	const weightReducedVolume = rowPart?.weightReducedVolume
	const weightReductionDensity = rowPart?.weightReductionDensity
	const partNumber = rowPart?.partNumber
	const thumbnailURL = rowPart?.thumbnailURL
	const imageURL = rowPart?.imageURL
	const formatType = rowPart?.formatType

	let src = thumbnailURL || imageURL

	if (rowPart?.latheAnalysisData) {
		if (resultType === ConfigurationResultTypes.LatheSplit) {
			src = rowPart.latheAnalysisData.combinedImageURL
		}
	}

	if (
		resultType === ConfigurationResultTypes.PartConsolidation &&
		isNumber(idx)
	) {
		let index = `#${idx + 1}`
		if (page > 1) {
			index = `#${page}${idx + 1}`
		}
		configurationName = `${name} ${index}`
	}

	const showRemovePart = Feature.isFeatureActive(FeatureComponentId.DELETE_PART)

	const allowRemoveAssemblyPart = Feature.isFeatureActive(
		FeatureComponentId.DELETE_ASSEMBLY_PART
	)
	const showRemove = suggestion?.parentAssemblyId
		? allowRemoveAssemblyPart
		: showRemovePart

	const onStarPartClicked = () => dispatch(starPartClicked(rowPart))

	const onShowModalOrRemove = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			stopEvent(e)
			if (rowPart.isUsedByCluster) {
				setShowAlert(true)
			} else {
				dispatch(onRemovePartClick(rowPart.id, partNumber))
			}
		},
		[dispatch, rowPart.id, rowPart.isUsedByCluster, partNumber]
	)

	const onConfirmRemove = useCallback(() => {
		setShowAlert(false)
		dispatch(onRemovePartConfirm(rowPart.id, rowPart.externalId, isBundle))
	}, [dispatch, rowPart.externalId, rowPart.id, isBundle])

	const review3dInProjectAnalysis = Feature.isFeatureOn(
		FeatureComponentId.REVIEW_3D_IN_PROJECT_ANALYSIS
	)

	let {
		iconName,
		resultLabel,
		partIsFailed,
		isPartNotCostEffective,
		isPartUpdating,
		isPartUnprintable
	} = getPartStatusAndIcon(rowPart?.status, rowPart?.result, false)

	const isNotCostSaving = useMemo(() => {
		return (
			costSavingAmount <= 0 &&
			!isPartNotCostEffective &&
			!isPartUpdating &&
			!isPartUnprintable &&
			!!resultType
		)
	}, [
		costSavingAmount,
		isPartNotCostEffective,
		isPartUpdating,
		isPartUnprintable,
		resultType
	])

	if (isNotCostSaving) {
		resultLabel = getString('NO_COST_SAVING')
		iconName = CADAnalysisResult.notCostEffective
	}

	const complexityScore = getComplexityScore(
		part?.partComplexityScore,
		user.defaultComplexityScore
	)

	const onPartRowClick = () => {
		const failedAndNonAm = selectedTab === TabsItem.opportunities

		if (partIsFailed && !isPartNotCostEffective && !failedAndNonAm) return

		if (
			rowPart?.isDrawing &&
			rowPart?.drawingStatusCode === InapplicableStatus
		) {
			dispatch(onPartPropertiesClick(rowPart.projectId))
		}

		const id = rowPart?.id
		const configId = suggestion?.id

		configId && setStringItemToLocalStorage(id, configId?.toString())

		if (isPartNotCostEffective || !rowPart.clusterId) {
			dispatch(onPartClick(rowPart, false, user, undefined, failedAndNonAm))
			return
		}

		history.push(
			`${USER_HOME_ROUTE}${CLUSTER_ANALYSIS_ROUTE}/${rowPart.projectId}/${rowPart.id}`
		)
	}

	const manufacturingMethodString: ImanufacturingTypes =
		mainManufactureMethod === ManufacturingMethod.threeD
			? ImanufacturingTypes.AM
			: mainManufactureMethod

	const costVolumeRatioUnit = getCostVolumeRatioUnit(user.userUnitSystem)

	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)

	const partVolumeUnit = convertPriceToVolume(
		part?.actualResult / part?.volume,
		customizeUnitSystem,
		user.userUnitSystem
	)

	// convert to cm3 if needed
	const partCostVolumeCube =
		user.userUnitSystem === UnitSystem.metric
			? convertMMtoCM(partVolumeUnit)
			: partVolumeUnit

	if (isChallenges)
		return (
			<>
				<div
					className={cx('suggestion-parts-list--part-row', {
						'in-quick-cad-upload': isQuickCADUpload,
						'is-generative-parts': isGenerativeDesignOn
					})}
					data-qa="data-qa-part-row-view"
					onClick={onPartRowClick}
				>
					<div
						className={cx('suggestion-parts-list--part-row--cell-img', {
							drawing: formatType === FormatType.pdf
						})}
					>
						<PartImageWithFallback
							data-qa={`data-qa-list-view-part-img-${
								partNumber || configurationName
							}`}
							fallbackClassName="cell-fallback-img"
							className="cell-img"
							src={src}
							alt={partNumber || configurationName}
							formatType={formatType}
						/>
					</div>
					<Flexbox
						flexDirection="column"
						alignItems="flex-start"
						data-qa={`data-qa-part-filed-${partName}-${configurationName}`}
						className="suggestion-parts-list--part-row--cell-text pure-text"
						title={partName + ' ' + configurationName}
					>
						{!!partName && (
							<span
								title={partName}
								className="suggestion-parts-list--part-name text-ellipsis"
							>
								{partName}
							</span>
						)}
						{!!rowPart.clusterId && (
							<span className="suggestion-parts-list--part-name">
								{!rowPart.disregardMaterial
									? getString('SAME_MATERIAL')
									: getString('ANY_MATERIAL')}
							</span>
						)}
					</Flexbox>
					<div
						className="suggestion-parts-list--part-row--cell-text"
						data-qa="part-row-part-dollar"
						title={part?.actualResult}
					>
						{part?.actualResult?.toFixed(2)}
					</div>
					<div
						className="suggestion-parts-list--part-row--cell-text"
						data-qa="part-row-part-percentage"
					>
						{partCostVolumeCube?.toFixed(4)} {costVolumeRatioUnit}
					</div>
					<div
						className="suggestion-parts-list--part-row--cell-text"
						title={part?.partComplexityScore}
						data-qa="part-row-original-material"
					>
						{complexityScore}
					</div>
					<div
						className="suggestion-parts-list--part-row--cell-text with-info"
						data-qa="part-row-printer-technology"
					>
						{part?.partBuyToFlyScore?.toFixed(1)}
					</div>
					<div
						className="suggestion-parts-list--part-row--cell-text with-info"
						data-qa="part-row-printer-additional-data"
					>
						{tightToleranceOptions.find(
							x => x.value === part?.customToleranceValue
						)?.label || '-'}
					</div>
				</div>
			</>
		)

	return (
		<>
			<div
				className={cx('suggestion-parts-list--part-row', {
					'in-quick-cad-upload': isQuickCADUpload,
					'is-generative-parts': isGenerativeDesignOn
				})}
				data-qa="data-qa-part-row-view"
				onClick={onPartRowClick}
			>
				<div
					className={cx('suggestion-parts-list--part-row--cell-img', {
						drawing: formatType === FormatType.pdf
					})}
				>
					<PartImageWithFallback
						data-qa={`data-qa-list-view-part-img-${
							partNumber || configurationName
						}`}
						fallbackClassName="cell-fallback-img"
						className="cell-img"
						src={src}
						alt={partNumber || configurationName}
						formatType={formatType}
					/>
				</div>
				<Flexbox
					flexDirection="column"
					alignItems="flex-start"
					data-qa={`data-qa-part-filed-${partName}-${configurationName}`}
					className="suggestion-parts-list--part-row--cell-text pure-text"
					title={partName + ' ' + configurationName}
				>
					{configurationName !== partName ? (
						<span title={configurationName} className="text-ellipsis">
							{configurationName}
						</span>
					) : (
						<></>
					)}

					{!!partName && (
						<span
							title={partName}
							className="suggestion-parts-list--part-name text-ellipsis"
						>
							{partName}
						</span>
					)}
					{!!rowPart.clusterId && (
						<span className="suggestion-parts-list--part-name">
							{!rowPart.disregardMaterial
								? getString('SAME_MATERIAL')
								: getString('ANY_MATERIAL')}
						</span>
					)}
				</Flexbox>
				<div
					className="suggestion-parts-list--part-row--cell-text"
					data-qa="part-row-part-dollar"
				>
					{costSavingAmount > 0 ? (
						<>
							<IconFactory
								iconName={'dollar'}
								className="project-results--label--title--icon"
							/>
							<div>${costSavingAmount?.toFixed(1)}</div>
						</>
					) : (
						<>
							{!isPartUpdating && (
								<IconFactory
									iconName={iconName}
									className={cx(
										'card--result-label-icon project-results--label--title--icon',
										{
											'grid-icon': isNotCostSaving
										}
									)}
								/>
							)}
							<div
								title={
									isPartUpdating ||
									(!review3dInProjectAnalysis && !isPartUnprintable)
										? resultLabel
										: null
								}
								data-qa="data-qa-part-row-printability-status"
							>
								{resultLabel}
							</div>
						</>
					)}
				</div>
				<div
					className="suggestion-parts-list--part-row--cell-text"
					data-qa="part-row-part-percentage"
				>
					{costSavingPercentage > 0 && (
						<>
							<IconFactory
								iconName={'percentage'}
								className="project-results--label--title--icon"
							/>
							{costSavingPercentage?.toFixed(1)}%
						</>
					)}
				</div>
				<div
					className="suggestion-parts-list--part-row--cell-text"
					title={material?.name}
					data-qa="part-row-original-material"
				>
					<div title={material?.name} className="text-ellipsis">
						{material?.name || ''}
					</div>
				</div>
				<div
					className="suggestion-parts-list--part-row--cell-text with-info"
					data-qa="part-row-printer-technology"
				>
					{getString(manufacturingMethodStrings[manufacturingMethodString]) ||
						'-'}
				</div>
				<div
					className="suggestion-parts-list--part-row--cell-text with-info"
					data-qa="part-row-printer-additional-data"
				>
					{!isPartUpdating ? (
						<PartAdditionalData
							isPartRow={true}
							resultType={resultType}
							partReductionsCount={partReductionsCount}
							partVolume={partVolume}
							weightReducedVolume={weightReducedVolume}
							weightReductionDensity={weightReductionDensity}
							unitSystem={user.userUnitSystem}
						/>
					) : (
						<>-</>
					)}
				</div>

				<div className="suggestion-parts-list--part-row--cell-text star-delete">
					{!cluster ? (
						<PartCardActionIcons
							starred={rowPart.starred}
							onStarClicked={onStarPartClicked}
							onRemoveClicked={onShowModalOrRemove}
							showRemove={showRemove && !cluster}
						/>
					) : (
						<></>
					)}
				</div>

				{showAlert &&
					renderConsolidationDeletionAlert({
						show: showAlert,
						onCancel: () => setShowAlert(false),
						onConfirm: onConfirmRemove
					})}
			</div>
		</>
	)
}

export default memo(PartRow)
