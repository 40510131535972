import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'

import AppBar from '@material-ui/core/AppBar'
import { useTheme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import cx from 'classnames'
import { isNil } from 'lodash'

import {
	hideInhouseAlert,
	hideInhouseAlertWithMaterial,
	onToggleHideConfigurations
} from '../MainPartAnalysis/MainPartAnalysisActions'
import { ConfigurationResultTypes } from '../MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import ConfigurationInHouseAlert from './common/ConfigurationInHouseAlert'
import {
	ConfigurationDropDown,
	ConfigurationNewButtons
} from './common/ConfigurationNewButtons'
import PrintingStandardsAlert from './common/PrintingStandardsAlert'
import {
	ConfigurationCompareButton,
	ConfigurationCompareListAlert
} from './ConfigurationAddNew/ConfigurationCompare'
import ConfigurationNewSolutionAlert from './ConfigurationAddNew/ConfigurationNewAlert'
import ConfigurationFailedProcessing from './ConfigurationContent/ConfigurationFailedProcessing'
import ConfigurationTab from './ConfigurationContent/ConfigurationTab'
import ConfigurationHeader from './ConfigurationHeader'
import {
	addNewConfigurationId,
	addPrinterConfigurationId,
	defaultTabOpen
} from './PartAnalysisConstants'
import {
	useDeleteConfiguration,
	useGetAllDataEffect,
	useTabChange
} from './PartAnalysisHooks'
import { useAllData } from './PartAnalysisSelector'
import {
	filterConfigurations,
	getAmOriginalMaterialType,
	getConfiguration,
	opportunitiesNames
} from './PartAnalysisService'
import { History, Location } from 'history'
import AdBlock from 'Scenes/Components/AdBlock'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { PART_ROUTE } from 'Services/Constants/RoutesConstants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { PartStatus } from 'Services/models/IPart'
import { LocationPartsParams } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { customizeUser } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

interface PartAnalysisTabProps {
	location: Location
	match: any
	history: History
}

const scrollToTop = () => {
	scroller.scrollTo('scrollTop', {
		duration: 300,
		delay: 100,
		smooth: true,
		containerId: `main-content-scroller`
	})
}

const PartAnalysisTab: FC<PartAnalysisTabProps> = ({ match, history }) => {
	const { partId } = match.params
	const theme = useTheme()
	const dispatch = useDispatch()
	const allAnalysisData: any = useAllData()
	const [
		showRemoveAlert,
		setShowRemoveAlert,
		removeConfiguration,
		setRemoveConfiguration
	]: any = useDeleteConfiguration(false)
	const [showDropDown, setShowDropDown] = useState(false)
	const [showCompare, setShowCompare] = useState(false)

	let {
		part,
		cluster,
		solutions,
		newSolutionAdded,
		newPrinterMaterialConfigurationAdded,
		configurations,
		showInhouseAlert,
		showHiddenConfigs,
		isInitialLoadingAnyConfiguration,
		project
	} = allAnalysisData
	const [showNewConfigurationAlert, setShowNewConfigurationAlert] = useState(
		!!newSolutionAdded
	)

	const isCastorTwo = allAnalysisData.project?.isCastorTwo

	const [solutionId, setSolutionId] = useState(addNewConfigurationId)

	const weightReductionConfigurationExist = configurations?.find(
		(s: any) => s.resultType === ConfigurationResultTypes.WeightReduction
	)
	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = weightReductionConfigurationExist
		? location?.state?.isWeightReduction
		: false
	const prevConfigurations = usePrevious(configurations)

	const filteredConfigurations = useMemo(() => {
		const shouldOrder = prevConfigurations?.length !== configurations?.length

		return filterConfigurations(
			configurations,
			isWeightReductionConfiguration,
			isCastorTwo,
			showHiddenConfigs,
			part?.isDrawing,
			shouldOrder
		)
	}, [
		partId,
		configurations,
		isWeightReductionConfiguration,
		isCastorTwo,
		showHiddenConfigs
	])

	const visibleConfigurations = useMemo(
		() =>
			configurations?.filter(
				(configuration: Record<string, any>) =>
					!configuration.hide &&
					configuration?.id !== addNewConfigurationId &&
					configuration?.id !== addPrinterConfigurationId &&
					configuration.resultType !==
						ConfigurationResultTypes.PartConsolidation &&
					configuration.resultType !== ConfigurationResultTypes.Challenges
			),
		[configurations]
	)

	const { value, handleChange } = useTabChange(
		filteredConfigurations,
		partId,
		isCastorTwo
	)

	const newConfiguration = useMemo(() => {
		return getConfiguration(configurations, solutionId)
	}, [configurations, solutionId])

	const hiddenConfigsLength = useMemo(() => {
		return configurations.filter((conf: IConfiguration) => conf.hide).length
	}, [configurations])

	const { isAmOriginalMaterial, subCategoryData } = getAmOriginalMaterialType(
		configurations,
		project
	)

	const [configurationLength, setConfigurationLength] = useState(
		filteredConfigurations?.length
	)

	const isAwaitingStatus =
		configurationLength === 0 &&
		part?.status === PartStatus.awaitingCombinedHeatmap

	const showSolutionMap = Feature.isFeatureOn(
		FeatureComponentId.ALTERNATIVE_SOLUTIONS_MAP
	)
	const showParameterSection = Feature.isFeatureOn(
		FeatureComponentId.CONFIGURATION_PARAMETER_SECTION
	)

	const tabHasInfo = showSolutionMap || showParameterSection

	const configurationTabContent = useMemo(() => {
		if (!configurationLength) return []
		return filteredConfigurations?.map((configuration: any, idx: number) => {
			const partSolution = solutions?.find(
				(s: ISolution) => s.id === configuration?.solution?.id
			)
			return (
				<ErrorBoundary
					extra="ConfigurationTab"
					hidden={value !== idx}
					key={configuration?.id}
				>
					<ConfigurationTab
						isCastorTwo={isCastorTwo}
						activeTabChange={handleChange}
						removeConfiguration={removeConfiguration}
						setShowRemoveAlert={setShowRemoveAlert}
						showRemoveAlert={showRemoveAlert}
						configuration={configuration}
						key={configuration?.id}
						value={value}
						index={idx}
						partSolution={partSolution}
						dir={theme.direction}
					/>
				</ErrorBoundary>
			)
		})
	}, [
		value,
		filteredConfigurations,
		showRemoveAlert,
		theme.direction,
		solutions,
		configurationLength
	])

	const configurationTabHeader = useMemo(() => {
		return filteredConfigurations?.map((configuration: any, idx: number) => {
			const isChallenges =
				configuration.resultType === ConfigurationResultTypes.Challenges
				
			return (
				<ConfigurationHeader
					isCastorTwo={isCastorTwo}
					hide={configuration.hide}
					isChallenges={isChallenges}
					disabled={
						configuration.skippedInitialAnalysis ||
						configuration.loadingInitialAnalysis
					}
					rootClass={isChallenges ? 'tab-root challenges' : 'tab-root'}
					showRemove={
						filteredConfigurations?.length > 1 &&
						!isInitialLoadingAnyConfiguration &&
						!isChallenges
					}
					setShowRemoveAlert={setShowRemoveAlert}
					setRemoveConfiguration={setRemoveConfiguration}
					selected={!!value}
					key={configuration?.id}
					id={configuration?.id}
					handleChange={handleChange}
					label={
						getString(
							opportunitiesNames[
								configuration.resultType as keyof typeof opportunitiesNames
							]
						) || configuration.name
					}
					index={idx}
					qaDataElementName={configuration.name}
					isLeading={configuration.isLeading}
					isWeightReductionConfiguration={isWeightReductionConfiguration}
				/>
			)
		})
	}, [filteredConfigurations])

	// show New config or New Printer material config
	useEffect(() => {
		setShowNewConfigurationAlert(
			!!newSolutionAdded || !!newPrinterMaterialConfigurationAdded
		)
		setSolutionId(
			newSolutionAdded ? addNewConfigurationId : addPrinterConfigurationId
		)
	}, [newSolutionAdded, newPrinterMaterialConfigurationAdded])

	useEffect(() => {
		// when config was deleted show leading tab
		if (
			isNil(value) ||
			value < 0 ||
			(configurationLength && value >= configurationLength)
		) {
			handleChange(null, defaultTabOpen, true)
			scrollToTop()
		}
		// when new config was added show last tab
		if (
			configurationLength > 0 &&
			configurationLength < filteredConfigurations?.length
		) {
			handleChange(null, filteredConfigurations?.length - 1)
			scrollToTop()
		}
	}, [value, filteredConfigurations, configurationLength])

	useEffect(() => {
		setConfigurationLength(filteredConfigurations?.length)
	}, [filteredConfigurations])

	useEffect(() => {
		if (showInhouseAlert)
			dispatch(hideInhouseAlertWithMaterial(newConfiguration?.id, true))
	}, [showInhouseAlert])

	useEffect(() => {
		return () => {
			// hide other suggestions on page leave
			if (!history.location.pathname.includes(PART_ROUTE)) {
				dispatch(onToggleHideConfigurations(false))
			}
		}
	}, [dispatch])

	useGetAllDataEffect(match.params)

	if (!part && !cluster) {
		return <div />
	}

	const onInhouseAlertConfirm = () => {
		dispatch(hideInhouseAlert())
		history.push(customizeUser())
	}

	const withoutTabContent = configurationTabContent.length === 0

	return (
		<ErrorBoundary extra="PartAnalysisTab">
			<div>
				<div id="scrollTop" />
				<NavBarAndMaterialTSX title={allAnalysisData.fileTiltleName}>
					<div
						className={cx('part-analysis-tab', {
							'no-content': withoutTabContent
						})}
					>
						<AppBar
							className={cx('part-analysis-tab--bar', {
								challenges:
									filteredConfigurations[value]?.resultType ===
									ConfigurationResultTypes.Challenges
							})}
							position="static"
							color="default"
						>
							<Tabs
								id="part-analysis-tab--header"
								className="part-analysis-tab--header"
								value={value}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
								classes={{
									indicator: 'indicator',
									flexContainer: 'tab-flex-container',
									scrollable: 'tab-scrollable-container-part'
								}}
							>
								{configurationTabHeader}
								{!!configurationLength &&
								!showHiddenConfigs &&
								!!hiddenConfigsLength ? (
									<div
										id="add-new-configuration-tab"
										className="add-new-button others"
										onClick={() => {
											dispatch(onToggleHideConfigurations(true))
										}}
									>
										<MoneyOffIcon />
										<div>{getString('OTHERS')}</div>
									</div>
								) : (
									<></>
								)}
								{!isInitialLoadingAnyConfiguration &&
									!isWeightReductionConfiguration &&
									!isAwaitingStatus &&
									!isCastorTwo && (
										<ConfigurationNewButtons
											disable={
												!!cluster ||
												part?.result === CADAnalysisResult.notCostEffective
											}
											showDropDown={showDropDown}
											setShowDropDown={setShowDropDown}
										/>
									)}
								{visibleConfigurations.length > 1 &&
									!isInitialLoadingAnyConfiguration &&
									!isAwaitingStatus &&
									isCastorTwo && (
										<ConfigurationCompareButton
											setShowCompare={setShowCompare}
											showCompare={showCompare}
										/>
									)}
							</Tabs>
							<ConfigurationDropDown
								configurationCount={configurationLength}
								showDropDown={showDropDown}
								setShowDropDown={setShowDropDown}
							/>
						</AppBar>
						<div
							className={cx('part-analysis-tab--content', {
								'without-info': !tabHasInfo
							})}
						>
							{configurationTabContent}
							{withoutTabContent && (
								<ConfigurationFailedProcessing
									bundleId={project.bundleId}
									setShowHiddenConfigs={(value: boolean) => {
										dispatch(onToggleHideConfigurations(value))
									}}
									noOpportunities={!!hiddenConfigsLength}
								/>
							)}
						</div>
						<AdBlock fullWidth={true} />
					</div>
				</NavBarAndMaterialTSX>
				<ConfigurationNewSolutionAlert
					setShowNewConfigurationAlert={setShowNewConfigurationAlert}
					showNewConfigurationAlert={showNewConfigurationAlert}
					configuration={newConfiguration}
					isAmOriginalMaterial={isAmOriginalMaterial}
					subCategoryData={subCategoryData}
				/>
				<ConfigurationInHouseAlert
					showInhouseAlert={showInhouseAlert}
					onInhouseAlertConfirm={onInhouseAlertConfirm}
				/>
				<PrintingStandardsAlert part={part || cluster} />
				<ConfigurationCompareListAlert
					setShowCompare={setShowCompare}
					showCompare={showCompare}
					configurations={visibleConfigurations}
				/>
			</div>
		</ErrorBoundary>
	)
}

export default memo(PartAnalysisTab)
