import { useEffect, useState } from 'react'

import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { getString } from 'Services/Strings/StringService'

const renderValue = (value: number, isDefault: boolean) => {
	const defaultString = isDefault ? ` (${getString('DEFAULT')})` : ''
	const percentageValue = value
	return `$${toFixedOnlyIfNeeded(percentageValue)} ${defaultString}`
}

const renderSelectPlaceholder = (value: number, isDefault: boolean) => {
	const defaultString = isDefault ? ` (${getString('DEFAULT')})` : ''
	const textValue = value ? getString('YES') : getString('NO')
	return `${textValue} ${defaultString}`
}

export const useRenderSelectValues = (value: number, isDefault: boolean) => {
	const [selectValue, setSelectValue] = useState(value)
	const [placeholder, setPlaceholder] = useState(
		renderSelectPlaceholder(value, isDefault)
	)
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		setSelectValue(value)
		setPlaceholder(renderSelectPlaceholder(value, isDefault))
	}, [value])

	return { selectValue, placeholder, isError, setSelectValue, setIsError }
}
export const useRenderValues = (
	value: number,
	isDefault: boolean,
	isEdit: boolean
) => {
	const [numberValue, setNumberValue] = useState(value)
	const [placeholder, setPlaceholder] = useState(renderValue(value, isDefault))
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		setNumberValue(toFixedOnlyIfNeeded(value))
		setPlaceholder(renderValue(value, isDefault))
	}, [value, isDefault])

	useEffect(() => {
		if (isEdit) {
			setIsError(false)
		}
	}, [isEdit])

	return { numberValue, placeholder, isError, setNumberValue, setIsError }
}

export const onApplyAllToleranceData = (
	tolerancePricesArranged: any,
	updateAllToleranceData: Function,
	toleranceEditToggle: Function,
	isEditAllToleranceCost: boolean,
	organizationId?: number
) => {
	for (const tolerance of tolerancePricesArranged) {
		if (tolerance.edit === isEditAllToleranceCost) {
			toleranceEditToggle(!tolerance.edit, tolerance)
		}
	}
	updateAllToleranceData(tolerancePricesArranged, organizationId)
}

export const onEditToggleChange = (
	isEditOn: boolean,
	toleranceData: Record<string, any>,
	toleranceEditToggle: Function,
	toleranceApplyToggle: Function,
	cancelToleranceEditData: Function,
	isApply: boolean = false
) => {
	toleranceEditToggle(isEditOn, toleranceData)
	if (isEditOn === true) {
		toleranceApplyToggle(false, toleranceData)
	}
	if (!isApply && !isEditOn) {
		cancelToleranceEditData(toleranceData)
	}
}

export const onApplyToggle = (
	isEditOn: boolean,
	toleranceData: Record<string, any>,
	toleranceApplyToggle: Function,
	toleranceEditToggle: Function,
	onEditToggle: Function,
	cancelToleranceEditData: Function
) => {
	toleranceApplyToggle(true, toleranceData)
	onEditToggle(
		isEditOn,
		toleranceData,
		toleranceEditToggle,
		toleranceApplyToggle,
		cancelToleranceEditData,
		true
	)
}

export const onEditAllToggle = (
	toleranceEditToggle: Function,
	toleranceEditAllToggle: Function,
	tolerancePricesArranged: any,
	isEditAllToleranceCost: boolean,
	cancelToleranceEditData: Function
) => {
	for (const tolerance of tolerancePricesArranged) {
		if (isEditAllToleranceCost) {
			cancelToleranceEditData(tolerance)
		}
		if (tolerance.edit === isEditAllToleranceCost) {
			toleranceEditToggle(!tolerance.edit, tolerance)
		}
	}
	toleranceEditAllToggle(!isEditAllToleranceCost)
}
