import React, { memo, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import cx from 'classnames'

import { onTolerancesConfirm } from '../../SolutionAnalysisActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import ThreeDButton from 'Scenes/Components/ThreeDButton/ThreeDButton'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import SpecificTolerancesModal from 'Scenes/Home/PartProperties/SpecificTolerancesModal/SpecificTolerancesModal'
import { PART_IMAGES_TABS_INDEX, partResults } from 'Services/Constants'
import { Part } from 'Services/models/IPart'
import {
	IDefaultPartDrawingTolerance,
	ToleranceOption
} from 'Services/models/SpecificTolerance'
import { CHOOSE_ORIENTATION_BUTTON } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const { ORIENTATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
	show3dButton: Boolean
	showHeatMap: boolean
	solutionPartImageIndex: number
	show3dModalIframe: () => void
	showHeatMapButton: Boolean
	onShowHeatToggle: Function
	onChoseOrientationClick: Function
	configurationId: number
	tourStepIndex: number
	freezeConfiguration: boolean
	customConfiguration: boolean
	isCluster: boolean
	configurationResult?: string
	showOrientationButton?: boolean
	loadingOrientation?: boolean
	isCastorTwo: boolean
	combinePdfUrl?: string
	showPDFModal: boolean
	onClosePDFModal: () => void
	onOpenPDFModal: () => void
}

const render3dButton = (
	show3dButton: Boolean,
	show3dModalIframe: () => void,
	configurationId: number
) => {
	if (!show3dButton) {
		return <div />
	}
	return (
		<ThreeDButton
			configurationId={configurationId}
			show3dModalIframe={show3dModalIframe}
			buttonClassName="threeD-button part-image-container--structural show"
			iconClassName="threeD-button-lable"
		/>
	)
}
const renderOrientationButton = (
	onChoseOrientationClick: Function,
	solutionPartImageIndex: number,
	isCluster: boolean,
	configurationResult?: string,
	loadingOrientation?: boolean
) => {
	const showSolutionOrientation =
		!isCluster &&
		(solutionPartImageIndex === ORIENTATION_TAB_INDEX ||
			configurationResult === partResults.notPrintable)

	return (
		<ButtonWithLoader
			qaDataElementName="data-qa-choose-orientation-btn"
			color="primary"
			onClick={onChoseOrientationClick}
			className={cx('orientation-button part-image-container--orientation', {
				show: showSolutionOrientation
			})}
			loading={loadingOrientation}
			muiClasses={{
				label: '',
				root: ''
			}}
		>
			{CHOOSE_ORIENTATION_BUTTON}
		</ButtonWithLoader>
	)
}

const render2DButton = (
	isCastorTwo: boolean,
	showPDFModal: boolean,
	onClosePDFModal: () => void,
	onOpenPDFModal: () => void,
	onToleranceModalConfirm: (
		customToleranceValue: ToleranceClass,
		specificTolerances: Record<string, any>
	) => void,
	combinePdfUrl: string | undefined,
	part: Part,
	generalToleranceInfo: Record<string, any>,
	defaultPartDrawingTolerances: IDefaultPartDrawingTolerance[],
	loading: boolean
) => {
	if (!isCastorTwo || !combinePdfUrl) {
		return null
	}
	return (
		<>
			<Button
				data-qa="data-qa-part-analysis-2d-button"
				color="primary"
				onClick={onOpenPDFModal}
				className={cx('three-d--button pdf-button')}
				muiClasses={{
					label: '',
					root: ''
				}}
			>
				<PictureAsPdfOutlinedIcon className={cx('three-d--icon pdf-icon')} />
				{getString('PDF')}
			</Button>
			<SpecificTolerancesModal
				showModal={showPDFModal}
				specificTolerances={generalToleranceInfo.specificTolerances}
				onCloseModal={onClosePDFModal}
				onConfirm={onToleranceModalConfirm}
				part={part}
				defaultPartDrawingTolerances={defaultPartDrawingTolerances}
				generalToleranceValue={generalToleranceInfo.generalToleranceValue}
				generalToleranceOptions={generalToleranceInfo.generalToleranceOptions}
				confirmOptionalText={getString('PART_ANALYSIS_CONFIGURE_CALCULATE')}
				loading={loading}
			/>
		</>
	)
}

const renderLeftButton = (
	onChoseOrientationClick: Function,
	freezeConfiguration: boolean,
	solutionPartImageIndex: number,
	isCluster: boolean,
	configurationResult?: string,
	showOrientationButton?: boolean,
	loadingOrientation?: boolean
) => {
	if (freezeConfiguration || !showOrientationButton) {
		return <div />
	}
	return renderOrientationButton(
		onChoseOrientationClick,
		solutionPartImageIndex,
		isCluster,
		configurationResult,
		loadingOrientation
	)
}

const SolutionPartImageButton: React.FC<Props> = ({
	show3dButton,
	show3dModalIframe,
	onChoseOrientationClick,
	configurationId,
	freezeConfiguration,
	configurationResult,
	solutionPartImageIndex,
	isCluster,
	showOrientationButton = true,
	loadingOrientation,
	isCastorTwo,
	combinePdfUrl,
	showPDFModal,
	onClosePDFModal,
	onOpenPDFModal
}: Props) => {
	const { part, initialToleranceValue, defaultPartDrawingTolerances } =
		useSelector((state: RootStateOrAny) => state.MainPartAnalysisReducer)
	const { tolerancesLoading } = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId] || {}
	})
	const dispatch = useDispatch()

	const generalToleranceInfo = useMemo(() => {
		const generalToleranceOptions: ToleranceOption[] = Object.entries(
			ToleranceClass
		).map(obj => ({
			key: obj[0],
			value: obj[1]
		}))
		const generalToleranceValue =
			initialToleranceValue || ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
		const specificTolerances = part?.specificTolerances

		return {
			generalToleranceOptions,
			generalToleranceValue,
			specificTolerances
		}
	}, [initialToleranceValue, part])

	const onToleranceModalConfirm = useCallback(
		(
			customToleranceValue: ToleranceClass,
			specificTolerances: Record<string, any>
		) => {
			dispatch(
				onTolerancesConfirm(
					part?.id,
					configurationId,
					customToleranceValue,
					false,
					undefined,
					specificTolerances,
					onClosePDFModal
				)
			)
		},
		[configurationId, dispatch, onClosePDFModal, part?.id]
	)

	return (
		<Flexbox
			alignItems="center"
			justifyContent="space-between"
			width="100%"
			className={`part-image-container--bottom-buttons-wrapper part-image-container--structural show`}
		>
			{showOrientationButton &&
				renderLeftButton(
					onChoseOrientationClick,
					freezeConfiguration,
					solutionPartImageIndex,
					isCluster,
					configurationResult,
					showOrientationButton,
					loadingOrientation
				)}
			{render2DButton(
				isCastorTwo,
				showPDFModal,
				onClosePDFModal,
				onOpenPDFModal,
				onToleranceModalConfirm,
				combinePdfUrl,
				part,
				generalToleranceInfo,
				defaultPartDrawingTolerances,
				tolerancesLoading
			)}
			{render3dButton(show3dButton, show3dModalIframe, configurationId)}
		</Flexbox>
	)
}

export default memo(SolutionPartImageButton)
