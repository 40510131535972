import parseHTML from 'html-react-parser'
import { FC, memo, useMemo } from 'react'

import EditIcon from '@material-ui/icons/Edit'
import { isNumber } from 'lodash'

import {
	complexityScoreOptions,
	SelectedTypeEnum,
	tightToleranceOptions
} from './ChallengeFilter/ChallengeFilterAlertService'
import {
	convertMMtoCM,
	convertPriceToVolume
} from './PartsListView/PartsListViewService'
import {
	ClientChallengesType,
	ProjectSuggestionsItem,
	StringChallenges,
	StringPercentageChallenges
} from './ProjectAnalysisSuggestionService'
import { formatMoney } from 'Scenes/Components/LedWithTitle'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

import './ProjectAnalysisSuggestion.scss'

const ChallengeContent: FC<{
	suggestion: ProjectSuggestionsItem
	customizeUnitSystem?: boolean
	userUnitSystem: UnitSystem
	setShowSelectedSuggestion: (value: ProjectSuggestionsItem) => void
}> = ({
	suggestion,
	setShowSelectedSuggestion,
	customizeUnitSystem,
	userUnitSystem
}) => {
	const value = useMemo(() => {
		let val = suggestion.editField?.value

		switch (suggestion.challengesResultType) {
			case ClientChallengesType.tightTolerance:
				val = tightToleranceOptions.find(
					option => option.value === suggestion.editField?.value
				)?.label
				break
			case ClientChallengesType.hightComplexity:
				val = complexityScoreOptions.find(
					option => option.value === suggestion.editField?.value
				)?.label
				break
			case ClientChallengesType.hightCostToVolume:
				if (suggestion.editField?.type === SelectedTypeEnum.input) {
					const partCostVolumeCube = convertPriceToVolume(
						+val,
						customizeUnitSystem,
						userUnitSystem
					)

					val =
						userUnitSystem === UnitSystem.metric
							? convertMMtoCM(partCostVolumeCube)
							: partCostVolumeCube
				}
				break
			default:
				break
		}

		return val
	}, [suggestion.editField?.value, suggestion.challengesResultType])

	const explanationString = useMemo(() => {
		const selectedValue = `<span className="text">${value}</span>`

		if (suggestion.editField?.type === SelectedTypeEnum.percentage) {
			return getString('CHALLENGES_SHOW_TOP_PERCENTAGE_BY').format(
				selectedValue,
				getString(
					StringPercentageChallenges[
						suggestion.challengesResultType as ClientChallengesType
					]
				)
			)
		}

		return ` 
			${getString(
				StringChallenges[
					suggestion.challengesResultType as ClientChallengesType
				]
			)} ${selectedValue}`
	}, [suggestion.challengesResultType, suggestion.editField, value])

	return (
		<>
			<div>{parseHTML(explanationString)}</div>
			<div>
				<EditIcon
					className="edit-icon"
					onClick={e => {
						e.stopPropagation()
						e.preventDefault()
						setShowSelectedSuggestion(suggestion)
					}}
				/>
			</div>
		</>
	)
}

const FilterContent: FC<{
	suggestion: ProjectSuggestionsItem
	suggestionCount: number
	customizeUnitSystem?: boolean
	userUnitSystem: UnitSystem
	setShowSelectedSuggestion: (value: ProjectSuggestionsItem) => void
}> = ({
	suggestion,
	suggestionCount,
	setShowSelectedSuggestion,
	customizeUnitSystem,
	userUnitSystem
}) => {
	const isChallenges = useMemo(
		() => isNumber(suggestion.challengesResultType),
		[suggestion.challengesResultType]
	)

	const showExplanation =
		!!suggestion?.clientSuggestionType &&
		suggestion?.clientSuggestionType > 0 &&
		suggestionCount > 0

	if (showExplanation) {
		return (
			<>
				{getString('TOTAL_SAVING_VALUE')}:{' '}
				{formatMoney(suggestion?.totalPotentialSavings?.toFixed(1))}
			</>
		)
	}

	if (isChallenges) {
		return (
			<ChallengeContent
				customizeUnitSystem={customizeUnitSystem}
				userUnitSystem={userUnitSystem}
				suggestion={suggestion}
				setShowSelectedSuggestion={setShowSelectedSuggestion}
			/>
		)
	}

	return <></>
}

export default memo(FilterContent)
