import axios from 'axios'
import 'babel-polyfill'
import { isEmpty } from 'lodash'

import PackageJson from '../../package.json'
import { resetAllApplicationData } from '../global actions'
import {
	AUTHORIZATION_TOKEN_UPDATED,
	HANDLE_LOADER,
	LOGIN_STATE_CHANGED
} from '../global actions/types'
import { store } from '../index'
import { getTheme } from '../themes/getTheme'
import {
	NETWORK_ERROR_CODE,
	notRequirePermissionUrls,
	partsPageLimit,
	stopRedirectUrls
} from './Constants'
import {
	getJsonItemFromLocalStorage,
	removeItemFromLocalStorage,
	setJsonItemToLocalStorage
} from './LocalStorageService'
import { FormatType } from './models/IPart'
import { isRequestThrottled } from './networkThttler'
import { GENERIC_ERROR_MESSAGE } from './Strings'
import { ProviderGuid } from 'Scenes/IntegrationProvider/providerGuid'
import history from 'Services/history'

const { skipLoginPage } = getTheme()

export const environments = {
	LOCAL: 'local',
	STAGING: 'staging',
	DEMO: 'demo',
	TEST: 'test',
	PRODUCTION: 'production',
	EVONIK: 'evonik',
	XEROX_TEST: 'xerox-test',
	XEROX_STAGING: 'xerox-staging',
	XEROX_PRODUCTION: 'xerox-production',
	NEXA3D: 'nexa3d',
	NEXA3D_TEST: 'nexa3d-test',
	NEXA3D_STAGING: 'nexa3d-staging',
	SIEMENS: 'siemens',
	SIEMENS_TEST: 'siemens-test',
	SIEMENS_STAGING: 'siemens-staging',
	EOS_TEST: 'eos-test',
	EOS_STAGING: 'eos-staging',
	EOS_PRODUCTION: 'eos-production',
	LOCAL_ONPREM: 'local-onprem'
}

const environment = process.env.REACT_APP_ENVIRONMENT
const theme_environment = process.env.REACT_APP_THEME_NAME

const hourTimeout = hours => {
	return hours * 60 * 60 * 1000 // timeout calculation 60m = 1h, 60s = 1m, 1000ms = 1s
}

const network = axios.create({
	baseURL: getBaseURL(),
	withCredentials: true,
	headers: {
		'x-client-version': PackageJson.version,
		'x-client-name': getClientName(environment)
	}
})

export function getBaseURL() {
	return window._env_.API_URL
}

export function getClientURL() {
	const location = window.location
	return `${location.protocol}//${location.host}`
}

function getClientName() {
	if (theme_environment.includes('xerox')) {
		return 'xerox'
	}
	return 'castor'
}

network.interceptors.request.use(async config => {
	if (config?.url === '/logout') {
		delete axios.defaults.headers['authorization']
		delete axios.defaults.headers['x-client-id']
		delete config?.headers['authorization']
	} else {
		config.headers['x-client-id'] = process.env.REACT_APP_CLIENT_ID
	}
	const isNotRequirePermissionUrl = notRequirePermissionUrls.some(url => {
		const tokenRegExp = '([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})'
		const regexp = new RegExp(`^${url}(([/]|[?])(${tokenRegExp}|.*))?$`, 'i')
		return regexp.test(config?.url)
	})

	if (isNotRequirePermissionUrl) {
		return config
	}

	if (config.baseURL === getBaseURL() && !config?.headers?.authorization) {
		//get the token from auth reducer and save it in the headers:
		let authorizationToken = store.getState().auth?.authorizationToken
		if (authorizationToken) {
			config.headers.authorization = authorizationToken
			return config
		}

		// Go to refresh token if the session has expired.
		await refreshJWT()

		authorizationToken = store.getState().auth?.authorizationToken
		if (authorizationToken) {
			config.headers.authorization = authorizationToken
		}
	}
	return config
})

network.interceptors.response.use(
	response => {
		// Do something with response data
		return response
	},
	async error => {
		if (!error.response) {
			console.error('The Server is not responding')
			return
		}

		if (error.response.data.code === 419) {
			window.location.href = '/2fa'
		}

		if (
			error.response.data.internalCode === 0 &&
			error.response.status === 500
		) {
			return Promise.reject({
				status: error.response.status,
				statusText: error.response.statusText
			})
		}

		if (error.response.data.internalCode === 54875) {
			return Promise.reject(error.response.data)
		}

		if (error.response.data.code === 403) {
			console.log('User does not have permission for the route.')
			return Promise.reject(error.response.data)
		}

		// Returns the specific error message to the UI.
		const errorMessageCode = [401, 422, 400, 500]
		if (errorMessageCode.includes(error.response.status)) {
			return Promise.reject(error.response.data)
		}

		const originalRequest = error.config
		const originalRequestURL = originalRequest?.url
		const tokenRefreshRoute = originalRequestURL.endsWith('/refresh_token')
		const externalRoute = originalRequestURL.includes('/external')

		if (tokenRefreshRoute) {
			if (window.location.href.includes('/login')) {
				return Promise.reject(error)
			}

			if (!externalRoute) {
				console.log('externalRoute')
				// prevent page reload
				history.push('/login')
			}
		}
	}
)

/**
 * @description
 *
 *
 *@return {Promise<any>} access_token
 */
export const refreshJWT = async (token, provider) => {
	let refreshTokenData = getJsonItemFromLocalStorage('crt')

	// if token is provided, use it instead of local storage
	if (token) {
		try {
			const response = await refreshAccessToken(token, provider)
			refreshTokenData = response.data

			const { refreshToken, token_type, refreshTokenExpireIn } =
				refreshTokenData
			setJsonItemToLocalStorage('crt', {
				refreshToken,
				token_type,
				expireIn: refreshTokenExpireIn
			})
		} catch (error) {
			resetAllApplicationData()
			removeItemFromLocalStorage('crt')
			window.location.reload()
			return
		}
	}

	if (isEmpty(refreshTokenData)) {
		// we need to go to castorLight
		// if we skip the Login page
		if (skipLoginPage && !window.location.href.includes('/login')) {
			history.push('/home/castorLight')
			window.location.reload()
		} else {
			if (!stopRedirectUrls.some(url => window.location.href.includes(url))) {
				history.push('/login')
			}
		}
		return
	}

	// Check refresh token expiration day if exist.
	if (
		Date.now() >
		(refreshTokenData?.expireIn && new Date(refreshTokenData.expireIn * 1000))
	) {
		return
	}

	store.dispatch({
		type: HANDLE_LOADER,
		payload: 1
	})

	// Go to refresh token if not expired.
	let response

	try {
		response = await refreshAccessToken(token)
	} catch (error) {
		resetAllApplicationData()
		removeItemFromLocalStorage('crt')
		window.location.reload()
		return
	}

	if (!response) {
		// if refreshAccessToken returns 500 and no response
		// refresh all data and go to page
		removeItemFromLocalStorage('crt')
		resetAllApplicationData()
		if (skipLoginPage) {
			history.push('/home/castorLight')
		} else {
			history.push('/login')
		}
		window.location.reload()
	}

	const { access_token, token_type, accessToken, tokenType, userId } =
		response?.data
	const tokenString = `${token_type || tokenType} ${
		access_token || accessToken
	}`

	store.dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})

	//set new access token to axios request headers
	axios.defaults.headers['authorization'] = tokenString

	//if token refreshing succeed change loggedIn state in redux
	store.dispatch({
		type: LOGIN_STATE_CHANGED,
		payload: true
	})
	store.dispatch({
		type: AUTHORIZATION_TOKEN_UPDATED,
		payload: {
			token: access_token || accessToken,
			token_type: token_type || tokenType
		}
	})
	return { token: tokenString, userId }
}

/**
 * Extracting the network call, if it's not being throttled
 * @param {string} uri Network URI
 * @param {Record<string, any>} parameters Network parameters
 * @param {Record<string, any>} other More network options
 * @param {requestMethodType} requestMethod  Request method name (get, post...)
 * @returns Nothing if the call is being throttled. Else, axios response.
 */
const extractNetworkCall = (uri, parameters, other, requestMethod) => {
	if (isRequestThrottled(other?.throttle, requestMethod)) {
		return
	}

	if (other?.timeout) {
		return network[requestMethod](uri, parameters, { timeout: other?.timeout })
	}

	return network[requestMethod](uri, parameters)
}

export const get = (uri, parameters, other) => {
	return extractNetworkCall(uri, parameters, other, 'get')
}

export const post = (uri, parameters, other) => {
	return extractNetworkCall(uri, parameters, other, 'post')
}

export const put = (uri, parameters, other) => {
	return extractNetworkCall(uri, parameters, other, 'put')
}

export const del = (uri, parameters, other) => {
	return extractNetworkCall(uri, parameters, other, 'delete')
}

const validateUserSettingsResponseData = response => {
	const settingsData = { ...response }
	if (!settingsData.data.userSettings) {
		settingsData.data.userSettings = {}
	}
	if (!settingsData.data.userSettings.offTheShelfSubstrings) {
		settingsData.data.userSettings.offTheShelfSubstrings = []
	}
	if (!settingsData.data.userSettings.manufacturedSubstrings) {
		settingsData.data.userSettings.manufacturedSubstrings = []
	}
	return settingsData
}

export const writeExcelFile = res => {
	const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
	let byteCharacters = atob(data.blob)
	let byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}
	let byteArray = new Uint8Array(byteNumbers)
	let blob = new Blob([byteArray], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	})
	return blob
}

// const all = (uploaders) =>{
//     Promise.all(uploaders)
// }
export const setup = () => {
	network.interceptors.response.use(
		response => {
			return response
		},
		error => {
			// Do something with response error
			if (error) {
				if (error.response && error.response.data) {
					return Promise.reject(error.response.data)
				}
				if (error.code) {
					return Promise.reject(error)
				}
				if (error.internalCode) {
					return Promise.reject(error)
				}
				return Promise.reject({
					code: NETWORK_ERROR_CODE,
					message: error.message
				})
			}
			return Promise.reject({
				code: NETWORK_ERROR_CODE,
				message: GENERIC_ERROR_MESSAGE
			})
		}
	)
}

export const login = (email, password) => {
	let params = { email, password }
	return post('/login', params)
}

export const loginWithProvider = provider => {
	return post(`/loginProvider`, { provider })
}

export const logoutWithProvider = email => {
	return post('/logoutProvider', { email })
}

export const redirectProvider = (clientInfo, code, provider) => {
	return post('/redirectProvider', { clientInfo, code, provider })
}

export const getProviders = () => {
	return get('/getAllProviders')
}

export const simpleLogin = email => {
	let params = { email }
	return post('/login/simple', params)
}

export const refreshAccessToken = (token, provider) => {
	const body = {}

	switch (provider) {
		case ProviderGuid.GrabCad:
			body.refreshToken =
				token ?? getJsonItemFromLocalStorage('crt')?.refreshToken
			break

		default:
			body.refreshToken = getJsonItemFromLocalStorage('crt')?.refreshToken
			break
	}

	return post('/refresh_token', body)
}

export const logout = email => {
	let params = { email }
	return post('/logout', params)
}

export const createHealthStatisticsLog = reactVersion => {
	return get(`/createHealthStatisticsLog?reactVersion=${reactVersion}`)
}

export const confirmUser = token => {
	let params = { token }
	return post('/confirmRegistration', params)
}

export const confirmOrganization = token => {
	let params = { token }
	return post('/confirmOrganization', params)
}

export const getUserProfileData = (
	userId,
	isCommunicationTool = false,
	organizationId
) => {
	return get(
		`/userProfileData/${userId}?isCommunicationTool=${isCommunicationTool}&organizationId=${organizationId}`
	)
}

export const isLoggedIn = lightUser => {
	return get(`/isLoggedIn?lightUser=${lightUser || false}`, {
		throttle: { time: 2000, name: 'isLoggedIn' }
	})
}

export const getLicense = () => {
	return get(`/getLicense`)
}

export const getFeatures = () => {
	return get(`/getFeatures`)
}

export const isLicenseNeeded = () => {
	return get(`/isLicenseNeeded`)
}

export const getUserRemainingPartsCreditNonAdminUsers = () => {
	return get(`/getUserRemainingPartsCredit`)
}

export const externalLogin = queryParams => {
	let params = { ...queryParams }
	return post('/external/login', params)
}

export const sendRestartPasswordEmail = email => {
	let params = { email }
	return post('/requestReset', params)
}
export const resetPassword = (newPassword, token) => {
	let params = { newPassword, token }
	return post('/resetPassword', params)
}
export const isAdminAuthenticated = () => {
	let params = {}
	return get('/adminAuthenticated', params)
}
export const getTwoFactorCode = () => {
	const params = {}
	return get('/twoFactorSetup', params)
}
export const informTwoFactorLoginIssue = time => {
	const params = { time }
	return post('/informAdminLoginIssue', params)
}
export const confirmUserCode = (code, isSetup) => {
	const params = { code, isSetup }
	return post('/twoFactorSetupConfirm', params)
}
export const register = params => {
	if (!params.company) {
		delete params.company
	}
	if (!params.organization) {
		delete params.organization
	}
	return post('/register', params)
}
export const sendVerifyEmail = (email, name) => {
	let params = { email, name }
	return post('/requestVerify', params)
}
export const sendReNewSubscriptionRequest = (
	subscriptionRequestType,
	userEmail
) => {
	let params = {
		subscriptionRequestType,
		userEmail: userEmail || null
	}

	return post('/reNewSubscriptionRequest', params)
}
export const createProject = (
	name,
	quantity,
	CADSoftware,
	ERP,
	skipFileUpload,
	files,
	formatType,
	bomFile,
	unitType,
	defaultMaterialId,
	subCategoryData,
	toleranceIncluded,
	application,
	filterValues,
	drawingMaterial,
	projectCadType,
	selectedProjectScenario = {},
	lightUser = {},
	projectStandardCost,
	manufactureMethod,
	amValue,
	isSpecifiedQuantity,
	additionalNotes,
	takePDFMaterial = formatType === FormatType.pdf,
	geometryAnalysisPart,
	organizationId
) => {
	const formData = new FormData()
	formData.append('name', name?.trim())
	formData.append('projectCADType', projectCadType)
	formData.append('quantity', quantity)
	formData.append('CADSoftware', CADSoftware)
	formData.append('ERP', ERP)
	formData.append('files', JSON.stringify(files))
	formData.append('unitType', unitType)
	formData.append('drawingMaterial', drawingMaterial)
	formData.append('toleranceIncluded', toleranceIncluded)
	formData.append('application', application || 'Other')
	formData.append('filters', JSON.stringify(filterValues))
	formData.append('formatType', formatType)
	formData.append('projectStandardCost', projectStandardCost)
	formData.append('manufactureMethod', manufactureMethod)
	formData.append('amValue', JSON.stringify(amValue))
	formData.append('isSpecifiedQuantity', isSpecifiedQuantity)
	formData.append('skipFileUpload', skipFileUpload)
	formData.append('lightUser', JSON.stringify(lightUser))
	formData.append('geometryAnalysisPart', JSON.stringify(geometryAnalysisPart))
	formData.append('takePDFMaterial', JSON.stringify(takePDFMaterial))
	formData.append('organizationId', organizationId)
	formData.append(
		'selectedProjectScenario',
		JSON.stringify(selectedProjectScenario)
	)
	if (defaultMaterialId) {
		formData.append('defaultMaterial', defaultMaterialId)
	}
	if (subCategoryData) {
		formData.append('subCategoryData', JSON.stringify(subCategoryData))
	}
	if (bomFile) {
		formData.append('bomFileName', bomFile.name)
	}
	if (additionalNotes) {
		formData.append('additionalNotes', additionalNotes)
	}

	return post('/createProject', formData)
}
export const informAdminUploadFilesFailed = projectId => {
	const params = { projectId }
	return post('/informAdminUploadFilesFailed', params)
}

const uploadFile = (url, file, progressCallback) => {
	const config = {
		onUploadProgress: progressEvent => {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			)
			if (progressCallback) {
				progressCallback(file, true, percentCompleted)
			}
		},
		headers: {
			'Content-Type': 'binary/octet-stream'
		}
	}
	delete axios.defaults.headers.authorization
	// delete axios.defaults.headers.common['authorization']
	return axios
		.put(url, file, config)
		.then(function (response) {
			if (response.status !== 200) {
				throw Error(response.statusText)
			}
			return response
		})
		.catch(function (error) {
			throw error
		})
}

export const getProjectStatus = projectId => {
	return get(`projectStatus?projectId=${projectId}`)
}

export const getProjectsInfo = projectId => {
	return get(`/projects/info/${projectId}`)
}

export const getProjectAnalysisStatus = (
	id,
	bundleId,
	silentUploadingNotification
) => {
	const projectId = bundleId || id
	return get(
		`projectAnalysisResults/getStatusUploadParts?projectId=${projectId}&bundleId=${bundleId}&silentUploadingNotification=${silentUploadingNotification}`
	)
}
export const getPartsReanalysisEntriesByUser = ownerId => {
	if (!ownerId) {
		return
	}
	return get(`/maintenance/parts/checkReanalysisByUser?userId=${ownerId}`)
}

export const getProjectsBundleAnalysis = bundleId =>
	get(`/projectsAnalysisResults?bundleId=${bundleId}&projectId=${bundleId}`)

export const updateTour = payload => post(`/user/updateTour`, payload)

/** uploads all the files to the server
 *
 * @param files array of files for upload
 * @param uploadURLs object of files Urls for upload
 * @param bomFile object
 * @param antivirusMessages array
 * @param antivirusMessagingEndpoint string
 * @param skipFileUpload boolean
 * @param defaultFileName string
 * @param progressCallback a callback that is called on any update in the
 *   progress. params: file - the file that status is updated, success - true
 *   if uploaded successfully and false otherwise
 */

const processUploadingFiles = async (
	file,
	url,
	antivirusMessagingEndpoint,
	antivirusMessages,
	progressCallback,
	skipFileUpload,
	defaultFileName,
	isBomFile
) => {
	if (isBomFile) {
		try {
			const res = await uploadFile(url, file, progressCallback)
			progressCallback(file, true)
			if (antivirusMessagingEndpoint) {
				await post(antivirusMessagingEndpoint, antivirusMessages.bomUploadURL)
			}
			return res
		} catch (error) {
			progressCallback(file, false)
			console.log(error)
			return null
		}
	}
	if (
		skipFileUpload &&
		file.name.toLowerCase() === defaultFileName.toLowerCase()
	) {
		progressCallback(file, true, 100)
		progressCallback(file, true, null)
		if (antivirusMessagingEndpoint) {
			const antivirusMessage = antivirusMessages[file.name]
			await post(antivirusMessagingEndpoint, antivirusMessage)
		}
		return null
	} else {
		try {
			const res = await uploadFile(url, file, progressCallback)
			if (!file?.size) {
				progressCallback(file, true, 100)
				progressCallback(file, true, null)
			} else {
				progressCallback(file, true)
			}
			if (antivirusMessagingEndpoint) {
				const antivirusMessage = antivirusMessages[file.name]
				await post(antivirusMessagingEndpoint, antivirusMessage)
			}
			return res
		} catch (error) {
			progressCallback(file, false)
			console.log(error)
			return null
		}
	}
}

export const uploadProjectFiles = async (
	uploadURLs,
	bomFile,
	antivirusMessages,
	antivirusMessagingEndpoint,
	progressCallback,
	skipFileUpload,
	defaultFileName,
	fileUrls,
	filesToUpload
) => {
	const maxUploadLength = 150

	const processingPromises = []

	for (let i = 0; i < filesToUpload.length; i += maxUploadLength) {
		const batch = filesToUpload.slice(i, i + maxUploadLength)
		for (const file of batch) {
			const url = fileUrls[file.name]

			processingPromises.push(
				processUploadingFiles(
					file,
					url,
					antivirusMessagingEndpoint,
					antivirusMessages,
					progressCallback,
					skipFileUpload,
					defaultFileName
				)
			)
		}
		if (bomFile && uploadURLs.bomUploadURL) {
			const url = uploadURLs.bomUploadURL

			processingPromises.unshift(
				processUploadingFiles(
					bomFile,
					url,
					antivirusMessagingEndpoint,
					antivirusMessages,
					progressCallback,
					skipFileUpload,
					defaultFileName,
					true
				)
			)
		}
		await Promise.all(processingPromises.splice(0, maxUploadLength))
	}

	await Promise.all(processingPromises)
}

export const explodeProject = project => {
	const params = { projectId: project.id }
	return post('/explodeProject', params)
}

export const setPartAnalysis = (
	projectId,
	partFileName,
	result,
	originalCost,
	moldCost,
	printer,
	material,
	serviceBureau,
	failureReason,
	suggestions,
	countInAssembly,
	file
) => {
	const formData = new FormData()
	formData.append('projectId', projectId)
	formData.append('partFileName', partFileName)
	formData.append('result', result)
	if (countInAssembly) {
		formData.append('countInAssembly', countInAssembly)
	}
	if (originalCost) {
		formData.append('originalCost', originalCost)
	}
	if (moldCost) {
		formData.append('moldCost', moldCost)
	}
	formData.append('printer', printer)
	formData.append('material', material)
	if (serviceBureau) {
		formData.append('serviceBureau', serviceBureau)
	}
	if (failureReason) {
		formData.append('failureReason', failureReason)
	}
	if (suggestions) {
		formData.append('suggestions', suggestions)
	}
	formData.append('file', file)

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	return post('/setPartAnalysis', formData, config)
}

export const getProjects = () => {
	let params = {}
	return get('/getProjects', params)
}

export const calculateProjects = projects => {
	return post('/maintenance/parts/reanalyse', projects)
}

export const stopRecalculatingByUser = () => {
	return post('/maintenance/parts/stopAllReanalyseProccessByUser')
}

export const checkRecalculateProcessByUser = () => {
	return get('/maintenance/parts/checkRecalculateProcessByUser')
}

export const stopRecalculating = () => {
	return post('/admin/stopAllReanalyseProccess')
}

export const checkRecalculateProcessAdmin = () => {
	return get('/admin/checkRecalculateProcessAdmin')
}

export const getProjectAnalysis = projectId =>
	get(`/projectAnalysisResults?projectId=${projectId}`)

export const updateProjectDrawingStatus = projectId => {
	return post(`/updateProject`, {
		id: projectId,
		showDrawingStatusWarn: false
	})
}

export const exportPartsToXLSX = (
	id,
	selectedFilterPart,
	bundleId = '',
	allOrganizations
) => {
	let params = {
		responseType: 'text',
		headers: { 'Content-Type': 'application/octet-stream' }
	}

	const projectId = bundleId ? '' : id

	return new Promise((resolve, reject) => {
		let url = '/exportPartsToExcel'

		params.selectedFilterPart = selectedFilterPart
		params.projectId = projectId
		params.allOrganizations = allOrganizations

		if (bundleId) {
			params.bundleId = bundleId
		}

		post(url, params)
			.then(res => {
				resolve(writeExcelFile(res))
			})
			.catch(e => {
				console.log(e)
				reject(e)
			})
	})
}

export const exportDataToXLSX = (
	urlParam,
	startDate,
	endDate,
	timezone,
	userId
) => {
	let params = {
		responseType: 'text',
		headers: { 'Content-Type': 'application/octet-stream' }
	}

	return new Promise((resolve, reject) => {
		let url = `/${urlParam}?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}&userId=${userId}`
		post(url, params, { timeout: hourTimeout(2) })
			.then(res => {
				if (!res?.data?.blob) {
					return resolve(null)
				}
				resolve(writeExcelFile(res))
			})
			.catch(e => {
				console.log(e)
				reject(e)
			})
	})
}

export const getPartAnalysis = partId => {
	// let params = {projectId}

	return get('/partAnalysisResults?partId=' + partId)
}

export const getMoldCosts = (chartDataLabels, solutionId) => {
	return get(
		`/moldCosts?quantities=${JSON.stringify(
			chartDataLabels
		)}&solutionId=${solutionId}`
	)
}

export const getCosts = (chartDataLabels, solutionId, changeQuantity) => {
	const quantityChanges = changeQuantity
		? `&changeQuantity=${changeQuantity}`
		: ''
	return get(
		`/costs?quantities=${JSON.stringify(
			chartDataLabels
		)}&solutionId=${solutionId}${quantityChanges}`
	)
}

export const updatePartAnalysis = (
	id,
	moldCost,
	moldPartCost,
	moldMaintenanceCost,
	DFMCosts,
	isOffTheShelf,
	needsHighAccuracy,
	needsSurfaceFinish,
	material,
	metrics,
	showInHouseSolutions,
	quantities,
	actualQuantity,
	solutionCost,
	castToolingCost,
	investmentCastToolingCost,
	sandCastToolingCost,
	puAnnualKgCO2,
	puYearsCO2,
	disposeFactor
) => {
	let params = {
		id,
		castToolingCost,
		investmentCastToolingCost,
		sandCastToolingCost,
		moldCost,
		moldPartCost,
		moldMaintenanceCost,
		DFMCosts,
		isOffTheShelf,
		needsHighAccuracy,
		needsSurfaceFinish,
		material,
		metrics,
		showInHouseSolutions,
		quantities,
		actualQuantity,
		solutionCost,
		puAnnualKgCO2,
		puYearsCO2,
		disposeFactor
	}

	return post('/updatePartAnalysis', params)
}

export const updateLeadTime = params => {
	return post('/updatePartAnalysis', params)
}

export const updateProject = (id, quantity) => {
	let params = { id, quantity }

	return post('/updateProject', params)
}

export const updateProjectPlacement = (
	id,
	orderDate,
	bundleId,
	userProjectFolderId
) => {
	let params = { id, orderDate, bundleId, userProjectFolderId }

	return post('/updateProject', params)
}

export const getProjectPrintableClusters = (projectId, bundleId = '') => {
	return get(
		`/getProjectsPrintableClusters?projectId=${projectId}&bundleId=${bundleId}`
	)
}

export const getClusterResults = clusterId => {
	return get('/clusterSolutions?clusterId=' + clusterId)
}

export const updateCluster = (id, material, metrics, showInHouseSolutions) => {
	let params = { id, material, metrics, showInHouseSolutions }

	return post('/updateCluster', params)
}

export const requestMultiplePartsIntoOne = (projectId, bundleId) => {
	let params = { projectId }

	if (bundleId) {
		params = { ...params, bundleId }
	}

	return post('/requestMultiplePartsIntoOne', params)
}

export const getProjectsAdmin = (
	searchPhrase,
	page,
	userId,
	limitProjects,
	filterType
) => {
	return get(
		`/getAllProjects?page=${page}&user=${userId}&limit=${limitProjects}&search=${searchPhrase}&filter=${filterType}`
	)
}

export const getProjectsParts = ({
	projectId = '',
	bundleId = '',
	searchPhrase = '',
	status = '',
	starred = '',
	benefits = '',
	standaloneParts = '',
	assembliesIds = '',
	page = 1,
	limit = partsPageLimit
}) => {
	return get(
		`/projectsParts?projectId=${projectId}&bundleId=${bundleId}&searchPhrase=${searchPhrase}&status=${status}&starred=${starred}&benefits=${benefits}&standaloneParts=${standaloneParts}&assembliesIds=${assembliesIds}&page=${page}&limit=${limit}`
	)
}

export const getProjectAdmin = projectId => {
	return get(`/getProject?projectId=${projectId}`)
}

export const sendPrinterNotExistMailToAdmins = (
	printer,
	userId,
	printerAmount,
	materials,
	otherMaterials,
	organizationId
) => {
	const params = {
		printer: { ...printer, amount: printerAmount || 1 },
		userId,
		materials,
		otherMaterials,
		organizationId
	}

	return post('/addPrinterRequest', params)
}

export const addPrinterToUser = (
	printer,
	userId,
	materials,
	organizationId
) => {
	const params = { printer, userId, materials, organizationId }
	return post(`userPrinter`, params)
}

export const removePrinterFromUser = (printer, userId, organizationId) => {
	return del(
		`userPrinter?printerId=${printer.id}&userId=${userId}&organizationId=${organizationId}`
	)
}

export const removePrintersFromUser = (userId, organizationId) => {
	return del(`userPrinters?userId=${userId}&organizationId=${organizationId}`)
}

export const getUsersAdmin = (searchPhrase, page, limit, sortBy, sortASC) => {
	return get(
		`/users?page=${page}&limit=${limit}&search=${searchPhrase}&sortBy=${sortBy}&sortASC=${sortASC}`
	)
}
export const getUserAdmin = (searchPhrase, limit) => {
	const withLimit = limit ? `&limit=${limit}` : ''
	return get(`/users?search=${searchPhrase}${withLimit}`)
}

export const getPrintersAdmin = (searchPhrase, page, limit) => {
	return get(`/printers?page=${page}&limit=${limit}&search=${searchPhrase}`)
}

export const getAllPrintersAdmin = () => {
	return get(`/admin/printers`)
}

export const getAllPrintersMaterialsAdmin = organizationId => {
	return get(`/printers/printerMaterials?organizationId=${organizationId}`)
}

export const getPrinterAdmin = id => {
	return get(`/printer?id=${id}`)
}

export const deletePrinterAdmin = id => {
	return del(`/printer?id=${id}`)
}
export const updateUserInfoAdmin = info => {
	return post('/updateUserInfo', info)
}
export const updateUserOwnerPermission = (userId, isOwner) => {
	return post('/updateUserOwnerPermission', { userId, isOwner })
}
export const updateUserAdminPermission = (userId, isAdmin, isSiteAdmin) => {
	return post('/updateUserAdminPermission', { userId, isAdmin, isSiteAdmin })
}
export const updateUserOrganization = (
	userId,
	organizationId,
	prevOrganizationId
) => {
	return post('/updateUserOrganization', {
		userId,
		organizationId,
		prevOrganizationId
	})
}
export const updatePrinterAdmin = printer => {
	const params = { printer }
	return put(`/printer`, params)
}
export const removeUserFromSystem = (userId, withOrganization) => {
	return del(`/users/${userId}?withOrganization=${withOrganization}`)
}

export const createNewPrinterAdmin = printer => {
	const params = { printer }
	return post(`/printer`, params)
}

export const createNewPrinterAdminAndRemovePrinterName = (
	printer,
	removeId
) => {
	const params = { printer, removeId }
	return post(`/printerFromList`, params)
}

export const getMaterialsAdmin = (searchPhrase, page, limit) => {
	return get(`/materials?page=${page}&limit=${limit}&search=${searchPhrase}`)
}

export const addAccessLog = (action, success, user_agent = {}) => {
	const params = { action, success, user_agent }
	return post(`/addAccessLog`, params)
}

export const getMaterialAdmin = id => {
	return get(`/material?id=${id}`)
}

export const deleteMaterialAdmin = id => {
	return del(`/material?id=${id}`)
}

export const updateMaterialAdmin = material => {
	const params = { material }
	return put(`/material`, params)
}
export const updateParametersAdmin = data => {
	const params = { parametersType: data.type, parameters: data.values }
	return put(`/systemParameters`, params)
}
export const getParametersAdmin = () => {
	return get('/systemParameters')
}
export const createNewMaterialAdmin = material => {
	const params = { material }
	return post(`/material`, params)
}

export const getPrinterMaterialsAdmin = (searchPhrase, page, limit) => {
	return get(
		`/printerMaterials?page=${page}&limit=${limit}&search=${searchPhrase}`
	)
}

export const getPrinterMaterialAdmin = id => {
	return get(`/printerMaterial?id=${id}`)
}

export const deletePrinterMaterialAdmin = id => {
	return del(`/printerMaterial?id=${id}`)
}

export const updatePrinterMaterialAdmin = printerMaterial => {
	const params = { printerMaterial }
	return put(`/printerMaterial`, params)
}

export const createNewPrinterMaterialAdmin = ({
	printerMaterial,
	organizationSpecific,
	organizationId
}) => {
	const params = { printerMaterial, organizationSpecific, organizationId }
	if (organizationSpecific) {
		return post(`/printerMaterial`, params)
	}
	return post(`/printerMaterialAdmin`, params)
}

export const getPrinterNamesAdmin = (searchPhrase, page, limit) => {
	return get(`/printerNames?page=${page}&limit=${limit}&search=${searchPhrase}`)
}

export const getPrinterNameAdmin = id => {
	return get(`/printerName?id=${id}`)
}

export const deletePrinterNameAdmin = id => {
	return del(`/printerName?id=${id}`)
}

export const updatePrinterNameAdmin = printerName => {
	const params = { printerName }
	return put(`/printerName`, params)
}

export const createNewPrinterNameAdmin = printerName => {
	const params = { printerName }
	return post(`/printerName`, params)
}

export const getMaterialPrinterPairsAdmin = (searchPhrase, page, limit) => {
	return get(
		`/materialPrinterPairs?page=${page}&limit=${limit}&search=${searchPhrase}`
	)
}

export const getMaterialPrinterPairAdmin = id => {
	return get(`/materialPrinterPair?id=${id}`)
}

export const deleteMaterialPrinterPairAdmin = id => {
	return del(`/materialPrinterPair?id=${id}`)
}

export const updateMaterialPrinterPairAdmin = materialPrinterPair => {
	const params = { materialPrinterPair }
	return put(`/materialPrinterPair`, params)
}

export const createNewMaterialPrinterPairAdmin = materialPrinterPair => {
	const params = { materialPrinterPair }
	return post(`/materialPrinterPair`, params)
}

export const publishProjectAdmin = projectId => {
	let params = { projectId }

	return post('/publishProject', params)
}

export const machinePartsForProjectAdmin = projectId => {
	return get('/machinePartsForProjectAdmin?projectId=' + projectId)
}

export const getMorePartsForProjectAdmin = (projectId, currentPartsCount) => {
	return get(
		`/morePartsForProjectAdmin?projectId=${projectId}&currentPartsCount=${currentPartsCount}`
	)
}

export const getMoreClustersForProjectAdmin = (
	projectId,
	currentClustersCount
) => {
	return get(
		`/moreClustersForProjectAdmin?projectId=${projectId}&currentClustersCount=${currentClustersCount}`
	)
}

export const getProjectDownloadLink = projectId => {
	return get('/projectDownloadURL?projectId=' + projectId)
}
export const adminCombineParts = (projectId, parts) => {
	let params = { projectId, parts }

	return post('/adminCombineParts', params)
}
export const adminDeleteParts = (projectId, parts) => {
	let params = { projectId, parts }

	return post('/adminDeleteParts', params)
}

export const starPartUpdated = (projectId, partId, starred) => {
	let params = { projectId, partId, starred }
	return post('/starPart', params)
}

export const ratePartUpdated = (projectId, partId, rate) => {
	let params = { projectId, partId, rate }
	return post('/ratePart', params)
}

const addItemToObjectIfNotNull = (object, key, value) => {
	if (value !== null && key) {
		object[key] = value
	}
}
export const adminUpdateResult = (
	projectId,
	partId,
	isOffTheShelf,
	wallThicknessScore,
	wallThicknessFailReason,
	sizeScore,
	sizeFailReason,
	materialScore,
	materialFailReason,
	CADScore,
	CADFailReason,
	result,
	errorMessage,
	partComplexityScore
) => {
	if (!projectId) {
		return Promise.reject(new Error('missing projectId'))
	}
	if (!partId) {
		return Promise.reject(new Error('missing parts'))
	}
	let params = { projectId, partId }

	addItemToObjectIfNotNull(params, 'projectId', projectId)
	addItemToObjectIfNotNull(params, 'parts', partId)
	if (isOffTheShelf === true || isOffTheShelf === false) {
		params.isOffTheShelf = isOffTheShelf
	}
	addItemToObjectIfNotNull(params, 'wallThicknessScore', wallThicknessScore)
	addItemToObjectIfNotNull(
		params,
		'wallThicknessFailReason',
		wallThicknessFailReason
	)
	addItemToObjectIfNotNull(params, 'sizeScore', sizeScore)
	addItemToObjectIfNotNull(params, 'sizeFailReason', sizeFailReason)
	addItemToObjectIfNotNull(params, 'materialScore', materialScore)
	addItemToObjectIfNotNull(params, 'materialFailReason', materialFailReason)
	addItemToObjectIfNotNull(params, 'CADScore', CADScore)
	addItemToObjectIfNotNull(params, 'partComplexityScore', partComplexityScore)
	addItemToObjectIfNotNull(params, 'CADFailReason', CADFailReason)
	addItemToObjectIfNotNull(params, 'result', result)
	addItemToObjectIfNotNull(params, 'errorMessage', errorMessage)

	return post('/adminUpdateResult', params)
}

export const setRelativeOriginalMaterial = isRelativeToOriginalMaterial => {
	const params = { isRelativeToOriginalMaterial }
	return put('/adminSettingsRelativeToOriginalMaterial', params)
}

export const addCustomOrientation = (
	configurationId,
	partId,
	trayNormalVector
) => {
	const body = { configurationId, partId, trayNormalVector }
	return post('/orientation', body)
}

export const checkCustomOrientationCompleted = (
	partId,
	configurationId,
	solutionId,
	quantity,
	postProcessesOptional
) => {
	const params = {
		partId,
		configurationId,
		solutionId,
		quantity,
		postProcessesOptional
	}
	return post(`/orientation/status`, params)
}

export const sendToPrint = (
	projectId,
	partId,
	configurationId,
	address,
	city,
	country,
	state,
	zip,
	lat,
	lon,
	quantity,
	notes,
	drawingFile
) => {
	const formData = new FormData()
	formData.append('projectId', projectId)
	formData.append('partId', partId)
	formData.append('configurationId', configurationId)
	formData.append('quantity', quantity)
	formData.append('notes', notes)

	if (!lat && !lon && !address && !city && !country) {
		formData.append('skipAddress', 'true')
	} else {
		formData.append('lat', lat)
		formData.append('lon', lon)
		formData.append('address', address)
		formData.append('city', city)
		formData.append('country', country)
		formData.append('state', state)
		formData.append('zip', country)
	}

	if (drawingFile) {
		formData.append('file', drawingFile)
	}

	const config = drawingFile
		? {
				headers: {
					'content-type': 'multipart/form-data'
				}
		  }
		: null
	return post('/sendToPrint', formData, config)
}

export const getCustomizationSettings = organizationId => {
	return new Promise((resolve, reject) => {
		get(`/userCustomization?organizationId=${organizationId}`)
			.then(response => {
				resolve(validateUserSettingsResponseData(response))
			})
			.catch(error => {
				reject(error)
			})
	})
}

export const getCustomizationPrinters = () => {
	return get(`/customizationPrinters`)
}

export const toggleUserDesktopPrinters = (
	desktopPrinters,
	userId,
	organizationId
) => {
	const params = { desktopPrinters, userId, organizationId }
	return post('/toggleDesktopPrinters', params)
}

export const updateUnitsType = (selectedUnitsType, organizationId) => {
	const params = { selectedUnitsType, organizationId }
	return post('/updateUnitsType', params)
}

export const updateUnitSystem = (unitSystem, organizationId) => {
	const params = { unitSystem, organizationId }
	return put('/updateUnitSystem', params)
}

export const getAllPrintersCompanies = organizationId => {
	return get(
		`/user/customize/printers/companies?organizationId=${organizationId}`
	)
}

export const getAllPrinters = () => {
		return get(`/printers`)
	},
	getAllUserPrinters = (userId, organizationId) => {
		return get(`/userPrinters?id=${userId}&organizationId=${organizationId}`)
	},
	uploadUserSettings = (userSettings, organizationId) => {
		let params = { ...userSettings, organizationId }
		return post('/userCustomization', params)
	},
	getProjectScenario = organizationId => {
		return get(`/project/settingsScenario?organizationId=${organizationId}`)
	},
	getProjectPDFOptions = partId => {
		return get(`/projectExportPdfOptions?partId=${partId}`)
	},
	updateProjectPDFOptions = (projectPdfOptions, partId) => {
		return post(`/projectExportPdfOptions`, { projectPdfOptions, partId })
	},
	updateUserCustomizePostProcesses = (
		postProcess,
		printingTechnologies,
		organizationId
	) => {
		const params = { postProcess, printingTechnologies, organizationId }
		return post('/user/customize/post-processes', params)
	},
	deleteUserCustomizePostProcesses = (postProcessId, organizationId) => {
		return del(
			`/user/customize/post-processes?postProcessId=${postProcessId}&organizationId=${organizationId}`
		)
	},
	updateUserCustomizeFullTrayAssumption = value => {
		const params = { fullTrayAssumptionOn: value }
		return put('/user/customize/full-tray', params)
	},
	updateUserCustomizeSupplChainCosts = supplyChainOn => {
		const params = { includeSupplyChainCosts: supplyChainOn }
		return put('/user/customize/supplyChainCosts', params)
	},
	updateUserCustomizeFilters = (userFilters, organizationId) => {
		const params = { userFilters, organizationId }
		return put('/user/customize/filters', params)
	},
	getCustomizationMaterialPriceList = organizationId => {
		return get(`/materialPriceList?organizationId=${organizationId}`)
	},
	updateCustomizationMaterialPriceList = (
		materialIds,
		price,
		organizationId
	) => {
		const params = { materialIds, price, organizationId }
		return put('/materialPrice', params)
	},
	deleteCustomizationMaterialPriceList = (materialIds, organizationId) => {
		return del(
			`/materialPrice?materialIds=${materialIds}&organizationId=${organizationId}`
		)
	},
	createCustomizationPrinterSetting = params => {
		return post('/user/printerSettings', params)
	},
	updateCustomizationPrinterSetting = (params, organizationId) => {
		return put('/user/printerSettings', { ...params, organizationId })
	},
	deleteCustomizationPrinterSettings = printerIds => {
		return del(`/user/printerSettings?printerIds=${printerIds}`)
	},
	getCustomizationPrinterSettingsList = organizationId => {
		return get(`/user/printerSettings?organizationId=${organizationId}`)
	},
	getTolerancesPrices = organizationId => {
		return get(`/user/toleranceSettings?organizationId=${organizationId}`)
	},
	updateUserCustomizeDefaultScenario = (
		updateScenario,
		fullTrayAssumptionOn,
		organizationId
	) => {
		const params = { defaultScenario: updateScenario, organizationId }

		if (typeof fullTrayAssumptionOn === 'boolean') {
			params.fullTrayAssumptionOn = fullTrayAssumptionOn
		}

		return put('/user/customize/scenario', params)
	},
	uploadProjectScenario = preparedDataToSend => {
		let params = { projectScenarioUpdate: preparedDataToSend }
		return put(`/project/settingsScenario`, params)
	},
	getProjectScenarioParameters = () => {
		return get(`/project/settingsScenarioParameters`)
	},
	updateUserProfileNetwork = updatedProfile => {
		let params = { ...updatedProfile }
		return post('/userProfileData/userDetails', params)
	},
	getAllUserMaterials = userId => {
		return get(`/userMaterials?id=${userId}`)
	},
	getAllUserPrinterFromUser = userId => {
		return get(`/userPrinterFromUserMaterials?id=${userId}`)
	},
	getAllUserPrinterMaterialsFromUser = userId => {
		return get(`/userPrinterMaterialFromUserMaterials?id=${userId}`)
	},
	addMaterialToUser = (material, userId, organizationId, isEdit) => {
		const params = { material, userId, organizationId, isEdit }
		return post(`userMaterial`, params)
	},
	addPrinterToUserBySetting = (printer, organizationId, isEdit) => {
		const params = { printer, organizationId, isEdit }
		return post(`addPrinterToUser`, params)
	},
	removeMaterialFromUser = (material, userId, organizationId) => {
		return del(
			`userMaterial?materialId=${material.id}&userId=${userId}&organizationId=${organizationId}`
		)
	},
	removeUserPrinterMaterialsFromUser = (
		printerMaterial,
		userId,
		organizationId
	) => {
		return del(
			`userPrinterMaterialFromUserMaterials?printerMaterialId=${printerMaterial.id}&userId=${userId}&organizationId=${organizationId}`
		)
	},
	removeUserPrinterFromUser = (printer, userId, organizationId) => {
		return del(
			`userPrinterFromUserMaterials?printerId=${printer.id}&userId=${userId}&organizationId=${organizationId}`
		)
	},
	getMaterialCategories = () => {
		return get(`/materialCategories`)
	},
	getPrinterMaterialCategories = organizationId => {
		return get(`/printerMaterialCategories?organizationId=${organizationId}`)
	},
	updateWallThickness = (wallThickness, configurationId, updateOrientation) => {
		const params = { configurationId, wallThickness, updateOrientation }
		return post('/changeWallThickness', params)
	},
	getWallThicknessStatus = partId => {
		return get(`/isPartComplete?partId=${partId}`)
	},
	updatePartTolerance = (
		partId,
		toleranceValue,
		removeInnerPartTolerance,
		specificTolerances
	) => {
		const params = {
			toleranceValue,
			partId,
			removeInnerPartTolerance
		}
		if (specificTolerances) {
			params.specificTolerances = specificTolerances
		}
		return put('/part/tolerance', params)
	},
	getUserMaterialNamesMapping = organizationId => {
		return get(
			`/user/customize/materialNamesMapping?organizationId=${organizationId}`
		)
	},
	createUserMaterialNameMapping = (userMaterialNameMapping, organizationId) => {
		const params = { userMaterialNameMapping, organizationId }
		return post('/user/customize/materialNameMapping', params)
	},
	updateUserMaterialNamesMapping = (
		userMaterialNamesMapping,
		organizationId
	) => {
		const params = { userMaterialNamesMapping, organizationId }
		return put('/user/customize/materialNameMapping', params)
	},
	removeUserMaterialNameMapping = (id, active, organizationId) => {
		const idParam = id ? `?id=${id}` : ''
		const activeParam = typeof active === 'boolean' ? `?active=${active}` : ''
		const organizationParam = organizationId
			? `&organizationId=${organizationId}`
			: ''
		const route =
			'/user/customize/materialNameMapping' +
			idParam +
			activeParam +
			organizationParam
		return del(route)
	}

export const getPrintingOrientation = (
	partId,
	configurationId,
	partSolutionId,
	batchSize,
	updatedPostProcesses
) => {
	const params = {
		partId,
		configurationId,
		solutionId: partSolutionId,
		quantity: batchSize,
		postProcessesOptional: updatedPostProcesses
	}
	return post('/getPrintingOrientationData', params)
}
export const switchAlternativeSolution = (
	configurationId,
	alternativeSolutionId,
	partId
) => {
	const params = { configurationId, alternativeSolutionId, partId }
	return post('/switchToAlternativeSolution', params)
}
export const updateOutSourcedCNCManufacturing = (
	configurationId,
	outsourcedCNCManufacturing
) => {
	const params = { configurationId, outsourcedCNCManufacturing }
	return post('/changeOutSourcedCNCManufacturing', params)
}

export const getTechParametersAdmin = name => {
	return get(`/printers-tech-parameters?id=${name}`)
}

export const getHealthStatusAdmin = name => {
	return get(`/healthStatus`)
}

export const getPrinterTechParametersAdmin = () => {
	return get('/printer-tech-parameters')
}

export const updatePrinterTechParametersAdmin = printerTechnologyParameters => {
	const params = { printerTechnologyParameters }
	return put(`/printer-tech-parameters`, params)
}

export const uploadScript = file => {
	return post(`/db_query`, file, { timeout: hourTimeout(12) })
}

export const getDBQueryStatus = () => {
	return get(`/db_query/status`)
}

export const uploadLicenseScript = file => {
	return post(`/db_query_license`, file, { timeout: hourTimeout(12) })
}

export const getLicenseInformation = organizationId => {
	const params = { organizationId }
	return get('/admin/licenseInformation', params)
}

export const createZip = (file, formValues) => {
	const body = { file, formValues }
	return post(`/createDbUpdateZipFile`, body, { timeout: hourTimeout(12) })
}

export const uploadMaterialsFile = (file, fileName, organizationId) => {
	const preparedToSend = {
		data: file?.split(',').pop(),
		fileName: fileName
	}

	return post(
		`/user/customize/uploadMaterials`,
		{ file: preparedToSend, organizationId },
		{
			timeout: hourTimeout(2),
			headers: { 'Content-Type': 'multipart/form-data' }
		}
	)
}

export const uploadMultipleUserMaterialsFile = (
	file,
	fileName,
	organizationId
) => {
	const preparedToSend = {
		data: file?.split(',').pop(),
		fileName: fileName
	}

	return post(
		`/user/customize/uploadUserMaterials`,
		{ file: preparedToSend, organizationId },
		{
			timeout: hourTimeout(2),
			headers: { 'Content-Type': 'multipart/form-data' }
		}
	)
}

export const updateSingletons = () => {
	return put(`/admin/singletons`)
}

export const getMailSettings = () => {
	return get(`/admin/emailSettings`)
}

export const updateMailSettings = emailSettings => {
	return put(`/admin/emailSettings`, { emailSettings })
}

export const verifyMailSettings = () => {
	return get(`/admin/emailSettings/verify`)
}

export const updateCO2Data = (params, reset, co2Key, organizationId) => {
	const co2Params = {
		CO2Params: {
			...params
		},
		co2Key: co2Key,
		reset: reset,
		organizationId
	}
	return put('/user/customize/co2', co2Params)
}

export const updateDrawingCosts = (params, organizationId) => {
	const drawingParams = {
		DrawingParams: {
			...params
		},
		organizationId
	}
	return put('/user/customize/drawing', drawingParams)
}

export const updateTolerancePrice = toleranceData => {
	const params = {
		toleranceData
	}
	return put('/user/customize/tolerancePrice', params)
}

export const updateMetadataCosts = (params, organizationId) => {
	const drawingParams = {
		MetadataParams: {
			...params
		},
		organizationId
	}
	return put('/user/customize/metadata', drawingParams)
}

export const getAllFeatures = () => {
	return get('/admin/features')
}
export const updateAllFeatures = featuresList => {
	const featureParams = {
		FeatureParams: {
			...featuresList
		}
	}
	return put(`/admin/features`, featureParams)
}
export const getConfigurationFeatures = () => {
	return get(`/admin/featureConfigurations`)
}
export const updateConfigurationFeatures = params => {
	const featureParams = {
		configurationParams: {
			...params
		}
	}
	return put(`/admin/featureConfigurations`, featureParams)
}
export const createNewConfigurationFeatures = params => {
	const featureParams = {
		configurationParams: {
			...params
		}
	}
	return post(`/admin/featureConfigurations`, featureParams)
}
export const getMaterialCo2Calculation = organizationId => {
	return get(
		`/user/customize/co2Calculation/material?organizationId=${organizationId}`
	)
}
export const getCycleCo2Calculation = () => {
	return get(`/user/customize/co2Calculation/cycle`)
}
export const getTypicalKwhList = organizationId => {
	return get(
		`/user/customize/co2Calculation/typicalKwh?organizationId=${organizationId}`
	)
}
export const createOrUpdateMaterialCo2Calculation = (
	parameters,
	organizationId
) => {
	const params = { materialParams: parameters, organizationId }
	return post(`/user/customize/co2Calculation/material`, params)
}
export const createNewPrinterMaterialCo2Calculation = (
	parameters,
	organizationId
) => {
	const params = { materialParams: parameters, organizationId }
	return post(`/user/customize/co2Calculation/printerMaterial`, params)
}
export const createNewPrinterTypicalKwh = (parameters, organizationId) => {
	const params = { PrinterParams: parameters, organizationId }
	return post(`/user/customize/co2Calculation/printer/typicalKwh`, params)
}
export const updateTmTypicalKwh = (parameters, organizationId) => {
	const params = { cycle: parameters, organizationId }
	return put(`/user/customize/co2Calculation/Tm/typicalKwh`, params)
}
export const getPrinterMaterialCo2Calculation = organizationId => {
	return get(
		`/user/customize/co2Calculation/printerMaterial?organizationId=${organizationId}`
	)
}
export const getPrinterTypicalKwh = organizationId => {
	return get(
		`/user/customize/co2Calculation/printer/typicalKwh?organizationId=${organizationId}`
	)
}

export const deleteMaterialCo2Calculation = id => {
	return del(`/user/customize/co2Calculation/material?id=${id}`)
}
export const deletePrinterMaterialCo2Calculation = id => {
	return del(`/user/customize/co2Calculation/printerMaterial?id=${id}`)
}
export const deletePrinterTypicalKwh = id => {
	return del(`/user/customize/co2Calculation/printer/typicalKwh?id=${id}`)
}
export const deleteCycleTypicalKwh = (id, organizationId) => {
	return del(
		`/user/customize/co2Calculation/Tm/typicalKwh?id=${id}&organizationId=${organizationId}`
	)
}
export const updateUserCo2PerKW = (co2PerKW, reset = false, organizationId) => {
	const params = { co2PerKW, reset, organizationId }
	return put('/user/customize/co2Calculation/Co2PerKW', params)
}
export const updateUserCo2HoldFactor = (value, organizationId) => {
	const params = { holdFactor: value, organizationId }
	return put('/user/customize/co2Calculation/holdFactor', params)
}
export const updateUserCarbonCreditCost = (value, organizationId) => {
	const params = { carbonCreditCost: value, organizationId }
	return put('/user/customize/co2Calculation/carbonCreditCost', params)
}
export const updateUserProductUse = (value, parameter, organizationId) => {
	const params = { [parameter]: value, organizationId }
	return put('/user/customize/co2Calculation/productUse', params)
}
export const updateUserEndOfLifeDisposeFactor = (value, organizationId) => {
	const params = { disposeFactor: value, organizationId }
	return put('/user/customize/co2Calculation/EndOfLife/disposeFactor', params)
}
export const getCustomFunctionStrings = organizationId => {
	return get(
		`/user/customize/functionString/customFunctions?organizationId=${organizationId}`
	)
}
export const deleteCustomFunctionString = (id, organizationId) => {
	return del(
		`/user/customize/functionString/customFunctions?id=${id}&organizationId=${organizationId}`
	)
}
export const getFunctionStringParams = (
	category,
	technologies,
	organizationId,
	unitSystem
) => {
	return get(
		`/user/customize/functionString/params?category=${category}&technologies=${technologies}&organizationId=${organizationId}&unitSystem=${unitSystem}`
	)
}
export const addFunctionStringParam = (name, value, organizationId) => {
	const params = { name, value, organizationId }
	return post('/user/customize/functionString/params', params)
}
export const deleteFunctionStringParam = (id, organizationId) => {
	return del(
		`/user/customize/functionString/params?id=${id}&organizationId=${organizationId}`
	)
}
export const addFunctionString = (
	functionString,
	category,
	technologies,
	organizationId
) => {
	const params = { functionString, category, technologies, organizationId }
	return post('/user/customize/functionString/customFunctions', params)
}

export const getCostAnalysisParameters = organizationId => {
	return get(
		`/user/customize/costAnalysisParameters?organizationId=${organizationId}`
	)
}

export const updateCostAnalysisParameters = (
	costAnalysisParams,
	organizationId
) => {
	return put(`/user/customize/costAnalysisParameters`, {
		costAnalysisParams,
		organizationId
	})
}

export const updateUserOnboardingState = (
	onboardingCompleted,
	wizardStep,
	organizationId
) => {
	const params = { onboardingCompleted, wizardStep, organizationId }
	return put('/user/onboarding', params)
}

export const createUserProjectFolder = name => {
	const params = { name }
	return post('/projectFolder', params)
}

export const updateProjectName = (
	projectId,
	newProjectName,
	oldProjectName
) => {
	const params = { newProjectName, oldProjectName }
	return put(`/updateProjectName/${projectId}`, params)
}

export const updateFolderName = (folderId, newFolderName) => {
	const params = { newFolderName }
	return put(`/updateFolderName/${folderId}`, params)
}

export const updateUserProjectFolder = (id, orderDate) => {
	const params = { id, orderDate }
	return put('/projectFolder', params)
}

export const deleteUserProjectFolder = id => {
	return del(`/projectFolder?id=${id}`)
}

export const updateClusterAssemblingParams = (
	configurationId,
	assemblingParams
) => {
	const params = { configurationId, assemblingParams }
	return put('/updateCluster/assemblingParams', params)
}

export const getAdminServerStopEnums = () => {
	return get(`/admin/stopServerStepsEnums`)
}
export const putStopServerStep = stopStep => {
	return put(`/admin/stopServerStep?stopStep=${stopStep}`)
}
export const putPartNameToFailAfterSaving = partNameToFailAfterSavingData => {
	return put(
		`/admin/partNameToFailAfterSavingData?partNameToFailAfterSavingData=${partNameToFailAfterSavingData}`
	)
}
export const putPartNameToFail = partNameToFail => {
	return put(`/admin/partNameToFail?partNameToFail=${partNameToFail}`)
}
export const postStopServer = () => {
	return post(`/admin/stopServer`)
}
export const getAvailableOrganizationsForUser = () => {
	return get(`/publicOrganizations`)
}
export const updateOrganizationInfo = (organizationId, name, isPrivate) => {
	const params = { organizationId, name, isPrivate }
	return put('/organization', params)
}
export const addNewOrganization = (
	name,
	isPrivate,
	duplicateOrganizationId
) => {
	const params = { name: name?.trim(), isPrivate }
	if (duplicateOrganizationId) {
		params.duplicateOrganizationId = duplicateOrganizationId
	}
	return post('/organization', params)
}
export const updateUserDevMode = devMode => {
	const params = { devMode }
	return put('/user/devMode', params)
}

export const getProjectPartsMappingByProject = projectId => {
	return get(`/projects/partsMapping/${projectId}`)
}

export const ignoreAllBlanksProjectPartMappingByProject = projectOrBundleId => {
	return put(`/projects/partsMapping/ignoreAll/${projectOrBundleId}`)
}

export const updateProjectPartMappingByProject = (
	projectOrBundleId,
	projectId,
	name3D,
	name2D
) => {
	const body = { projectOrBundleId, projectId, name3D, name2D }
	return put(`/projects/partsMapping`, body)
}

export const updateProjectPartsByMapping = projectId => {
	return put(`/projects/partsMapping/${projectId}`)
}

export default network
