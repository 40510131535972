import React, { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { isEmpty } from 'lodash'

import FailedPartsWarning from '../../ProjectAnalysis/ProjectAnalysisPage/ProjectsResult/FailedPartsWarning'
import QuickCADProjectResults from '../../ProjectAnalysis/QuickCADProjectResults'
import ProjectBundleMultipleParts from './ProjectBundleMultipleParts'
import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import ProjectExtraFeaturesSection from 'Scenes/Components/ProjectExtraFeaturesSection'
import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import {
	confirmConsolidationAnalysis,
	sendMultiplePartsIntoOneRequest
} from 'Scenes/Home/ProjectAnalysis/MultiplePartsIntoOneWidgits/RequestMultiplePartsIntoOneCardActions'
import {
	onPartsStandardConfirmed,
	onPartsStandardCostUpdated,
	onRemovePartCancel,
	onRemovePartConfirm,
	onStandardCostClick,
	onUpdateWeightReductionButtonClick,
	onWeightReductionMinimumThicknessThresholdChanged,
	onWeightReductionThresholdChanged,
	reducePartWeightClick,
	removeAlert,
	removeFailWarningFromProject,
	sendWeightReductionRequest
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import ProjectResultDistributionChart from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisChart/ProjectResultDistributionChart/ProjectResultDistributionChart'
import { styles } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectMultipleParts/style'
import ProjectAlerts from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectsResult/ProjectAlerts'
import { getProjectsBundle } from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle/ProjectBundleActions'
import {
	projectPageScrollParams,
	ProjectUrlParams
} from 'Scenes/Home/ProjectPage/ProjectPageConstants'
import ProjectFail from 'Scenes/Home/ProjectPending/ProjectFail'
import ProjectPending from 'Scenes/Home/ProjectPending/ProjectPending'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectClusterStatus, ProjectStatus } from 'Services/models/IProject'
import { PROJECT_ANALYSIS_SUB_TITLE, RESULTS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { gerUrlParams } from 'Services/Utils/urlTools'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

const NavBarAndMaterialTSX: any = NavBarAndMaterial
const ProjectResultDistributionChartTSX: any = ProjectResultDistributionChart

const ProjectBundleAnalysis: FC = () => {
	const { projectId } = useParams() as { projectId: string }
	const dispatch = useDispatch()
	const { scrollToBottom } = gerUrlParams([ProjectUrlParams.scrollToBottom])

	const {
		projects,
		project,
		isSinglePartProject,
		numberOfPrintableParts,
		numberOfAllBenefits,
		benefitsState,
		numberOfPrintablePart,
		showMultiplePartsIntoOneRequest,
		projectWeightReductionStatus,
		clusterRequested,
		clusterRequestText,
		projectClusterStatus,
		showMultiplePartsIntoOneResults,
		weightReductionRequested,
		weightReductionLoading,
		weightReductionRequestText,
		weightReductionItemsWithPictures,
		projectWeightReductionThreshold,
		projectWeightReductionMinimumThicknessThreshold,
		updateWeightReductionButtonLoading,
		clusterItemsWithPictures,
		materialCountInfo,
		bundleId,
		showStandardCostAlert,
		partsStandardCosts,
		updateStandardCostLoading,
		disableStandardCostSubmit,
		showStandardCostError,
		showStandardCostNumberError,
		showNameDuplicationError,
		showingSimpleAlertText,
		showingSimpleAlertTitle,
		showRemovePartAlert,
		requestedRemovePartName,
		loadingRemovePart,
		onlyPart,
		requestedRemovePartId,
		doRefreshConfigurations,
		userHasNoPermissions,
		partsStandardCostsBeforeChanges,
		printabilityData,
		totalPartsCount,
		parts,
		initialSetup,
		allPartsFailed,
		showFailedPartsWarning
	} = useSelector((state: RootStateOrAny) => state.ProjectBundleReducer)

	const quickCADUploadFeatureIsOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	useEffect(() => {
		scrollToBottom === 'true' &&
			scroller.scrollTo('scrollBottom', projectPageScrollParams)
	}, [])

	useEffect(() => {
		dispatch(getProjectsBundle(projectId))
	}, [projectId])

	useEffect(() => {
		if (doRefreshConfigurations) {
			dispatch(getProjectsBundle(projectId))
		}
	}, [doRefreshConfigurations])

	const projectAssembly = useMemo(
		() => projects.filter((project: any) => !!project.isAssembly),
		[projects]
	)

	if (
		userHasNoPermissions ||
		(project?.status === ProjectStatus.published && allPartsFailed)
	) {
		return (
			<ProjectFail
				project={project}
				projectFail={true}
				userHasNoPermissions={userHasNoPermissions}
				quickProjectConnectionError={false}
			/>
		)
	}

	if (!isEmpty(project) && project?.status !== ProjectStatus.published) {
		return (
			<ProjectPending
				project={project}
				projectBundleId={bundleId}
				projects={projects}
			/>
		)
	}

	if (quickCADUploadFeatureIsOn) {
		return <QuickCADProjectResults isBundle />
	}

	const isMetadata =
		!!project?.metadataParts || onlyPart?.formatType === METADATA
	const emptyParts = !parts.length && isEmpty(onlyPart)

	return (
		<NavBarAndMaterialTSX title={getString('NAV_TITLE_BUNDLE_PROJECTS')}>
			{initialSetup && emptyParts ? (
				<Loader
					wrapperClassName="project-analysis-block__loader"
					load={true}
					fullPage={true}
				/>
			) : (
				<></>
			)}
			<FailedPartsWarning
				show={showFailedPartsWarning}
				onRemoveWarningClick={() =>
					dispatch(removeFailWarningFromProject(bundleId))
				}
			/>
			<div className="project-bundle">
				<Flexbox
					flexDirection="row"
					style={{ margin: '0 15px', width: 'calc(100% - 30px)' }}
				>
					<HeaderCard
						id={`general-part-bundle-statistics`}
						cardTitle={RESULTS}
						headerColor={cardBoxColor || 'green'}
						cardSubtitle={PROJECT_ANALYSIS_SUB_TITLE}
						iconColor="red"
						content={
							<ProjectResultDistributionChartTSX
								style={styles.assemblyProjectResultChart}
								contentStyle={styles.chartWhenAssembly}
								isAssembly={!!projectAssembly?.length}
								projectsAssembly={projectAssembly}
								image={''}
								name={''}
								numberOfPrintablePart={numberOfPrintablePart}
								printabilityData={printabilityData}
								totalPartsCount={totalPartsCount}
								benefitsState={benefitsState}
								numberOfAllBenefits={numberOfAllBenefits}
								project={project}
								assemblyImage={styles.assemblyImage}
								is2dProject={false}
								isMetaDataProject={false}
								allPartsInapplicable={false}
								numberOfPrintableParts={numberOfPrintableParts}
							/>
						}
					/>
				</Flexbox>
				<div style={styles.combineCard}>
					<ProjectExtraFeaturesSection
						disabled={isMetadata || (!parts.length && isEmpty(onlyPart))}
						setupClustersOverview={() => {}}
						clusterItemsWithPictures={clusterItemsWithPictures}
						sendMultiplePartsIntoOneRequest={() =>
							dispatch(sendMultiplePartsIntoOneRequest(null, bundleId))
						}
						confirmConsolidationAnalysis={() =>
							dispatch(
								confirmConsolidationAnalysis(
									null,
									materialCountInfo?.totalMaterialCount,
									bundleId,
									projectClusterStatus === ProjectClusterStatus.awaitingRequest
								)
							)
						}
						materialCountInfo={materialCountInfo}
						reducePartWeightClick={(
							partId: any,
							stepURL: any,
							hasBrepData: any
						) => dispatch(reducePartWeightClick(partId, stepURL, hasBrepData))}
						isSinglePartProject={isSinglePartProject}
						showMultiplePartsIntoOneRequest={showMultiplePartsIntoOneRequest}
						project={project}
						projectWeightReductionStatus={projectWeightReductionStatus}
						clusterRequested={clusterRequested}
						clusterRequestText={clusterRequestText}
						projectClusterStatus={projectClusterStatus}
						showMultiplePartsIntoOneResults={showMultiplePartsIntoOneResults}
						weightReductionRequested={weightReductionRequested}
						weightReductionRequestText={weightReductionRequestText}
						weightReductionItemsWithPictures={weightReductionItemsWithPictures}
						projectWeightReductionThreshold={projectWeightReductionThreshold}
						projectWeightReductionMinimumThicknessThreshold={
							projectWeightReductionMinimumThicknessThreshold
						}
						onWeightReductionThresholdChanged={(value: number) =>
							dispatch(onWeightReductionThresholdChanged(value))
						}
						onWeightReductionMinimumThicknessThresholdChanged={(value: any) =>
							dispatch(onWeightReductionMinimumThicknessThresholdChanged(value))
						}
						weightReductionLoading={weightReductionLoading}
						sendWeightReductionRequest={() =>
							dispatch(
								sendWeightReductionRequest(
									bundleId,
									projectWeightReductionThreshold,
									projectWeightReductionMinimumThicknessThreshold
								)
							)
						}
						onUpdateWeightReductionButtonClick={() =>
							dispatch(
								onUpdateWeightReductionButtonClick(
									bundleId,
									projectWeightReductionThreshold
								)
							)
						}
						updateWeightReductionButtonLoading={
							updateWeightReductionButtonLoading
						}
					/>
				</div>
				<br />
				<div className="general-part-statistics__block">
					<HeaderCard
						contentRootClassName="general-part-statistics"
						id={`general-parts-bundle-statistics`}
						cardTitle={getString('PARTS')}
						headerColor={cardBoxColor || 'green'}
						iconColor="red"
						content={<ProjectBundleMultipleParts />}
					/>
				</div>
			</div>
			<ProjectAlerts
				showingSimpleAlertText={showingSimpleAlertText}
				showingSimpleAlertTitle={showingSimpleAlertTitle}
				removeAlert={() => dispatch(removeAlert())}
				showRemovePartAlert={showRemovePartAlert}
				onRemovePartCancel={() => dispatch(onRemovePartCancel())}
				onRemovePartConfirm={(
					partId: number,
					externalId: string,
					isBundle: boolean
				) => dispatch(onRemovePartConfirm(partId, externalId, isBundle))}
				requestedRemovePartName={requestedRemovePartName}
				loadingRemovePart={loadingRemovePart}
				requestedRemovePartId={requestedRemovePartId}
				onlyPart={onlyPart}
				showStandardCostAlert={showStandardCostAlert}
				onStandardCostClick={() => dispatch(onStandardCostClick())}
				partsStandardCosts={partsStandardCosts}
				onPartsStandardCostUpdated={(data: any) =>
					dispatch(onPartsStandardCostUpdated(data))
				}
				projectId={projectId}
				updateStandardCostLoading={updateStandardCostLoading}
				disableStandardCostSubmit={disableStandardCostSubmit}
				onPartsStandardConfirmed={(data: any) =>
					dispatch(
						onPartsStandardConfirmed(
							data,
							bundleId,
							partsStandardCostsBeforeChanges
						)
					)
				}
				showStandardCostError={showStandardCostError}
				showStandardCostNumberError={showStandardCostNumberError}
				showNameDuplicationError={showNameDuplicationError}
				isBundle
			/>
			<div id="scrollBottom" />
		</NavBarAndMaterialTSX>
	)
}

export default memo(ProjectBundleAnalysis)
