import React, { FC, memo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import FileDownload from '@material-ui/icons/GetApp'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import cx from 'classnames'

import { Button } from '../../../../../../../Components/thirdParty/CreativeTim/components'
import {
	defaultNumber,
	defaultRotate,
	maxRotate,
	pageStep,
	rotateStep,
	zoomStep
} from './constants'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { getString } from 'Services/Strings/StringService'

import './PdfViewer.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

interface IProps {
	partImageSrc: string
	fileUrl?: string
	showModal: boolean
	onCloseModal: Function
	inModal?: boolean
}

const PdfViewer: FC<IProps> = ({
	partImageSrc,
	fileUrl,
	showModal,
	onCloseModal,
	inModal = true
}) => {
	const [rotate, setRotate] = useState(defaultRotate)
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(defaultNumber)
	const [scalePage, setScalePage] = useState(defaultNumber)

	const onDocumentLoadSuccess = ({ numPages, ...rest }: any) => {
		setNumPages(numPages)
	}

	const changePage = (offset: number) => {
		setPageNumber(prevPageNumber => prevPageNumber + offset)
	}

	const onScalePage = (scale: number) => {
		setScalePage(prevScale => prevScale + scale)
	}

	const previousPage = () => {
		changePage(-pageStep)
	}

	const nextPage = () => {
		changePage(pageStep)
	}

	const onZoomOut = () => {
		onScalePage(-zoomStep)
	}

	const onZoomIn = () => {
		onScalePage(zoomStep)
	}

	const onRotate = () => {
		setRotate(prevRotate =>
			prevRotate < maxRotate ? prevRotate + rotateStep : defaultRotate
		)
	}

	const renderPDFViewer = () => {
		return (
			<div className="pdf-preview--wrapp">
				<div
					className={cx('pdf-preview--body', {
						scaled: scalePage > defaultNumber,
						integrated: !inModal
					})}
				>
					<Document
						imageResourcesPath={partImageSrc}
						file={{ url: fileUrl }}
						onLoadSuccess={onDocumentLoadSuccess}
						rotate={rotate}
					>
						<Page
							className="pdf-preview--body__pdf"
							height={480}
							scale={scalePage}
							pageNumber={pageNumber}
							renderTextLayer={false}
						/>
					</Document>
				</div>

				<div className="pdf-preview--body__zoom">
					<Button size="sm" color="primary" onClick={onZoomIn}>
						<ZoomIn />
					</Button>
					<Button size="sm" color="primary" onClick={onZoomOut}>
						<ZoomOut />
					</Button>
					<Button size="sm" color="primary" onClick={onRotate}>
						<RotateRightIcon />
					</Button>
					<Button
						size="sm"
						color="primary"
						type="link"
						href={fileUrl}
						target="_blank"
					>
						<FileDownload />
					</Button>
				</div>
				<div className="pdf-preview--body__buttons">
					<Button
						size="sm"
						color="primary"
						type="button"
						disabled={pageNumber === 1}
						onClick={previousPage}
					>
						<ArrowBack />
					</Button>
					<p data-qa="data-qa-pdf-preview-pagination-text">
						{getString('PREVIEW_PDF_PAGE').format(pageNumber, numPages)}
					</p>
					<Button
						data-qa="data-qa-pdf-preview-next-page-btn"
						size="sm"
						color="primary"
						type="button"
						disabled={pageNumber === numPages}
						onClick={nextPage}
					>
						<ArrowForward />
					</Button>
				</div>
			</div>
		)
	}

	return inModal ? (
		<CastorAlert
			headerTitle={getString('PREVIEW_PDF')}
			onCancel={() => onCloseModal()}
			show={showModal}
			showConfirm={false}
			showCancel={false}
			showFooter={false}
			alertClass="pdf-preview--alert"
			alertBodyClass="pdf-preview--alert-body"
			inPortal
		>
			{renderPDFViewer()}
		</CastorAlert>
	) : (
		renderPDFViewer()
	)
}

export default memo(PdfViewer)
