import { isEmpty, toNumber, trim } from 'lodash'

import {
	ChartDataItem,
	ChartHeaderItem
} from '../../ProjectAnalysisSuggestionService'
import {
	calculatePercent,
	calculateRestPercent,
	countToPieChartLabel,
	countToPieChartLongLabel,
	initChartData,
	initCostChartData,
	noOpportunitiesColor,
	opportunitiesResults,
	opportunitiesResultsColors,
	setChartDataCount
} from '../ProjectResultDistributionService'
import { PART_RESULTS_OPTIONS_OPPORTUNITIES } from 'Services/Strings'

export const defaultChartPercentage = '3%'

export interface ICostAnalysisChartProps {
	color: string
	dollar: number
	label: string
	name: string
	percent: string
	total: number
	x: string
	y: number
}

export const PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES = (
	Object.keys(opportunitiesResults) as Array<keyof typeof opportunitiesResults>
).map(key => {
	const option: any = (PART_RESULTS_OPTIONS_OPPORTUNITIES || []).find(
		(partResultOption: any) =>
			partResultOption.value === opportunitiesResults[key]
	)

	return {
		title: option?.title || '',
		result: opportunitiesResults[key] || opportunitiesResults.noOpportunities,
		color: opportunitiesResultsColors[key] || noOpportunitiesColor,
		stroke: opportunitiesResultsColors[key] || noOpportunitiesColor
	}
})

export const PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES_COST =
	PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES

const formatNumber = (num: number) => {
	const fixedNum = num.toFixed(1)
	const parsedNum = parseFloat(fixedNum)

	return Number.isInteger(parsedNum) ? parsedNum.toString() : fixedNum
}

export const makeHeaders = (
	chartData: Array<ChartDataItem>,
	header: Array<ChartHeaderItem>
) => {
	return header
		.map((el: any) => {
			const selectedChart = chartData?.find((e: any) => e.name === el.result)

			el.percent = trim(selectedChart?.x)
			el.dollar = selectedChart?.dollar
				? `${formatNumber(toNumber(selectedChart?.dollar))}`
				: null
			return el
		})
		.filter((elem: any) => !isEmpty(elem) && !isEmpty(elem.percent))
}

export const createChartData = (
	partsCount: number,
	printabilityData: any,
	opportunitiesData?: any
) => {
	if (!printabilityData) {
		return null
	}
	const chartData: any = initChartData()

	Object.keys(printabilityData).forEach(type => {
		setChartDataCount(chartData, type, printabilityData[type])
	})
	let total = 0

	chartData.forEach((data: any) => {
		total += data.count
	})

	if (partsCount === 0 && total === 0) {
		return null
	}

	chartData.map((data: any) => {
		data.percent = calculatePercent(data.count, total)
		return data
	})

	const maxCountType = chartData.sort((a: any, b: any) => b.count - a.count)[0]
	chartData.find((data: any) => data.type === maxCountType.type).percent =
		calculateRestPercent(
			chartData
				.filter((data: any) => data.type !== maxCountType.type)
				.map((data: any) => data.count),
			total,
			false
		)

	return chartData?.map((data: any) => {
		return {
			x: countToPieChartLabel(data.percent, false),
			y: data.count,
			label: countToPieChartLongLabel(
				data.count,
				data.percent,
				data.name,
				false,
				opportunitiesData
			),
			name: data.name,
			color: data.color,
			total
		}
	})
}

export const createCostChartData = (
	partsCount: number,
	printabilityData: any,
	opportunitiesData?: any
) => {
	if (partsCount === 0 || isEmpty(printabilityData)) {
		return null
	}
	const chartData: any = initCostChartData()

	Object.keys(printabilityData).forEach(type => {
		setChartDataCount(chartData, type, printabilityData[type])
	})

	let total = 0

	chartData.forEach((data: any) => {
		total += data.dollar
	})

	chartData.map((data: any) => {
		data.percent = (data.count * 100) / total
		return data
	})

	return chartData?.map((data: any) => {
		const percent =
			data.percent < 1 && data.percent > 0 ? 1 : data.percent?.toFixed(1)

		return {
			x: countToPieChartLabel(percent, true),
			y: data.count,
			dollar: data.dollar,
			label: countToPieChartLongLabel(
				data.count,
				percent,
				data.name,
				true,
				opportunitiesData
			),
			name: data.name,
			color: data.color,
			total: total,
			percent: percent
		}
	})
}
