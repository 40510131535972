import React, { FC, memo } from 'react'

import { MenuItem, Select } from '@material-ui/core'

import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { ToleranceOption } from 'Services/models/SpecificTolerance'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	generalTolerance: ToleranceClass
	generalToleranceOptions: ToleranceOption[]
	onGeneralToleranceChange: (value: ToleranceClass) => void
	disabled?: boolean
}

const GeneralToleranceSelector: FC<IProps> = ({
	generalTolerance,
	generalToleranceOptions,
	disabled,
	onGeneralToleranceChange
}) => {
	return (
		<div className="specific-tolerances--general-tolerance">
			<div>{getString('GENERAL_TOLERANCE_LABEL')}</div>
			<Select
				displayEmpty
				className="data-table-dropdown without-border specific-tolerances--general-tolerance--selector"
				value={generalTolerance}
				onChange={e =>
					onGeneralToleranceChange(e.target.value as ToleranceClass)
				}
				disableUnderline={true}
				disabled={disabled}
			>
				{generalToleranceOptions.map(option => {
					return (
						<MenuItem
							key={option.key}
							value={option.value}
							disabled={option.disabled}
						>
							{getString(option.key) || option.key}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}

export default memo(GeneralToleranceSelector)
