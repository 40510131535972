import { every, isEmpty, isNil, map, some } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import { GET_PROJECTS_SUGGESTION_BUNGLE } from './ProjectBundleTypes'
import {
	CHANGE_FILTERS_KEY,
	CHANGE_INITIAL_PROJECT_SETUP,
	CHANGE_ORDER_NAME,
	CHANGE_PARTS_PAGE,
	CHANGE_PARTS_PROPERTIES_PAGE,
	CHANGE_PARTS_VIEW,
	CHANGE_RESULT_TYPE,
	CHANGE_SEARCH_PHRASE,
	DEFAULT_SCENARIO_CHANGED,
	DO_REFRESH_CONFIGURATIONS,
	GET_PARTS_PROPERTIES_LOADING,
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	GET_PROJECT_PARTS_STARTED,
	GET_PROJECT_PARTS_TO_PRINT_FROM_STATE,
	GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS,
	GRAB_CAD_PRINT_UPDATED,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	MPIO_REQUEST,
	MPIO_REQUEST_SUCCESS,
	NEW_PRINTER_ADDED_TO_USER,
	NEW_PRINTER_MATERIAL_ADDED,
	PART_ANALYSIS_WALL_THICKNESS_UPDATED,
	PART_FINANCIAL_UPDATED,
	POST_PROCESS_UPDATED,
	PROJECT_ANALYSIS_PART_REMOVED,
	PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR,
	PROJECT_ANALYSIS_REMOVE_ALERT,
	PROJECT_ANALYSIS_REMOVE_PART_CANCELED,
	PROJECT_ANALYSIS_REMOVE_PART_CLICKED,
	PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED,
	PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED,
	PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED,
	PROJECT_PARTS_PROPERTIES_CALCULATING,
	PROJECT_PARTS_PROPERTIES_CALCULATING_RESET,
	PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA,
	PROJECT_PARTS_PROPERTIES_CHANGE_SPECIFIC_TOLERANCE,
	PROJECT_PARTS_PROPERTIES_FETCHED,
	PROJECT_PARTS_PROPERTIES_RESET,
	PROJECT_PARTS_PROPERTIES_UPDATE_PART,
	PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST,
	PROJECT_WEIGHT_REDUCTION_CHANGED,
	PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED,
	PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED,
	PROJECT_WEIGHT_REDUCTION_UPDATED,
	STAR_PART_SUCCESS,
	UPDATE_PROJECT_CHALLENGES,
	UPDATE_PROJECT_CHALLENGES_VALUE,
	USER_FILTERS_CHANGE,
	USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE,
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
	USER_PRINTER_REMOVED_FROM_USER
} from 'global actions/types'
import { filters } from 'Scenes/Components/FilterPartsGrid/filterPartsEnum'
import {
	changeFilterValue,
	prepareFiltersForLocalStorage,
	prepareFiltersForReducer
} from 'Scenes/Components/FilterPartsGrid/FilterPartsService'
import { getClustersWithImages } from 'Scenes/Home/PrintableClusters/PrintableClustersOverviewReducer'
import { ProjectAnalysisPartsView } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionInterfaces'
import {
	createPaginationData,
	getAllBenefitsState,
	getClusterRequestText,
	getClustersStatuses,
	getPartsStandardCosts,
	getPartValue,
	getResultTypeAndSelectedTab,
	getWightReductionStatuses,
	ProjectSuggestionsItem,
	showFailedPartsWarning
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import { TabsItem } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import {
	GET_PROJECT_SUGGESTIONS,
	UPDATE_PROJECT_TAB
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionTypes'
import { RESET_PROJECTS_BUNGLE } from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle/ProjectBundleTypes'
import { partsPageLimit } from 'Services/Constants'
import {
	getJsonItemFromLocalStorage,
	getStringItemFromLocalStorage,
	setJsonItemToLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import {
	PartProperty,
	PartPropertyName
} from 'Services/models/IPartPropertiest'
import {
	Project,
	ProjectClusterStatus,
	ProjectStatus
} from 'Services/models/IProject'
import { UnitSystem } from 'Services/UnitsConversionService'

const CHANGED = 'changed'

const INITIAL_STATE = {
	bundleId: null,
	project: {},
	projects: [],
	parts: [] as Part[],
	partsProperties: [],
	partsPropertiesAll: [] as Part[],
	weightReducedParts: [],
	allPartsInapplicable: false,
	selectedFilterPart: null,
	searchPhrase: '',
	isSinglePartProject: false,
	is2dProject: false,
	geometryAnalysisPart: {},
	onlyPart: {} as Part,
	onlyPartResultTitle: '',
	numberOfPrintablePart: 0,
	numberOfPrintableParts: 0,
	numberOfAllBenefits: 0,
	benefitsState: [],
	selectedFilterValues: [filters.PRINTABLE],
	showMultiplePartsIntoOneRequest: true,
	showMultiplePartsIntoOneResults: false,
	projectWeightReductionStatus: '',
	clusterRequested: false,
	clusterRequestText: '',
	projectClusterStatus: '',
	weightReductionRequested: false,
	weightReductionLoading: false,
	weightReductionRequestText: '',
	weightReductionItemsWithPictures: [],
	weightReducedSuggestedParts: [],
	projectWeightReductionThreshold: 0,
	projectWeightReductionMinimumThicknessThreshold: 0,
	updateWeightReductionButtonLoading: false,
	clusterItemsWithPictures: [],
	partsStandardCosts: [],
	initialStandardCosts: null,
	showStandardCostAlert: false,
	updateStandardCostLoading: false,
	disableStandardCostSubmit: true,
	showStandardCostNumberError: false,
	showNameDuplicationError: false,
	showStandardCostError: false,
	showStandardCostFinishIcon: false,
	requestedRemovePartId: null,
	requestedRemovePartName: '',
	showingSimpleAlertText: null,
	showingSimpleAlertTitle: null,
	showRemovePartAlert: false,
	loadingRemovePart: false,
	doRefreshConfigurations: false,
	loading: false,
	userHasNoPermissions: false,
	partsStandardCostsBeforeChanges: [],
	partsView: ProjectAnalysisPartsView.list,
	leadingConfigurationData: [] as IBestMatchData[],
	paginationData: {
		page: 1,
		limit: partsPageLimit,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	},
	partPropertiesPaginationData: {
		page: 1,
		limit: partsPageLimit,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	},
	partsLoading: false,
	partsPropertiesLoading: false,
	partsToPrintLoading: false,
	partsToPrint: [],
	partsToPrintSummary: [],
	totalPartsCount: 0,
	standalonePartsCount: 0,
	printabilityData: {
		borderline: 0,
		failed: 0,
		missingInformation: 0,
		notCostEffective: 0,
		notPrintable: 0,
		printable: 0
	},
	weightReductionPartsLeadingData: [] as IBestMatchData[],
	initialSetup: true,
	refetchParts: false,
	partsPropertiesReset: { reset: false },
	partsPropertiesCalculating: { calculating: false },
	standardCostAllowedNames: [] as string[],
	selectedTab: TabsItem.opportunities,
	totalSuggestionsCount: 0,
	totalClustersCount: 0,
	costSavingPartsCount: 0,
	sortBy: '',
	sortingCriteria: '',
	resultType: '',
	costSavingDataPerUnit: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	costSavingDataPerYear: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	opportunitiesData: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	suggestions: [] as any,
	projectSuggestionsList: [] as any,
	projectNoOpportunitiesList: [] as any,
	projectChallengesList: [] as any,
	defaultPartDrawingTolerances: [],
	challengesFilterLoading: false
}

const ProjectBundleSuggestionReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case RESET_PROJECTS_BUNGLE: {
			return {
				...INITIAL_STATE
			}
		}

		case GET_PROJECTS_SUGGESTION_BUNGLE: {
			const { bundleId, projects, partsResults, features, clusters, userId } =
				action.payload

			const projectWeightReductionThreshold =
				projects[0]?.weightReductionThreshold
			const projectWeightReductionMinimumThicknessThreshold =
				projects[0]?.weightReductionMinimumThicknessThreshold

			const clustersCount = clusters.length

			const filteredClusterWithPictures = getClustersWithImages(
				clusters,
				projects
			)
			const clusterStatus = getClustersStatuses(projects, clustersCount)
			const weightReductionStatus = getWightReductionStatuses(projects)

			const getFilterFromLocal = getJsonItemFromLocalStorage(
				`filter-${bundleId}`
			)
			const partsViewFromLocal = getStringItemFromLocalStorage(
				`view-${bundleId}`
			)

			const selectedFilterValuesFromLocal =
				(!isEmpty(getFilterFromLocal) && getFilterFromLocal.split(',')) || null

			const preparedFiltersFromLocal = prepareFiltersForReducer(
				selectedFilterValuesFromLocal
			)

			// if there is no selected filter we need to pass by default and save it
			// if some part is Printable => by default Printable, if not => All
			const selectedFilterValues = preparedFiltersFromLocal
				? preparedFiltersFromLocal
				: partsResults?.isSomePrintable
				? state.selectedFilterValues
				: [filters.ALL]

			const selectedPartsView = partsViewFromLocal
				? partsViewFromLocal
				: state.partsView

			const selectedFilterValuesToLS =
				prepareFiltersForLocalStorage(selectedFilterValues)

			const projectStatus = some(
				projects,
				(project: Project) => project.status === ProjectStatus.published
			)
				? ProjectStatus.published
				: some(
						projects,
						(project: Project) =>
							project.status === ProjectStatus.awaitingMapping
				  )
				? ProjectStatus.awaitingMapping
				: ProjectStatus.awaitingAnalysis

			const metadataParts = projects.reduce(
				(accumulator: number, currentValue: Project) => {
					return accumulator + currentValue?.metadataParts
				},
				0
			)

			// do not set filters to ls until all parts are analyzed
			if (projectStatus === ProjectStatus.published) {
				setJsonItemToLocalStorage(
					`filter-${state.bundleId}`,
					`${selectedFilterValuesToLS}`
				)
				setStringItemToLocalStorage(`view-${state.bundleId}`, selectedPartsView)
			}

			const project = {
				id: bundleId,
				isAssembly: false,
				name: projects[0]?.bundle?.name,
				assemblyImage: null,
				status: projectStatus,
				clusters: clustersCount,
				metadataParts: metadataParts,
				clusterStatus: clusterStatus,
				reductions: projects.reduce(
					(accumulator: number, currentValue: Project) => {
						return accumulator + currentValue.reductions
					},
					0
				),
				recalculateClusters: some(
					projects,
					(project: Project) => project.recalculateClusters
				),
				offerMultiplePartsIntoOne: some(
					projects,
					(project: Project) => project.offerMultiplePartsIntoOne
				),
				partsWeightReductionNumber: 1,
				weightReductionStatus: weightReductionStatus,
				amountOfLockedParts: projects.reduce(
					(total: number, { lockedParts }: any) => total + (lockedParts || 0),
					0
				),
				lockedParts: some(
					projects,
					(project: Project) => project.lockedParts > 0
				)
			}

			const partsStandardCosts = getPartsStandardCosts(
				partsResults?.partsWithStandardCost,
				partsResults?.totalPartsCount
			)

			const suggestionsEmpty = every(
				partsResults?.projectSuggestionsList,
				item => !item.suggestionsCount
			)
			const noSuggestionsEmpty = every(
				partsResults?.projectNoOpportunitiesList,
				item => !item.partsCount
			)
			let resultType =
				suggestionsEmpty && !noSuggestionsEmpty
					? partsResults?.projectNoOpportunitiesList?.[0]
							?.noOpportunitiesResultType
					: partsResults?.projectSuggestionsList?.[0]?.clientSuggestionType
			let selectedTab =
				suggestionsEmpty && !noSuggestionsEmpty
					? TabsItem.others
					: TabsItem.opportunities

			return {
				...state,
				bundleId,
				selectedFilterValues,
				project,
				projects,
				geometryAnalysisPart: projects[0]?.geometryAnalysisPart,
				allPartsInapplicable: partsResults?.allPartsInapplicable,
				searchPhrase: '',
				projectWeightReductionThreshold,
				showStandardCostFinishIcon: false,
				projectWeightReductionMinimumThicknessThreshold,
				totalPartsCount: partsResults?.totalPartsCount,
				allPartsFailed:
					partsResults?.numberOfFailedParts === partsResults?.totalPartsCount &&
					every(projects, (project: Project) => !project.lockedParts),
				showFailedPartsWarning: showFailedPartsWarning(
					partsResults?.numberOfFailedParts,
					partsResults?.totalPartsCount,
					some(projects, (project: Project) => project.forcePublished),
					every(projects, (project: Project) => project.allPartsAnalyzed),
					some(projects, (project: Project) => project.ignoreFailedPartsWarning)
				),
				standalonePartsCount: partsResults?.standalonePartsCount,
				partsStandardCosts,
				initialStandardCosts: partsStandardCosts,
				numberOfPrintableParts: partsResults?.printablePartsCount,
				partsView: selectedPartsView,
				...filteredClusterWithPictures,
				costSavingDataPerUnit: partsResults?.costSavingDataPerUnit,
				costSavingDataPerYear: partsResults?.costSavingDataPerYear,
				opportunitiesData: partsResults?.opportunitiesData,
				totalSuggestionsCount: partsResults?.totalSuggestionsCount,
				totalClustersCount: partsResults?.totalClustersCount,
				costSavingPartsCount: partsResults?.costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList: partsResults?.projectSuggestionsList,
				projectNoOpportunitiesList: partsResults?.projectNoOpportunitiesList,
				projectChallengesList: partsResults?.projectChallengesList,
				standardCostAllowedNames: partsResults?.standardCostAllowedNames
			}
		}

		case MPIO_REQUEST_SUCCESS:
			return {
				...state,
				clusters: [],
				clusterItemsWithPictures: []
			}

		case MPIO_REQUEST:
			return {
				...state,
				clusterRequested: true,
				clusterRequestText: getClusterRequestText(
					ProjectClusterStatus.awaitingAnalysis
				),
				projectClusterStatus: ProjectClusterStatus.awaitingAnalysis
			}

		case CHANGE_FILTERS_KEY: {
			const { selectedFilter, isBundle } = action.payload
			const { selectedFilterValues } = state
			if (!isBundle) return state
			let updatedFilterValues = changeFilterValue(
				selectedFilter,
				selectedFilterValues
			)
			const updatedValuesToLS =
				prepareFiltersForLocalStorage(updatedFilterValues)

			setJsonItemToLocalStorage(
				`filter-${state.bundleId}`,
				`${updatedValuesToLS}`
			)

			return {
				...state,
				selectedFilterValues: updatedFilterValues,
				paginationData: {
					...state.paginationData,
					page: 1
				}
			}
		}

		case CHANGE_ORDER_NAME:
			const { sortBy, sortingCriteria } = action.payload
			return { ...state, sortBy, sortingCriteria }

		case CHANGE_SEARCH_PHRASE:
			const searchPhrase = action.payload
			return { ...state, searchPhrase }

		case PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED:
			return {
				...state,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading
			}

		case PROJECT_WEIGHT_REDUCTION_UPDATED: {
			const { partsResults, features, userId } = action.payload
			const {
				is2dProject,
				allPartsInapplicable,
				onlyPart,
				weightReductionParts,
				printablePartsCount,
				totalPartsCount,
				benefitsData
			} = partsResults

			const project = {
				...state.project,
				weightReductionThreshold: state.projectWeightReductionThreshold
			}

			return {
				...state,
				project,
				is2dProject,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading,
				onlyPart,
				allPartsInapplicable,
				totalPartsCount,
				...getAllBenefitsState(
					printablePartsCount,
					weightReductionParts.length,
					benefitsData,
					state.project,
					features,
					is2dProject
				)
			}
		}
		case PROJECT_WEIGHT_REDUCTION_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionThreshold: value
			}
		}
		case PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED:
			return {
				...state,
				showStandardCostAlert: !state.showStandardCostAlert,
				partsStandardCosts: state.initialStandardCosts
			}

		case PART_ANALYSIS_WALL_THICKNESS_UPDATED:
		case PART_FINANCIAL_UPDATED:
		case STAR_PART_SUCCESS: {
			const starredPart = action.payload
			const suggestions = state.suggestions.map(
				(suggestion: IConfiguration) => {
					if (suggestion.part.id === starredPart.id) {
						suggestion.part.starred = starredPart.starred
					}
					return suggestion
				}
			)

			return { ...state, suggestions }
		}

		case PROJECT_ANALYSIS_REMOVE_PART_CLICKED: {
			const { partId, partName } = action.payload
			return {
				...state,
				requestedRemovePartId: partId,
				requestedRemovePartName: partName,
				showRemovePartAlert: true
			}
		}

		case PROJECT_ANALYSIS_REMOVE_ALERT:
			return {
				...state,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null
			}

		case PROJECT_ANALYSIS_REMOVE_PART_CANCELED:
		case PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR:
			return {
				...state,
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false
			}

		case PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED:
			return {
				...state,
				loadingRemovePart: true
			}

		case PROJECT_ANALYSIS_PART_REMOVED: {
			const { partId, partsResults, isBundle } = action.payload
			if (!isBundle) return state

			const suggestions = state.suggestions.filter(
				(suggestion: IConfiguration) => suggestion.part.id !== partId
			)
			const {
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				isSinglePartProject,
				totalPartsCount,
				partsWithStandardCost,
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			} = partsResults

			const { resultType, selectedTab } =
				getResultTypeAndSelectedTab(partsResults)

			return {
				...state,
				suggestions,
				project: {
					...state.project,
					recalculateClusters: true
				},
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false,
				initialStandardCosts: getPartsStandardCosts(
					partsWithStandardCost,
					totalPartsCount
				),
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				totalPartsCount,
				isSinglePartProject,
				updateStandardCostLoading: false,
				showStandardCostAlert: false,
				showStandardCostFinishIcon: true,
				partsStandardCostsBeforeChanges: [],
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			}
		}

		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED:
			const { data } = action.payload
			const prevPartsStandardCosts = state.partsStandardCosts
			let disableStandardCostSubmit = false
			let showStandardCostError = false
			let showStandardCostNumberError = false
			let showNameDuplicationError = false

			const partsStandardCosts = data.map((row: any, key: any) => {
				// remove the space at the beginning and at the end
				let partName = getPartValue(row[0].value) as string

				let partStandardCost: any = getPartValue(row[1].value)

				// const partId = row[0].id
				const partExists = state.standardCostAllowedNames.find(
					name => name.toLowerCase() === partName.toLowerCase()
				)

				// check if part is already exist
				prevPartsStandardCosts &&
					prevPartsStandardCosts.forEach(
						(part: { value: string; valid: boolean }[], i) => {
							if (key !== i) {
								const existPartName = getPartValue(part[0].value)

								if (partName && partName === existPartName) {
									showNameDuplicationError = true
								}
							}
						}
					)

				const partNameValid = !partName || !!partExists
				const standardCostValid =
					(!isNaN(partStandardCost) && partStandardCost > 0) ||
					partStandardCost === ''

				if (!partNameValid) {
					showStandardCostError = true
				}

				if (!standardCostValid) {
					showStandardCostNumberError = true
				}

				if (!partNameValid || !standardCostValid || showNameDuplicationError) {
					disableStandardCostSubmit = true
				}

				if (partStandardCost === '' && partName) {
					disableStandardCostSubmit = false
				}

				if (isEmpty(partName) && !!+partStandardCost) {
					showStandardCostError = true
					disableStandardCostSubmit = true
				}

				return [
					{ value: partName, valid: partNameValid },
					{ value: partStandardCost, valid: standardCostValid }
				]
			})
			return {
				...state,
				partsStandardCosts,
				showStandardCostError,
				showStandardCostNumberError,
				showNameDuplicationError,
				disableStandardCostSubmit: disableStandardCostSubmit,
				partsStandardCostsBeforeChanges:
					state.partsStandardCostsBeforeChanges?.length === 0
						? state.partsStandardCosts
						: state.partsStandardCostsBeforeChanges
			}

		case PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED:
			return {
				...state,
				updateStandardCostLoading: !state.updateStandardCostLoading
			}

		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED: {
			const { partsResults } = action.payload
			const {
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				isSinglePartProject,
				totalPartsCount,
				partsWithStandardCost,
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			} = partsResults || {}

			const { resultType, selectedTab } =
				getResultTypeAndSelectedTab(partsResults)

			return {
				...state,
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				totalPartsCount,
				isSinglePartProject,
				updateStandardCostLoading: false,
				showStandardCostAlert: false,
				showStandardCostFinishIcon: true,
				partsStandardCostsBeforeChanges: [],
				initialStandardCosts: getPartsStandardCosts(
					partsWithStandardCost,
					totalPartsCount
				),
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			}
		}

		case DO_REFRESH_CONFIGURATIONS: {
			const { doRefreshConfigurations, afterStandardCostUpdate, refetchParts } =
				action.payload

			return {
				...state,
				doRefreshConfigurations: afterStandardCostUpdate
					? state.doRefreshConfigurations
					: doRefreshConfigurations,
				refetchParts
			}
		}

		case PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionMinimumThicknessThreshold: value
			}
		}

		case PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA: {
			const { propIndex, partId, value } = action.payload
			const changedProperties: any = cloneDeep(state.partsProperties)
			const partIndex = changedProperties.findIndex(
				(part: any) => part.partId === partId
			)

			if (partIndex !== -1 && !isNil(partIndex)) {
				changedProperties[partIndex].data[propIndex].value = value
				changedProperties[partIndex].data[propIndex].text = value
				changedProperties[partIndex][CHANGED] = true
			}

			return {
				...state,
				partsProperties: changedProperties
			}
		}

		case PROJECT_PARTS_PROPERTIES_CHANGE_SPECIFIC_TOLERANCE: {
			const { partId, specificTolerances, generalTolerance } = action.payload
			const changedProperties: any[] = cloneDeep(state.partsProperties)
			const partIndex = changedProperties.findIndex(
				part => part.partId === partId
			)

			if (partIndex !== -1 && !isNil(partIndex)) {
				changedProperties[partIndex].specificTolerances = specificTolerances
				const toleranceIndex = changedProperties[partIndex].data.findIndex(
					(cell: Record<string, any>) =>
						cell.propertyName === PartPropertyName.customToleranceValue
				)
				changedProperties[partIndex].data[toleranceIndex].text =
					generalTolerance
				changedProperties[partIndex].data[toleranceIndex].value =
					generalTolerance
				changedProperties[partIndex][CHANGED] = true
			}

			return {
				...state,
				partsProperties: changedProperties
			}
		}

		case PROJECT_PARTS_PROPERTIES_FETCHED: {
			const {
				partsProperties: properties,
				rowIndex,
				inapplicablePartsProperties,
				parts,
				totalPartsPropertiesCount,
				defaultPartDrawingTolerances
			} = action.payload
			const { partsProperties } = state

			let updatedPartProperties = cloneDeep(properties)

			if (rowIndex !== -1 && !isNil(rowIndex)) {
				updatedPartProperties = cloneDeep(partsProperties)
				updatedPartProperties[rowIndex] = properties[rowIndex]
				updatedPartProperties[rowIndex][CHANGED] = false
			} else {
				updatedPartProperties = updatedPartProperties.map(
					(property: PartProperty | any) => {
						property[CHANGED] = false
						return property
					}
				)
			}

			return {
				...state,
				partsProperties: updatedPartProperties,
				inapplicablePartsProperties,
				partsPropertiesAll: parts,
				partPropertiesPaginationData: createPaginationData(
					state.partPropertiesPaginationData.page,
					state.partPropertiesPaginationData.limit,
					totalPartsPropertiesCount,
					parts.length
				),
				partsPropertiesLoading: false,
				defaultPartDrawingTolerances
			}
		}

		case PROJECT_PARTS_PROPERTIES_CALCULATING: {
			const { calculating, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesCalculating: {
					calculating,
					rowIndex
				}
			}
		}

		case PROJECT_PARTS_PROPERTIES_CALCULATING_RESET: {
			return {
				...state,
				partsPropertiesCalculating: INITIAL_STATE.partsPropertiesCalculating
			}
		}
		case PROJECT_PARTS_PROPERTIES_RESET: {
			const { reset, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesReset: {
					reset,
					rowIndex
				}
			}
		}

		case PROJECT_PARTS_PROPERTIES_UPDATE_PART: {
			const { partId } = action.payload
			return {
				...state,
				partId
			}
		}
		case GRAB_CAD_PRINT_UPDATED: {
			const { partId, toggleGrabCad } = action.payload
			return {
				...state,
				parts: map(state.parts, (part: Part) => {
					if (part.id === partId) {
						return {
							...part,
							grabCadPrint: !toggleGrabCad
						}
					}

					return part
				})
			}
		}
		case GET_PROJECT_ANALYSIS_NO_PERMISSION: {
			return {
				...state,
				userHasNoPermissions: true
			}
		}

		case CHANGE_PARTS_VIEW: {
			const { view } = action.payload

			setStringItemToLocalStorage(`view-${state.bundleId}`, view)

			return {
				...state,
				partsView: view
			}
		}

		case CHANGE_RESULT_TYPE: {
			return {
				...state,
				partsLoading: true,
				resultType: action.payload.resultType,
				paginationData: INITIAL_STATE.paginationData
			}
		}

		case GET_PROJECT_PARTS_STARTED: {
			return {
				...state,
				partsLoading: true
			}
		}

		case CHANGE_PARTS_PAGE: {
			const { page } = action.payload

			return {
				...state,
				paginationData: {
					...state.paginationData,
					page
				}
			}
		}

		case GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED: {
			return {
				...state,
				partsToPrintLoading: true
			}
		}

		case GET_PARTS_PROPERTIES_LOADING: {
			const { loading } = action.payload

			return {
				...state,
				partsPropertiesLoading: loading
			}
		}

		case CHANGE_PARTS_PROPERTIES_PAGE: {
			const { page } = action.payload

			return {
				...state,
				partPropertiesPaginationData: {
					...state.partPropertiesPaginationData,
					page
				}
			}
		}

		case GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS: {
			const { suggestions, leadingConfigurationData } = action.payload

			return {
				...state,
				partsToPrintLoading: false,
				partsToPrint: suggestions,
				leadingConfigurationData
			}
		}

		case GET_PROJECT_PARTS_TO_PRINT_FROM_STATE: {
			return {
				...state,
				partsToPrint: state.suggestions
			}
		}

		case CHANGE_INITIAL_PROJECT_SETUP: {
			return {
				...state,
				initialSetup: action.payload
			}
		}

		case GET_PROJECT_SUGGESTIONS: {
			const {
				suggestions,
				totalSuggestionsCount,
				updatePagination,
				page,
				limit
			} = action.payload

			let selectedTab = state.selectedTab
			let resultType = state.resultType

			if (!suggestions.length && state.selectedTab === TabsItem.others) {
				if (!state.projectNoOpportunitiesList.length) {
					selectedTab = TabsItem.opportunities
					resultType =
						state.projectSuggestionsList?.[0]?.clientSuggestionType ||
						resultType
				}

				if (state.projectNoOpportunitiesList.length) {
					const otherType = state.projectNoOpportunitiesList.filter(
						(el: ProjectSuggestionsItem) =>
							el.noOpportunitiesResultType !== +state.resultType
					)
					resultType = otherType?.[0]?.noOpportunitiesResultType || resultType
				}
			}

			return {
				...state,
				partsLoading: false,
				refetchParts: false,
				resultType,
				suggestions,
				selectedTab,
				paginationData: createPaginationData(
					updatePagination ? page : state.paginationData.page,
					updatePagination ? limit : state.paginationData.limit,
					totalSuggestionsCount,
					suggestions.length
				)
			}
		}

		case UPDATE_PROJECT_TAB: {
			const { selectedTab } = action.payload

			let resultType

			switch (selectedTab) {
				case TabsItem.opportunities:
					resultType = state.projectSuggestionsList?.[0]?.clientSuggestionType
					break
				case TabsItem.others:
					resultType =
						state.projectNoOpportunitiesList?.[0]?.noOpportunitiesResultType
					break
				case TabsItem.challenges:
					resultType = state.projectChallengesList?.[0]?.challengesResultType
					break
			}

			return {
				...state,
				selectedTab: selectedTab,
				partsLoading: true,
				resultType,
				paginationData: INITIAL_STATE.paginationData
			}
		}

		case PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST: {
			const { partsResults } = action.payload

			return {
				...state,
				partsLoading: false,
				projectChallengesList:
					partsResults?.projectChallengesList || state.projectChallengesList,
				projectSuggestionsList:
					partsResults?.projectSuggestionsList || state.projectSuggestionsList,
				projectNoOpportunitiesList:
					partsResults?.projectNoOpportunitiesList ||
					state.projectSuggestionsList
			}
		}

		case NEW_PRINTER_ADDED_TO_USER:
		case USER_PRINTER_REMOVED_FROM_USER:
		case MATERIAL_ADDED_TO_USER:
		case MATERIAL_REMOVED_FROM_USER:
		case USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE:
		case NEW_PRINTER_MATERIAL_ADDED:
		case USER_PRINTER_MATERIAL_REMOVED_FROM_USER:
		case USER_FILTERS_CHANGE:
		case DEFAULT_SCENARIO_CHANGED:
		case POST_PROCESS_UPDATED: {
			return {
				...state,
				doRefreshConfigurations: true
			}
		}

		case UPDATE_PROJECT_CHALLENGES: {
			return {
				...state,
				challengesFilterLoading: action.payload.loading
			}
		}

		case UPDATE_PROJECT_CHALLENGES_VALUE: {
			const { value, type, challengeType } = action.payload
			let projectChallengesList = state.projectChallengesList

			const updatedIndex = projectChallengesList.findIndex(
				(challenge: ProjectSuggestionsItem) =>
					challenge.challengesResultType === challengeType
			)

			if (updatedIndex !== -1) {
				projectChallengesList[updatedIndex].editField.type = type
				projectChallengesList[updatedIndex].editField.value = value
			}

			return {
				...state,
				projectChallengesList
			}
		}

		default:
			return state
	}
}

export default ProjectBundleSuggestionReducer
