import React, { FC, memo, useMemo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'
import classnames from 'classnames'
import cx from 'classnames'
import { trim } from 'lodash'

import {
	createCostChartData,
	defaultChartPercentage,
	ICostAnalysisChartProps,
	makeHeaders,
	PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES_COST
} from './CreateChartService'
import EmptyChart from './EmptyChart'
import PieFooter from './PieFooter'
import ResultsLabel from './ResultsLabel'
import { stylesChart } from './styles'
import FlexBox from 'Scenes/Components/FlexBox'
import { formatMoney } from 'Scenes/Components/LedWithTitle'
import { getString } from 'Services/Strings/StringService'

const BLACK = 'black'

enum costChartData {
	perUnit = 'perUnit',
	perYear = 'perYear'
}

const CostAnalysisChart: FC = () => {
	const [costChartSelect, setCostChartSelect] = useState(costChartData.perYear)
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)

	const {
		project,
		is2dProject,
		isMetaDataProject,
		costSavingDataPerUnit,
		costSavingDataPerYear,
		costSavingPartsCount,
		opportunitiesData,
		totalSuggestionsCount
	} = useSelector((state: RootStateOrAny) =>
		isBundle
			? state.ProjectBundleSuggestionReducer
			: state.ProjectSuggestionReducer
	)
	const [partResultHovered, setPartResultHovered] = useState('')

	const chartData = useMemo(
		() =>
			createCostChartData(
				costSavingPartsCount,
				costChartSelect === costChartData.perUnit
					? costSavingDataPerUnit
					: costSavingDataPerYear,
				opportunitiesData
			),
		[costChartSelect, costSavingDataPerUnit, costSavingDataPerYear]
	)

	const isAssembly = project.isAssembly
	const changedHeader = makeHeaders(
		chartData,
		PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES_COST
	)

	const hoveredPercent = useMemo(
		() => chartData?.find((single: any) => single.name === partResultHovered),
		[chartData, partResultHovered]
	)

	const isEmptyChart = !chartData?.[0]?.total
	const selectedText = useMemo(
		() => changedHeader.find(text => hoveredPercent?.name === text.result),
		[changedHeader, hoveredPercent]
	)

	const chartLinear = useMemo(() => {
		return chartData
			?.filter((single: ICostAnalysisChartProps) => !!trim(single.x))
			.map((single: ICostAnalysisChartProps) => {
				return (
					<div
						key={single.name}
						className={cx('linear-chart-item', {
							hovered: partResultHovered === single.name
						})}
						onMouseEnter={() => setPartResultHovered(single.name)}
						onMouseLeave={() => setPartResultHovered('')}
						style={{
							backgroundColor: single.color,
							width:
								+single.percent < 2 ? defaultChartPercentage : `${single.x}%`
						}}
					></div>
				)
			})
	}, [chartData, partResultHovered])

	return (
		<FlexBox
			justifyContent={'space-between'}
			flexDirection="column"
			className={classnames(
				'project-results--block optimization cost-analysis',
				{
					'without-assembly-block': !isAssembly
				}
			)}
			style={stylesChart.footerFlex}
		>
			<ResultsLabel
				subTitleKey={''}
				title={getString('SOLUTION_BENEFIT_COST_SAVING_NAME')}
				partsCount={totalSuggestionsCount}
				printablePartsCount={costSavingPartsCount}
				isAssembly={isAssembly}
				isCostSaving={true}
			/>
			<FlexBox flexDirection="column" height="100%">
				{isEmptyChart ? (
					<EmptyChart isLinear />
				) : (
					<>
						<FlexBox width="100%" justifyContent="flex-end" alignItems="center">
							<div className="show-chart-by">{getString('SHOW_CHART_BY')}:</div>
							<Select
								id="upload-with-organization"
								className="recalculate-projects--organization--select-field"
								value={costChartSelect}
								onChange={event => {
									setCostChartSelect(event.target.value as costChartData)
								}}
								classes={{
									select: 'customize-settings--header--organization--select'
								}}
								inputProps={{
									name: 'simpleSelect',
									id: 'simple-select'
								}}
								MenuProps={{
									PaperProps: {
										style: {
											transform: 'translate3d(0, 0, 0)'
										}
									}
								}}
							>
								<MenuItem value={costChartData.perUnit}>
									{getString('SHOW_PER_UNIT')}
								</MenuItem>
								<MenuItem value={costChartData.perYear}>
									{getString('SHOW_YEARLY')}
								</MenuItem>
							</Select>
						</FlexBox>
						<FlexBox
							alignItems="center"
							justifyContent="center"
							flexDirection="column"
							width="100%"
							height="50%"
						>
							<div className={'linear-chart-title'}>
								<span
									style={{
										color: hoveredPercent?.color || BLACK
									}}
								>
									{formatMoney(
										(hoveredPercent?.y || chartData?.[0]?.total || 0).toFixed(0)
									)}
								</span>
								<div className="linear-chart-title__saving">
									{getString('POTENTIAL_COST_SAVING').format(
										hoveredPercent ? getString('POTENTIAL_USING') : '',
										hoveredPercent ? selectedText?.title : ''
									)}
								</div>
							</div>
							<div className="linear-chart">{chartLinear}</div>
						</FlexBox>
						<PieFooter
							hoverItem={(name: string) => setPartResultHovered(name)}
							currentData={chartData}
							isSuggestion={true}
							partStatuses={changedHeader}
							chartIsEmpty={!chartData}
							is2dProject={is2dProject || isMetaDataProject}
							isAssembly={isAssembly}
							partResultHovered={partResultHovered}
						/>
					</>
				)}
			</FlexBox>
		</FlexBox>
	)
}

export default memo(CostAnalysisChart)
