import { FC, useState } from 'react'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	show: boolean
	onRemoveWarningClick: () => void
}

const FailedPartsWarning: FC<IProps> = ({ show, onRemoveWarningClick }) => {
	const [showWarning, setShowWarning] = useState(show)
	if (!showWarning) return null
	return (
		<div className="failed-parts-warning--wrapper">
			<IconFactory iconName="warning" />
			{getString('FAILED_PARTS_WARNING')}
			<IconFactory
				className="failed-parts-warning--remove-icon"
				iconName="clear"
				onClick={() => {
					setShowWarning(false)
					onRemoveWarningClick()
				}}
			/>
		</div>
	)
}

export default FailedPartsWarning
