import React, { FC, HTMLAttributes, memo } from 'react'

const Flexbox: FC<HTMLAttributes<HTMLButtonElement> & any> = ({
	children,
	className = '',
	style,
	innerRef,
	'data-qa': dataQa,
	'--solution-color': solutionColor,
	...propsStyle
}) => {
	const styles = {
		display: 'flex',
		...propsStyle,
		...style,
		'--solution-color': solutionColor
	} as any

	return (
		<div
			id={propsStyle.id}
			ref={innerRef}
			data-qa={dataQa || ''}
			style={styles}
			className={className}
		>
			{children}
		</div>
	)
}

export default memo(Flexbox)
