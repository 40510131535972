import { scroller } from 'react-scroll'

import { isNil, isNumber, isObject, minBy } from 'lodash'

import { ConfigurationResultTypes } from './ConfigurationResultTypes'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import {
	DataTableFieldType,
	IDataTableField
} from 'Scenes/Components/DataTable/IDataTableField'
import { materialTypes, partResults } from 'Services/Constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import {
	ChainBenefitsNames,
	IChainBenefits
} from 'Services/models/IChainBenefits'
import {
	GRAM_UNITS,
	KG_UNITS,
	MATERIAL,
	MATERIAL_TABLE_TEXTS,
	PART_ANALYSIS_LED_WITH_TITLE_INDEXES
} from 'Services/Strings'
import {
	TemperatureUnit,
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

const emptyText = '—'

export const TMAndPCConfigurations = [
	ConfigurationResultTypes.PartConsolidation,
	ConfigurationResultTypes.Casting,
	ConfigurationResultTypes.Challenges,
	ConfigurationResultTypes.Lathe,
	ConfigurationResultTypes.LatheSplit
]

export const disabledConfigureTypes = [
	ConfigurationResultTypes.WeightReduction,
	...TMAndPCConfigurations
]

export const disabledDetailTableTypes = [
	ConfigurationResultTypes.WeightReduction,
	ConfigurationResultTypes.PartConsolidation,
	ConfigurationResultTypes.Casting,
	ConfigurationResultTypes.Lathe,
	ConfigurationResultTypes.LatheSplit,
	ConfigurationResultTypes.Challenges
]

export interface IWeightData {
	weight: number
	weightUnits: string
}

export class SolutionAnalysisReducerService {
	dataTableService: DataTableService

	constructor() {
		this.dataTableService = new DataTableService()
	}

	scrollToElement = (elementName: string) => {
		scroller.scrollTo(elementName, {
			duration: 300,
			delay: 300,
			offset: -50,
			smooth: true
		})
	}

	renderTextField = (text?: string | number) => {
		return this.dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: text
		})
	}

	renderTextWithStandardDeviation = (
		text?: string | number,
		dataOrDeviation?: number | object
	) => {
		return this.dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithStandardDeviation,
			text: text,
			dataOrDeviation: dataOrDeviation
		})
	}

	scrollToElementInContainer = (elementName: string) => {
		scroller.scrollTo(elementName, {
			duration: 0,
			delay: 500,
			smooth: 'easeInOutQuint',
			offset: -135,
			containerId: 'main-content-scroller'
		})
	}

	getWeightData = (weight: number): IWeightData => {
		weight /= 1000
		let weightUnits = KG_UNITS

		if (weight < 0.5) {
			weight *= 1000
			weightUnits = GRAM_UNITS
		}

		return {
			weight,
			weightUnits
		}
	}

	getTextWithStandardDeviation = (
		obj: any,
		elm: string
	): IDataTableField | null => {
		const isNullObj = obj && isNil(obj[elm])

		// check on 0, '', undefined, null
		const checkNotNullObj =
			!isNullObj &&
			((!obj &&
				!obj[elm] &&
				!(obj?.standardDeviation && obj?.standardDeviation[elm])) ||
				(obj &&
					!obj[elm] &&
					!(obj?.standardDeviation && obj.standardDeviation[elm])))

		if (isNullObj || checkNotNullObj) {
			return this.renderTextField(emptyText)
		}

		if (obj.standardDeviation && obj.standardDeviation[elm]) {
			return this.renderTextWithStandardDeviation(
				toFixedOnlyIfNeeded(obj[elm]),
				obj.standardDeviation[elm]
			)
		}
		return this.renderTextField(toFixedOnlyIfNeeded(obj[elm]))
	}

	getTextWithXYZDeviation = (obj: any, elm: string): IDataTableField | null => {
		const ext: any = obj[`${elm}Ext`]
		const checkExt: boolean = isObject(ext)

		const checkOnEmpty = (key: string) => {
			return !ext[key]?.val && !ext[key]?.stdev
		}

		if (checkExt) {
			if (checkOnEmpty('X') && checkOnEmpty('Y') && checkOnEmpty('Z')) {
				return this.renderTextField(emptyText)
			}

			return this.dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithXYZDeviation,
				dataOrDeviation: ext
			})
		} else {
			return this.getTextWithStandardDeviation(obj, elm)
		}
	}

	percentDeltaStr = (obj: any, elm: string, original: number) => {
		const ext = obj[`${elm}Ext`]
		let printed = obj[elm]

		if (isObject(ext)) {
			const values = Object.values(ext)
			const lowerValue: any = minBy(values, 'val')
			printed = lowerValue?.val
		}

		if (printed && original) {
			const diff = Math.floor((100 * printed) / original) - 100
			const diffWithSign = diff > 0 ? `+${diff}` : diff
			return `${diffWithSign}%`
		}

		return emptyText
	}

	temperatureDeltaStr = (
		temperatureUnit: TemperatureUnit,
		printerMaterialMaxTemperature?: number | string,
		originalMaterialMaxTemperature?: number | string
	) => {
		if (
			isNumber(printerMaterialMaxTemperature) &&
			isNumber(originalMaterialMaxTemperature)
		) {
			const diff = Math.floor(
				printerMaterialMaxTemperature - originalMaterialMaxTemperature
			)
			const diffWithSign = diff > 0 ? `+${diff}` : diff
			return `${diffWithSign} [°${temperatureUnit}]`
		}

		return emptyText
	}

	getMaterialComparisonRows = (
		solution: any,
		material: any,
		solutionPriorities: Map<string, number>,
		showAll: boolean,
		isAmOriginalMaterial: boolean,
		isAMSuggestion: boolean,
		userUnitSystem: UnitSystem = UnitSystem.metric
	): any[][] => {
		if (!solution || !material) {
			return []
		}
		const userConversionService = new UnitsConversionService(
			UnitSystem.metric,
			userUnitSystem
		)

		const ultimateTensileStrength = 'ultimateTensileStrength'
		const percentElongationAtBreak = 'percentElongationAtBreak'
		const youngsModulus = 'youngsModulus'
		const yieldStrengthMPa = 'yieldStrengthMPa'
		const density = 'density'
		const thermalConductivity = 'thermalConductivity'
		const maximumServiceTemperature = 'maximumServiceTemperature'
		const surfaceFinish = 'surfaceFinish'
		const accuracy = 'accuracy'

		solution.printerMaterial = solution.printerMaterial || {}

		const ultimateTensileStrengthValue = this.getTextWithXYZDeviation(
			solution.printerMaterial,
			ultimateTensileStrength
		)
		const ultimateTensileStrengthValueStandard =
			this.getTextWithStandardDeviation(material, ultimateTensileStrength)

		const percentElongationAtBreakValue = this.getTextWithXYZDeviation(
			solution.printerMaterial,
			percentElongationAtBreak
		)
		const percentElongationAtBreakValueStandard =
			this.getTextWithStandardDeviation(material, percentElongationAtBreak)

		const youngsModulusValue = this.getTextWithXYZDeviation(
			solution.printerMaterial,
			youngsModulus
		)
		const yieldStrengthMPaValue = this.getTextWithXYZDeviation(
			solution.printerMaterial,
			yieldStrengthMPa
		)
		const densityValue = this.getTextWithStandardDeviation(
			solution.printerMaterial,
			density
		)
		const accuracyValue = this.getTextWithStandardDeviation(
			solution.printerMaterial,
			accuracy
		)
		const surfaceFinishValue = this.getTextWithStandardDeviation(
			solution.printerMaterial,
			surfaceFinish
		)

		const addElmToArray = (priorityName: string, elm: any[]) => {
			const inPriorities = solutionPriorities.has(priorityName)
			if (showAll || inPriorities) {
				toReturn.push(elm)
			}
		}

		const toReturn = [
			[
				this.renderTextField(MATERIAL),
				this.renderTextField(solution.printerMaterial.name),
				this.renderTextField(material.name),
				this.renderTextField(emptyText)
			]
		]

		if (
			ultimateTensileStrengthValue?.text !== emptyText ||
			ultimateTensileStrengthValueStandard?.text !== emptyText
		) {
			addElmToArray(ultimateTensileStrength, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH),
				ultimateTensileStrengthValue,
				ultimateTensileStrengthValueStandard,
				this.renderTextField(
					this.percentDeltaStr(
						solution.printerMaterial,
						ultimateTensileStrength,
						material.ultimateTensileStrength
					)
				)
			])
		}

		if (
			percentElongationAtBreakValue?.text !== emptyText ||
			percentElongationAtBreakValueStandard?.text !== emptyText
		) {
			addElmToArray(percentElongationAtBreak, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.ELONGATION),
				percentElongationAtBreakValue,
				percentElongationAtBreakValueStandard,
				this.renderTextField(
					this.percentDeltaStr(
						solution.printerMaterial,
						percentElongationAtBreak,
						material.percentElongationAtBreak
					)
				)
			])
		}

		if (youngsModulusValue?.text !== emptyText || material.youngsModulus) {
			addElmToArray(youngsModulus, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS),
				youngsModulusValue,
				this.renderTextWithStandardDeviation(
					toFixedOnlyIfNeeded(material.youngsModulus),
					material.standardDeviation && material.standardDeviation.youngsModulus
				),
				this.renderTextField(
					this.percentDeltaStr(
						solution.printerMaterial,
						youngsModulus,
						material.youngsModulus
					)
				)
			])
		}

		if (
			yieldStrengthMPaValue?.text !== emptyText ||
			material.yieldStrengthMPa
		) {
			addElmToArray(yieldStrengthMPa, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.YIELD_STRENGTH),
				yieldStrengthMPaValue,
				this.renderTextWithStandardDeviation(
					toFixedOnlyIfNeeded(material.yieldStrengthMPa),
					material.standardDeviation &&
						material.standardDeviation.yieldStrengthMPa
				),
				this.renderTextField(
					this.percentDeltaStr(
						solution.printerMaterial,
						yieldStrengthMPa,
						material.yieldStrengthMPa
					)
				)
			])
		}

		if (densityValue?.text !== emptyText || material.density) {
			addElmToArray(density, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.DENCITY),
				densityValue,
				this.renderTextWithStandardDeviation(
					toFixedOnlyIfNeeded(material.density),
					material.standardDeviation && material.standardDeviation.density
				),
				this.renderTextField(
					this.percentDeltaStr(
						solution.printerMaterial,
						density,
						material.density
					)
				)
			])
		}

		if (material.type === materialTypes.metal) {
			const thermalConductivityValue = this.getTextWithStandardDeviation(
				solution.printerMaterial,
				thermalConductivity
			)
			const thermalConductivityWithStandard = this.getTextWithStandardDeviation(
				material,
				thermalConductivity
			)
			if (
				thermalConductivityValue?.text !== emptyText ||
				thermalConductivityWithStandard?.text !== emptyText
			) {
				addElmToArray(thermalConductivity, [
					this.renderTextField(MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY),
					thermalConductivityValue,
					thermalConductivityWithStandard,
					this.renderTextField(
						this.percentDeltaStr(
							solution.printerMaterial,
							thermalConductivity,
							material.thermalConductivity
						)
					)
				])
			}
		} else {
			const temperatureUnit =
				userUnitSystem === UnitSystem.metric
					? TemperatureUnit.C
					: TemperatureUnit.F

			const maximumServiceTemperaturePrinter =
				this.getTextWithStandardDeviation(
					solution.printerMaterial,
					maximumServiceTemperature
				)
			const maximumServiceTemperatureMaterial =
				this.getTextWithStandardDeviation(material, maximumServiceTemperature)

			if (
				maximumServiceTemperaturePrinter?.text !== emptyText ||
				maximumServiceTemperatureMaterial?.text !== emptyText
			) {
				// convert to user's unit system
				if (
					maximumServiceTemperaturePrinter?.text &&
					maximumServiceTemperaturePrinter.text !== emptyText
				) {
					maximumServiceTemperaturePrinter.text =
						userConversionService.convertTemperature(
							+maximumServiceTemperaturePrinter.text
						)
				}
				if (
					maximumServiceTemperaturePrinter?.data?.dataOrDeviation &&
					isNumber(maximumServiceTemperaturePrinter?.data?.dataOrDeviation)
				) {
					maximumServiceTemperaturePrinter.data.dataOrDeviation =
						userConversionService.convertTemperature(
							maximumServiceTemperaturePrinter.data.dataOrDeviation
						)
				}

				if (
					maximumServiceTemperatureMaterial?.text &&
					maximumServiceTemperatureMaterial.text !== emptyText
				) {
					maximumServiceTemperatureMaterial.text =
						userConversionService.convertTemperature(
							+maximumServiceTemperatureMaterial.text
						)
				}
				if (
					maximumServiceTemperatureMaterial?.data?.dataOrDeviation &&
					isNumber(maximumServiceTemperatureMaterial?.data?.dataOrDeviation)
				) {
					maximumServiceTemperatureMaterial.data.dataOrDeviation =
						userConversionService.convertTemperature(
							maximumServiceTemperatureMaterial.data.dataOrDeviation
						)
				}

				addElmToArray(maximumServiceTemperature, [
					this.renderTextField(
						MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE.format(temperatureUnit)
					),
					maximumServiceTemperaturePrinter,
					maximumServiceTemperatureMaterial,
					this.renderTextField(
						this.temperatureDeltaStr(
							temperatureUnit,
							maximumServiceTemperaturePrinter?.text,
							maximumServiceTemperatureMaterial?.text
						)
					)
				])
			}
		}

		if (solution.printerMaterial.type === materialTypes.metal) {
			if (surfaceFinishValue?.text !== emptyText) {
				addElmToArray(surfaceFinish, [
					this.renderTextField(MATERIAL_TABLE_TEXTS.SURFACE_FINISH),
					this.getTextWithStandardDeviation(
						solution.printerMaterial,
						surfaceFinish
					),
					this.renderTextField(emptyText),
					this.renderTextField(emptyText)
				])
			}
		}

		if (accuracyValue?.text !== emptyText) {
			addElmToArray(accuracy, [
				this.renderTextField(MATERIAL_TABLE_TEXTS.ACCURACY),
				accuracyValue,
				this.renderTextField(emptyText),
				this.renderTextField(emptyText)
			])
		}

		if (!isAMSuggestion) {
			for (const row of toReturn) {
				row?.splice(1, 1)
				row?.splice(2, 1)
			}
			return toReturn
		}

		if (isAmOriginalMaterial) {
			for (const row of toReturn) {
				row?.splice(2, 2)
			}
		}
		return toReturn
	}

	formatLedWithTitleIndexesStrings = (wallThicknessTestInMM: number) => {
		return PART_ANALYSIS_LED_WITH_TITLE_INDEXES.map((index: any) => {
			const title = index.title.format(wallThicknessTestInMM.toString())
			return {
				title,
				color: index.color,
				materialType: index?.materialType,
				featureId: index?.featureId
			}
		})
	}

	toShowAnalysisTab = (configuration: any, keepAnalysis: boolean) => {
		if (keepAnalysis) {
			return true
		}
		const configurationPrintable =
			configuration?.result === partResults.printable
		const configurationBorderline =
			configuration?.result === partResults.borderline
		return configurationPrintable || configurationBorderline
	}
	getTabIndex = (configuration: any) => {
		if (
			!configuration ||
			!!configuration?.cluster ||
			!configuration?.trayOrientation
		) {
			return 0
		}
		const { result } = configuration
		if (result === partResults.borderline || result === partResults.printable) {
			return 1
		}
		return 0
	}

	getChainBenefits = (
		chainBenefits: IChainBenefits,
		userCustomDefaultSupplyChainCost?: boolean
	): IChainBenefits => {
		if (chainBenefits) {
			return chainBenefits
		}

		const defaultChainBenefits: IChainBenefits = {
			[ChainBenefitsNames.Global]: {
				on: true
			},
			[ChainBenefitsNames.Ordering]: {
				on: userCustomDefaultSupplyChainCost || false
			},
			[ChainBenefitsNames.Obsolescence]: {
				on: userCustomDefaultSupplyChainCost || false
			},
			[ChainBenefitsNames.Maintenance]: {
				on: userCustomDefaultSupplyChainCost || false
			}
		}
		return defaultChainBenefits
	}
}
