import { useEffect, useState } from 'react'

import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { tightToleranceOptions } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ChallengeFilter/ChallengeFilterAlertService'
import {
	ClientChallengesType,
	StringChallengesTitle
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const dataTableService = new DataTableService()

const getChallengesRows = (
	part: Part,
	configuration: IConfiguration,
	unit: string,
	complexityScore: string,
	highCostToVolumeRatio: number,
	partVolumeAverage: number
) => {
	const rows = [
		part?.challenges?.[ClientChallengesType.highCost]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString(
							StringChallengesTitle[ClientChallengesType.highCost]
						),
						iconName: 'dollar',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_COST_EXPLANATION').format(
							`<b>$${configuration?.costResults?.actualResult?.toFixed(
								2
							)}</b>` || '-',
							`<b>$${part?.averageCosts?.averageActualResult?.toFixed(2)}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: [],
		part?.challenges?.[ClientChallengesType.hightCostToVolume]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString(
							StringChallengesTitle[ClientChallengesType.hightCostToVolume]
						),
						iconName: 'dollar',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_COST_VOLUME_EXPLANATION').format(
							`<b>${highCostToVolumeRatio.toFixed(5)} ${unit}</b>`,
							`<b>${partVolumeAverage?.toFixed(5)} ${unit}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: [],
		part?.challenges?.[ClientChallengesType.hightComplexity]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString(
							StringChallengesTitle[ClientChallengesType.hightComplexity]
						),
						iconName: 'complexitySmall',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_COMPLEXITY_EXPLANATION').format(
							`<b>${complexityScore}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: [],
		part?.challenges?.[ClientChallengesType.highBuyToFly]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString(
							StringChallengesTitle[ClientChallengesType.highBuyToFly]
						),
						iconName: 'buyToFlySmall',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_BUY_TO_FLY_EXPLANATION').format(
							`<b>${part?.partBuyToFlyScore?.toFixed(2)}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: [],
		part?.challenges?.[ClientChallengesType.tightTolerance]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString(
							StringChallengesTitle[ClientChallengesType.tightTolerance]
						),
						iconName: 'tolerance',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_TOLERANCE_EXPLANATION').format(
							`<b>${
								tightToleranceOptions.find(
									x => x.value === part?.customToleranceValue
								)?.label || '-'
							}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: [],
		part?.challenges?.[ClientChallengesType.countInAssembly]
			? [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.TextWithIcon,
						text: getString('HIGH_BOM_QUANTITY_TITLE'),
						iconName: 'assembly',
						className: `cost-summary--table--text--title data-table--first-row`
					}),
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.Detailed,
						text: getString('PART_HIGH_QUANTITY_EXPLANATION').format(
							`<b>${part?.countInAssembly || '-'}</b>`,
							`<b>${part?.countInAssembly || '-'}</b>`
						),
						className: `cost-summary--table--text--title`
					})
			  ]
			: []
	]
	return rows
}

export function useChallengesRows(
	part: Part,
	configuration: IConfiguration,
	unit: string,
	complexityScore: string,
	partCostVolumeCube: number,
	partVolumeAverage: number
) {
	const [challengesRows, setChallengesRows] = useState(
		getChallengesRows(
			part,
			configuration,
			unit,
			complexityScore,
			partCostVolumeCube,
			partVolumeAverage
		)
	)

	useEffect(() => {
		setChallengesRows(
			getChallengesRows(
				part,
				configuration,
				unit,
				complexityScore,
				partCostVolumeCube,
				partVolumeAverage
			)
		)
	}, [part, configuration, complexityScore])

	return challengesRows
}
